<div
  class="formly-switch-wrapper d-flex-center"
  (click)="onActive($event)">
  <nz-switch
    [formControl]="formControl"
    [formlyAttributes]="field"
    [nzSize]="props?.size || 'small'"
    stopEvent>
  </nz-switch>

  <div class="label ml-2">{{props?.name}}</div>
</div>
