import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-name-editable',
  templateUrl: './name-editable.component.html',
  styleUrls: ['./name-editable.component.scss']
})
export class NameEditableComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Output() focusout = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  onEnterName(event, element: HTMLElement) {
    event.preventDefault();
    event.stopPropagation();
    element?.blur();
    this.nameFocusout();
  }

  nameFocusout() {
    if (this.form.get(this.controlName).invalid) {
      return;
    }
    this.focusout.emit(this.form.get(this.controlName).value);
  }

}
