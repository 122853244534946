import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CompanyStorage, DatePeriod, DATE_FORMAT, DATE_UI_FORMAT, DATE_UI_SHORT_FORMAT, Field, FieldType, FieldValue, PeriodOfTimeAgo, REGREX_IS_HTML, UnitOfTime, FORMAT_12_HOURS, TIME_FORMAT, SelectOption } from 'src/app/shared';
import { FirstDayAndLastDay } from 'src/app/shared/_models';
import { DiffTimeFromNow } from 'src/app/shared/_models/app/diff-time.model';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor() {
  }

  static isSubString(text: string, keyword: string) {

    // debug ignore special character
    const containsSpecialCharacters = (str) => {
      var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      return regex.test(str);
    }
    // test text VN
    return !containsSpecialCharacters(keyword) && (new RegExp(keyword, "i")).test(text);
  }

  static isValidDate(date: string) {
    return moment(date, DATE_FORMAT, true).isValid();
  }

  static isNumber(text: any) {
    return /^-?\d+(\.\d+)?(E-?\d+)?$/.test(text);
  }

  static textToCapitalize(text: string) {
    return text.charAt(0) + text.toLowerCase().slice(1);
  }

  static getDateTimeOfMonths() {
    return moment.months().map((key, idx) => {
      return {
        month: key,
        fromDate:  moment().month(idx).startOf('month').format(DATE_FORMAT),
        toDate: moment().month(idx).endOf('month').format(DATE_FORMAT)
      };
    });
  }

  static sortByProp(array: any[] = [], prop: string = '') { // prop HH:mm:ss
    return array.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1;
      }
      if (a[prop] > b[prop]) {
        return 1;
      }
      return 0;
    });
  }

  static getDateUI(date: string, format: string = DATE_UI_FORMAT) {
    return date ? moment(date).format(format) : '';
  }

  static getDateFormat(date: Date | string, format: string = DATE_FORMAT) {
    return date ? moment(date).format(format) : null;
  }

  static getDateFormatFromDateUI(date: Date | string, format: string = DATE_FORMAT) {
    return date ? moment(date, DATE_UI_FORMAT).format(format) : null;
  }

  static getDateFormatFromShortDateUI(date: Date | string, format: string = DATE_FORMAT) {
    return date ? moment(date, DATE_UI_SHORT_FORMAT).format(format) : null;
  }

  static getAbbreviationName(fullName: string = '') {
    return fullName ? fullName.split(' ').slice(0, 2).map((n) => (n[0] || '').toUpperCase()).join('') : '';
  }

  static getFolderName(text: string = '') {
    return text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  static getFirstDayAndLastDayOfWeek(date: Date | string = new Date(), offset: number = 0): FirstDayAndLastDay {
    return {
      firstDay: moment(date).add(offset, 'week').startOf('week'),
      lastDay: moment(date).add(offset, 'week').endOf('week')
    };
  }

  static getFirstDayAndLastDayOfMonth(date: Date | string = new Date(), offset: number = 0): FirstDayAndLastDay {
    return {
      firstDay: moment(date).add(offset, 'months').startOf('month'),
      lastDay: moment(date).add(offset, 'months').endOf('month')
    };
  }

  static getFirstDayAndLastDayOfLastXMonth(date: Date | string = new Date(), xMonth: number, offset: number = 0): FirstDayAndLastDay {
    return {
      firstDay: moment(date).add((offset * xMonth) - (xMonth - 1), 'months').startOf('month'),
      lastDay: moment(date).add((offset * xMonth), 'months').endOf('month')
    };
  }

  static getFirstDayAndLastDayOfYear(date: Date | string = new Date(), offset: number = 0): FirstDayAndLastDay {
    return {
      firstDay: moment(date).add(offset, 'years').startOf('year'),
      lastDay: moment(date).add(offset, 'years').endOf('year')
    };
  }

  static getFirstDayAndLastDayBeforeOf(startDate: Date, endDate: Date): FirstDayAndLastDay {
    const diff = moment(startDate).diff(moment(endDate), 'd');
    return {
      firstDay: moment(startDate).add(diff - 1, 'd'),
      lastDay: moment(startDate).add(-1, 'd')
    };
  }

  static isBelongToPeriod(date: string, period: DatePeriod) {
    const dateMoment = moment(date);
    const todayMoment = this.getFirstDayAndLastDayOfPeriod(period);
    if (todayMoment.firstDay.isSameOrBefore(dateMoment) && todayMoment.lastDay.isSameOrAfter(dateMoment)) {
      return true;
    }

    return false;
  }

  static getCurrentAndPreviousDateOfPeriod(selectedDateFilter: string, fromDate: string, toDate: string): {
    current: FirstDayAndLastDay;
    prev: FirstDayAndLastDay;
  } {
    let currentDateRange: FirstDayAndLastDay = {firstDay: moment(), lastDay: moment()};
    let prevDateRange: FirstDayAndLastDay = {firstDay: moment(), lastDay: moment()};

    if(selectedDateFilter === DatePeriod.CUSTOM) {
      currentDateRange = Object.assign({}, {firstDay: moment(fromDate), lastDay: moment(toDate)});
      prevDateRange = UtilService.getFirstDayAndLastDayBeforeOf(fromDate as any, toDate as any);

    } else {
      currentDateRange = UtilService.getFirstDayAndLastDayOfPeriod(selectedDateFilter as DatePeriod);
      prevDateRange = UtilService.getFirstDayAndLastDayBeforeOf(currentDateRange.firstDay as any, currentDateRange.lastDay as any);
    }

    return {
      current: currentDateRange,
      prev: prevDateRange,
    }
  }

  static getFirstDayAndLastDayOfPeriod(period: DatePeriod): FirstDayAndLastDay {
    if (period === DatePeriod.TODAY) {
      return {
        firstDay: moment(),
        lastDay: moment(),
      };
    }
    if (period === DatePeriod.THIS_WEEK) {
      return UtilService.getFirstDayAndLastDayOfWeek(new Date(), 0);
    }
    if (period === DatePeriod.THIS_MONTH) {
      return UtilService.getFirstDayAndLastDayOfMonth(new Date(), 0);
    }
    if (period === DatePeriod.PREVIOUS_MONTH) {
      return UtilService.getFirstDayAndLastDayOfMonth(new Date(), -1);
    }
    if (period === DatePeriod.NEXT_MONTH) {
      return UtilService.getFirstDayAndLastDayOfMonth(new Date(), 1);
    }
    if (period === DatePeriod.THIS_YEAR) {
      return UtilService.getFirstDayAndLastDayOfYear(new Date(), 0);
    }

    return {
      firstDay: null,
      lastDay: null,
    };
  }

  static getRangeDateFromPeriodFilter(period: SelectOption) {
    if (!period?.value) {
      return {
        fromDate: '',
        toDate: ''
      }
    }
    if (typeof period.value === "string") {
      const type: DatePeriod = period.value as DatePeriod;
      return {
        fromDate: UtilService.getFirstDayAndLastDayOfPeriod(type)?.firstDay?.format(DATE_FORMAT) || '',
        toDate: UtilService.getFirstDayAndLastDayOfPeriod(type)?.lastDay?.format(DATE_FORMAT) || ''
      }
    }
    return {
      fromDate: period.value.startDate,
      toDate: period.value.endDate
    }
  }

  static isImageURL(url: string = '') {
    return ['jpeg', 'jpg', 'gif', 'png', 'svg'].includes(this.getUrlExtension(url));
  }

  static isVideoURL(url: string = '') {
    return ['mp4', 'webm', 'ogg', 'mov'].includes(this.getUrlExtension(url));
  }

  static isPdfURL(url: string = '') {
    return ['pdf'].includes(this.getUrlExtension(url));
  }

  static getUrlExtension(url: string = '') {
    return (url || '').split(/[#?]/)[0].split('.').pop().trim().toLocaleLowerCase();
  }

  static getFileNameAndExtension(name: string = '') {
    const splits = name.split('.');
    let extension = '';
    let fileName = '';

    if (splits.length >= 2) {
      extension = splits.pop();
      fileName = splits.join('.');
    } else {
      fileName = name || '';
    }

    return [fileName, extension];
  }

  static getTaskKeywordText(keyword: string = '') {
    if (!keyword.includes('/')) {
      return keyword.trim();
    }

    return keyword.split('/').slice(-1)[0].trim();
  }

  static getProjectKeywordText(keyword: string = '') {
    if (!keyword.includes('/')) {
      return keyword.trim();
    }

    return keyword.split('/').slice(-1)[0].split('-')[0].trim();
  }

  static getStorgateText(storage: CompanyStorage) {
    return `${UtilService.formatBytes(storage.used || 0)} of ${UtilService.formatBytes(storage.total || 0)} used`;
  }

  static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) { return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static saveServerFile(res: HttpResponse<Blob>) {
    const contentDisposition = res.headers.get('content-disposition');
    const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replace(/\"/g, '').trim();
    UtilService.saveFile(res.body, filename);
  }

  static saveFile(blob: Blob, fileName?: string) {
    if ((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      if (fileName) {
        a.download = fileName;
      } else {
        const lastForwardSlash = url.lastIndexOf('/');
        const name = url.slice(lastForwardSlash + 1, url.length) + '.zip';
        a.download = name;
      }
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }

  static getTaskUrl(taskKey: string) {
    return `${window.location.origin}/task-mgmt/tasks/${taskKey}`;
  }

  static generateVideoThumbnail = (file: File) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        const dataUrl = canvas.toDataURL('image/png');
        const thumbnail = UtilService.dataURLtoFile(dataUrl, 'thumbnail.png');
        return resolve(thumbnail);
      };
    });
  }

  static dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  static getDiffTimeFromNow(previousDate: string): DiffTimeFromNow{
    const currentTime = moment(new Date());
    const previousTime = moment(previousDate);
    const diffTime = currentTime.diff(previousTime, 'minutes');
    const years = currentTime.diff(previousTime, 'years');
    if (diffTime <= 60) {
      return {type: UnitOfTime.minutes, value: diffTime};
    }
    if (diffTime > 60 && diffTime <= 1440) {
      const hours = currentTime.diff(previousTime, 'hours');
      return {type: UnitOfTime.hours, value: hours};
    }
    if (diffTime > 1440 && diffTime <= 43200){
      const days = currentTime.diff(previousTime, 'days');
      return {type: UnitOfTime.days, value: days};
    }
    if (diffTime > 43200 && diffTime <= 1296000){
      const months = currentTime.diff(previousTime, 'months');
      return {type: UnitOfTime.months, value: months};
    }
    return {type: UnitOfTime.years, value: years};
  }

  static getDiffTimeFromNowUI(date: string) {
    if (!date) {
      return;
    }

    const remainingTime = UtilService.getDiffTimeFromNow(date);
    let name = '';
    switch (remainingTime.type) {
      case UnitOfTime.minutes:
        name = remainingTime.value > 1 ? PeriodOfTimeAgo.minutesAgo : PeriodOfTimeAgo.now;
        break;
      case UnitOfTime.hours:
        name = remainingTime.value > 1 ? PeriodOfTimeAgo.hoursAgo : PeriodOfTimeAgo.oneHourAgo;
        break;
      case UnitOfTime.days:
        name = remainingTime.value > 1 ? PeriodOfTimeAgo.daysAgo : PeriodOfTimeAgo.oneDayAgo;
        break;
      case UnitOfTime.months:
        name = remainingTime.value > 1 ? PeriodOfTimeAgo.monthsAgo : PeriodOfTimeAgo.oneMonthAgo;
        break;
      default:
        name = remainingTime.value > 1 ? PeriodOfTimeAgo.yearsAgo : PeriodOfTimeAgo.oneYearAgo;
        break;
    }

    return {
      name,
      value: remainingTime.value,
    };
  }

  static checkIsHtml(value: string): boolean {
    const reg = new RegExp(REGREX_IS_HTML);
    return reg.test(value);
  }

  static getCurrentFieldValue(type: FieldType, value: FieldValue) {
    if (!value) {
      return null;
    }

    if (type === FieldType.text) {
      return value.text;
    }

    if (type === FieldType.number) {
      return value.number;
    }

    if (type === FieldType.datePicker) {
      return value.date ? new Date(moment(value.date).format(DATE_FORMAT)) : null;
    }

    if (type === FieldType.select) {
      return value?.options ?? null;
    }

    if (type === FieldType.multiSelect) {
      return value?.options ?? null;
    }

    if (type === FieldType.table) {
      return value.rows ?? [];
    }

    if (type === FieldType.file) {
      let files = [];
      if (value?.attachments) {
        files = [...files, ...value.attachments];
      }
      if (value?.file) {
        files = [...files, ...value.file];
      }
      return files;
    }

    return null;
  }

  static getFieldValueText(field: Field, value) {
    if (!value) {
      return null;
    }
    if (field.type === FieldType.datePicker) {
      return moment(value).format(DATE_UI_FORMAT) || null;
    }
    if (field.type === FieldType.multiSelect) {
      return value?.map(e => e?.name) || [];
    }
    if (field.type === FieldType.select) {
      return value?.map(e => e?.name) || [];
    }
    if (field.type === FieldType.file) {
      return (value || []).length > 0 ?  `${(value || []).length} items(s)` : '';
    }
    return value;
  }

  static setStorage(key: string, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static getStorage(key: string) {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  }

  static convertFullTimeTo12Hours(time: string): string {
    return moment(time, TIME_FORMAT).format(FORMAT_12_HOURS);
  }

  static convert12HoursToFullTime(time: string): string {
    return moment(time, FORMAT_12_HOURS).format(TIME_FORMAT);
  }
}
