import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { isObservable, Subscription } from "rxjs";
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UtilService } from 'src/app/core';
import { DETAIL_PAGE, GroupMenu, LeaveRequestSearch, MenuItem, MenuLevel, requestLeaveUserType, RequestStatus, routerObject, sidebarSize, User } from 'src/app/shared';
import { DynamicFieldDataService } from '../../dynamic-field/_services/dynamic-field-data.service';
import { DynamicFieldService } from '../../dynamic-field/_services/dynamic-field-service';

import { selectWaitingRequest, SiteActions, SiteManagementState } from '../../_store/site-management';
import { SearchSystemState } from '../../_store/search-system/search-system.model';
import { selectPage } from '../../_store/search-system';
import { AuthActions, AuthState } from 'src/app/core/store/auth';
import { Logger } from 'src/app/core/models/logger';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() groupMenus: GroupMenu[] = [];
  @Input() isWebLayout = true;
  @Input() currentSize;
  @Input() user: User;
  @Output() onResize: EventEmitter<string> = new EventEmitter();
  @Output() openSearch = new EventEmitter<any>();

  currentUrl = '';
  waitingRequests = 0;
  interval;
  requestStatus = RequestStatus;
  // Web property
  sidebarSize = sidebarSize;

  // Mobile property
  isShowMobileSidebar = false;
  isObservable = isObservable;
  updateDynamicSub: Subscription;
  levels = MenuLevel;
  initLevel = MenuLevel.level1;
  routerObject = routerObject;

  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private siteManagementStore: Store<SiteManagementState>,
    private dynamicFieldDataService: DynamicFieldDataService,
    protected searchSystemStore: Store<SearchSystemState>
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
      }
    });
  }

  ngOnInit(): void {
    this.countWaitingRequest();
    this.searchSystemStore.select(selectPage)
      .subscribe(
        (latestPage) => {
          if (latestPage) {
            this.onExpandCurrentModule();
          }
        }
      );
    this.onExpandCurrentModule();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  onExpandCurrentModule() {
    const menuItems: MenuItem[] = this.groupMenus.reduce(
      (acc, cur) => acc.concat(cur.childrens),
      []
    );

    menuItems.forEach((menu) => {
      const isExpand =
        this.isActiveItem(menu) ||
        menu.childrens?.some((item) => {
          return this.isActiveItem(item);
        });
      if (isExpand) {
        menu.isCollapsed = false;
        return true;
      }
      menu.isCollapsed = true;
      return false;
    });
  }

  countWaitingRequest() {
    this.siteManagementStore.select(selectWaitingRequest).subscribe(
      (data: number) => {
        this.waitingRequests = data;
      }
    );

    const payload: LeaveRequestSearch = {
      userType: requestLeaveUserType.approver,
      status: [this.requestStatus.WAITING_APPROVAL, this.requestStatus.CANCELLED_WAITING],
    };

    this.siteManagementStore.dispatch(SiteActions.loadWaitingRequestNumber({ data: payload }));
    this.interval = setInterval(() => {
      this.siteManagementStore.dispatch(SiteActions.loadWaitingRequestNumber({ data: payload }));
    }, 300000); // 5 minutes.
  }

  isActiveItem(item) {
    return item.routerLink && this.currentUrl.includes(item.routerLink);
  }

  onClickMenuItem(item: MenuItem): void {
    if (item?.clickable) {
      this.router.navigate([item.routerLink]);
      return;
    }

    if (item.routerLink && (!item.childrens || (item.childrens && item.isTabMenu))) {
      this.router.navigate([item.routerLink]);

      this.isShowMobileSidebar = false; // Mobile
      return;
    }

    if (item.childrens) {
      item.isCollapsed = !item.isCollapsed;
      return;
    }
  }

  changeSize() {
    if (this.currentSize === this.sidebarSize.small) {
      this.currentSize = this.sidebarSize.large;
      this.onResize.next(this.currentSize);
      return;
    }

    if (this.currentSize === this.sidebarSize.medium) {
      this.currentSize = this.sidebarSize.small;
      this.onResize.next(this.currentSize);
      return;
    }

    if (this.currentSize === this.sidebarSize.large) {
      this.currentSize = this.sidebarSize.medium;
      this.onResize.next(this.currentSize);
      return;
    }
  }

  // Mobile hanlder
  toggleMobileSidebar() {
    this.isShowMobileSidebar = !this.isShowMobileSidebar;
  }

  signOut() {
    this.authStore.dispatch(AuthActions.signOut());
  }

  isVisibleHash(item: MenuItem) {
    if (!item?.hash) {
      return item.visible;
    }
    const field = this.dynamicFieldDataService.getDynamicFieldByHash(item.hash);
    return field ? field.isVisible : true;
  }

  isVisibleGroup(group: GroupMenu) {
    return group.visible && group.childrens.some(e => this.isVisibleHash(e));
  }

  isBackIcon() {
    return localStorage.getItem(DETAIL_PAGE);
  }

  backToPreviousPage() {
    localStorage.removeItem(DETAIL_PAGE);
  }

  onSearch() {
    this.openSearch.emit();
  }
}
