import { SelectOption } from "../..";
import { ReminderPeriod, StopType } from "../_enums";

export const TASK_DETAIL_SIZE_DEFAULT = 500;
export const TASK_MODAL_CLASS = 'modal-task-detail';

export const taskReminders: SelectOption[] = [
  {
    name: 'In 30 minutes',
    value: ReminderPeriod.MINUTE_30,
  },
  {
    name: 'In 1 hour',
    value: ReminderPeriod.HOUR_1,
  },
  {
    name: 'In 2 hours',
    value: ReminderPeriod.HOUR_2,
  },
  {
    name: 'Next Day',
    value: ReminderPeriod.DAY_1,
  },
  {
    name: 'Next 2 Days',
    value: ReminderPeriod.DAY_2,
  }
];

export const stopTime: SelectOption[] = [
  {
    name: 'Never',
    value: StopType.NEVER,
  },
  {
    name: 'On',
    value: StopType.ON
  },
  {
    name: 'After',
    value: StopType.AFTER
  },
  // {
  //   name: `When task's statuses`,
  //   value: StopType.ON_STATUS
  // }
];

export const DuplicateLinkedTask = 'Task Link Is Duplicated';

export const UNSAVED_CACHES = 'expercUnsavedCaches';
export const UNSAVED_USER_KEY = 'user_';
export const UNSAVED_TASK_KEY = 'task_key_';
export const UNSAVED_COMMENT_KEY = 'comment_id_';
export const UNSAVED_REPLY_COMMENT_KEY = 'reply_comment_id_';

export const TASK_GIT_COMMIT_SORT_PROP = 'createdAt';

export const UNASSIGNED_USER = 'Unassigned';
