import { AppLanguage } from '../../_models';
import { LanguageCode, LanguageKey } from '../_enums';

export const appDefaultLang = LanguageCode.en;
export const appLanguages: AppLanguage[] = [
  {
    name: 'English',
    key: LanguageKey.en,
    code: LanguageCode.en,
  },
  {
    name: 'Tiếng Việt',
    key: LanguageKey.vi,
    code: LanguageCode.vi,
  },
];
