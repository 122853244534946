import { PageableModel } from './generic';
import { Project } from './project.model';

export interface Sender {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
}

export interface Source {
  id: number;
  name?: string;
  key?: string;
}

export interface NotificationShort {
  id: number;
  sender: Sender;
  action: string;
  createdAt: string;
  read: boolean;
  data: NotificationTaskData;
}

export interface NotificationData {
  targetId: number;
  targetType: string;
  targetKey: string;
  targetName: string;
  sourceId?: string;
  sourceType?: string;
  message?: string;
}

export interface NotificationTaskData extends NotificationData {
  projectId: number;
  projectKey: string;
  projectName: string;
  dayRemaining?: number;
}

export interface NotificationRequest {
  field: string;
  value: boolean;
}

export class NotificationSearch extends PageableModel {
  read: boolean;
  receiverId: number;
  constructor(obj?: NotificationSearch) {
    super(obj);
  }
}

export interface NotificationSetting {
  taskSendMailCreated?: boolean;
  taskSendMailAssignee?: boolean;
  taskSendMailChangeStatus?: boolean;
  taskSendMailMention?: boolean;
  taskOverDueDate?: boolean;
  taskOverDueDateAfter?: boolean;
  receiveTaskOverDueAfter?: number;
  taskSendMailReminder?: boolean;
  taskNotifyUpdate?: boolean;
  taskNotifyMention?: boolean;
  taskNotifyReminder?: boolean;
  taskNotifyCreated?: boolean;
}

export interface NotificationSettingRequest extends NotificationSetting {
  setAll?: boolean;
  setForAllTask?: boolean;
  turnOn?: boolean;
}

export interface NotificationSettingUI {
  label: string;
  id?: string;
  icon?: string;
  code: string;
  isCollapsed?: boolean;
  description?: string;
  child?: NotificationSettingUI[];
  additionalLabel?: string;
  additionalCode?: string;
}
