import { createAction, props } from '@ngrx/store';
import { PreviousWorkType } from './task.model';
import { Task, TaskPatch, TaskSearchRequest, TaskUpsert, WorkItemType } from 'src/app/shared';

export const selectWorkItem = createAction(
  '[Task Management] Select Previous Work Item Type',
  props<PreviousWorkType>()
);

export const getAllTaskTypes = createAction(
  '[Task Management] Select All Work Item Type',
  props<{ projectId: number; keyword?: string; force?: boolean }>()
);

export const setTaskTypes = createAction(
  '[Task Management] Set All Work Item Type',
  props<{ taskTypes: WorkItemType[] }>()
);

export const createTask = createAction(
  '[Task Management] Create Task',
  props<{ payload: TaskUpsert }>()
);

export const createTaskSuccess = createAction(
  '[Task Management] Create Task Success',
  props<{ task: Task }>()
);

export const createTaskFail = createAction(
  '[Task Management] Create Task Fail',
  props<{ error: any }>()
);

export const updateTask = createAction(
  '[Task Management] Update Task',
  props<{ taskId: number; payload: TaskPatch }>()
);

export const updateTaskSuccess = createAction(
  '[Task Management] Update Task Success',
  props<{ task: Task }>()
);

export const updateTaskFail = createAction(
  '[Task Management] Update Task Fail',
  props<{ error: any }>()
);

export const updateStartEndTask = createAction(
  '[Task Management] Update Start End Task',
  props<{ taskId: number; payload: [TaskPatch, TaskPatch] }>()
);

export const getWorkCalendarTasks = createAction(
  '[Task Management] Get Work Calendar Task',
  props<{ payload: TaskSearchRequest }>()
);

export const setWorkCalendarTasks = createAction(
  '[Task Management] Set Work Calendar Task',
  props<{ workCalendarTasks: Task[] }>()
);

export const TaskActions = {
  selectWorkItem,
  getAllTaskTypes,
  setTaskTypes,
  createTask,
  createTaskFail,
  createTaskSuccess,
  updateTask,
  updateTaskSuccess,
  updateTaskFail,
  updateStartEndTask,
  getWorkCalendarTasks,
  setWorkCalendarTasks
};
