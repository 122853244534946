<div class="pricing-info-wrapper {{type}}">
  <div class="period-wrapper">
    <div class="package-title">
      <app-i18n [key]="'Package'"></app-i18n>
    </div>

    <div class="price-permonth-wrapper">
      <span class="label"><app-i18n [key]="'Price per month when paying for'"></app-i18n></span>
      <ng-select class="select-price ld"
        [(ngModel)]="selectedPrice"
        [items]="prices"
        bindLabel="label"
        [clearable]="false"
        [searchable]="false"
        [compareWith]="compareWith"
        (change)="onPriceChange()">
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <div class="price-label" [title]="item?.name">
            <span>{{item.label}}</span>
            <span class="discount-label ml-2" *ngIf="item.discountText">{{item.discountText}}</span>
          </div>
        </ng-template>
      </ng-select>
      <span class="apply-date text-primary ml-3" *ngIf="applyDate">{{applyDate}}</span>
    </div>
  </div>

  <div class="modules-wrapper">
    <div class="m-item m-item-module-list px-0">
      <div class="feature-list">
        <div class="f-title"><app-i18n [key]="'PRODUCTIVITY ESSENTIALS'"></app-i18n></div>
        <div class="f-item"><app-i18n [key]="'Leave Management'"></app-i18n></div>
        <div class="f-item"><app-i18n [key]="'Work Tracking'"></app-i18n></div>
        <div class="f-item"><app-i18n [key]="'OKR'"></app-i18n></div>
        <div class="f-item"><app-i18n [key]="'Task Management'"></app-i18n></div>
        <!-- <div class="f-item">Test Management</div> -->
        <div class="f-item"><app-i18n [key]="'Timesheet'"></app-i18n></div>
        <div class="f-item"><app-i18n [key]="'Document Management'"></app-i18n></div>
        <div class="f-item"><app-i18n [key]="'HR'"></app-i18n></div>
        <div class="f-item">
          <app-i18n [key]="'Audit Log'"></app-i18n>
          <mat-icon class="ml-1 audit-log-note" [svgIcon]="'info-circle-fill'"
          [tooltip]="'Audit Log up to 1 year for just $2.99 per user/month.' | translate" placement="bottom">
          </mat-icon>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let m of modules; let index = index">
      <div class="m-item m-item-{{index}}" [ngClass]="{'selected': selectedModule?.id === m.id}" (click)="onClickModuleItem(m)">
        <div class="m-info">
          <div class="m-name"><app-i18n [key]="m.name"></app-i18n></div>
          <div class="m-desc"><app-i18n [key]="getModuleDesc(m.name)"></app-i18n></div>
        </div>
        <div class="m-rate-wrapper">
          <div class="m-rate">{{getRate(m.rate).trim() | translate}} <span class="original-rate" *ngIf="m.rate && selectedPrice?.discount > 0">${{m.rate}}</span></div>
          <div class="m-rate-desc">
            <span>
              <span *ngIf="m.rate"><app-i18n [key]="'per user / month'"></app-i18n></span>
              <span *ngIf="!m.rate"><app-i18n [key]="'Limited'"></app-i18n> {{m.limitedUser}} <app-i18n [key]="'users'"></app-i18n></span>
            </span>
            <span class="limited-storage">{{m.limitedStorage}}GB</span>
          </div>
          <div class="m-rate-desc-sub"><span *ngIf="m.name==='Free'">(<app-i18n [key]="'For whole company'"></app-i18n>)</span></div>

          <button class="btn btn-select-module" (click)="onSelectModule(m)" *ngIf="type === PricingInfoType.pricingPage">
            <span><app-i18n [key]="m.name==='Free' ? 'Register Free' : 'Try FREE for 30 days'"></app-i18n></span>
          </button>

          <button class="btn btn-select-module text-uppercase" (click)="onSelectModule(m)" *ngIf="type === PricingInfoType.changePlanPage">
            <span><app-i18n [key]="m.id===selectedModule?.id ? 'selected' : 'Select'"></app-i18n></span>
          </button>

          <label class="btn btn-select-module" *ngIf="type === PricingInfoType.signupPage">
            <span><app-i18n [key]="m.name==='Free' ? 'Free Forever' : 'FREE for 30 days'"></app-i18n></span>
          </label>
          <div class="text-no-credit"><app-i18n [key]="'No credit card required'"></app-i18n></div>
        </div>

        <div class="feature-list">
          <div class="f-item" [ngClass]="{'check': m.leaveTracking}">
            <ng-container *ngIf="m.leaveTrackingLimitUser && m.name !== 'Free'">
              <span class="check-label mr-1" *ngIf="m.leaveTracking">
                <app-i18n [key]="'Leave Tracking'"></app-i18n> ({{m.leaveTrackingLimitUser}} <app-i18n [key]="'users'"></app-i18n>)</span>
              <span class="count-label">{{m.leaveTrackingLimitUser}} <app-i18n [key]="'users'"></app-i18n></span>
            </ng-container>

            <ng-container *ngIf="!(m.leaveTrackingLimitUser && m.name !== 'Free')">
              <span class="check-label" *ngIf="m.leaveTracking"><app-i18n [key]="'Leave Tracking'"></app-i18n></span>
              <span class="material-icons-outlined check-icon" *ngIf="m.leaveTracking">
                check_circle
              </span>
            </ng-container>
          </div>
          <div class="f-item" [ngClass]="{'check': m.dailyReport}">
            <span class="check-label" *ngIf="m.dailyReport"><app-i18n [key]="'Daily Report'"></app-i18n></span>
            <span class="material-icons-outlined check-icon" *ngIf="m.dailyReport">
              check_circle
            </span>
          </div>
          <div class="f-item" [ngClass]="{'check': m.okr}">
            <span class="check-label" *ngIf="m.okr"><app-i18n [key]="'OKR'"></app-i18n></span>
            <span class="material-icons-outlined check-icon" *ngIf="m.okr">
              check_circle
            </span>
          </div>
          <div class="f-item" [ngClass]="{'check': m.taskManagement}">
            <span class="check-label mr-1">
              <app-i18n [key]="'Task Management'"></app-i18n>
              {{m.allowProjectType === ('Kanban'| translate ) ? '('+('Kanban'| translate )+')' : ''}}
              {{m.taskManagementLimitUser ? '(' + m.taskManagementLimitUser + (' users' | translate ) + ')' : ''}}</span>
            <ng-container *ngIf="m.allowProjectType">
              <span class="count-label" *ngIf="m.allowProjectType === 'Kanban'"><app-i18n [key]="'Kanban'"></app-i18n>
                {{m.taskManagementLimitUser ? '(' + m.taskManagementLimitUser + (' users' | translate ) + ')' : ''}}
              </span>
            </ng-container>

            <ng-container *ngIf="!m.allowProjectType">
              <span class="material-icons-outlined check-icon" *ngIf="m.taskManagement">
                check_circle
              </span>
            </ng-container>
          </div>
          <!-- <div class="f-item" [ngClass]="{'check': m.testManagement}">
            <span class="check-label" *ngIf="m.testManagement">Test Management</span>
            <span class="material-icons-outlined check-icon" *ngIf="m.testManagement">
              check_circle
            </span>
          </div> -->
          <div class="f-item" [ngClass]="{'check': m.timesheet}">
            <span class="check-label" *ngIf="m.timesheet"><app-i18n [key]="'Timesheet'"></app-i18n></span>
            <span class="material-icons-outlined check-icon" *ngIf="m.timesheet">
              check_circle
            </span>
          </div>
          <div class="f-item" [ngClass]="{'check': m.documentManagement}">
            <span class="check-label" *ngIf="m.documentManagement"><app-i18n [key]="'Document Management'"></app-i18n></span>
            <span class="material-icons-outlined check-icon" *ngIf="m.documentManagement">
              check_circle
            </span>
          </div>
          <div class="f-item" [ngClass]="{'check': m.hr}">
            <span class="check-label" *ngIf="m.hr"><app-i18n [key]="'HR'"></app-i18n></span>
            <span class="material-icons-outlined check-icon" *ngIf="m.hr">
              check_circle
            </span>
          </div>
          <div class="f-item check text-nowrap" >
            <app-i18n [key]="'Audit Log'"></app-i18n>&nbsp;1&nbsp;<app-i18n [key]="'month'"></app-i18n>*
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
