import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UrlHelperService, UtilService } from 'src/app/core';
import { BackLog, BackLogSearchRequest, List2Res, Pageable, PageInfo, Sprint, Task } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import { BacklogSearchParam, SprintToBacklogRequest, TasksPatchRequest } from './../../../../shared/_models/backlog.model';
import { BacklogDataFilterService } from './backlog-data-filter.service';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})
export class BacklogService extends EntityCollectionServiceBase<BackLog>{
  public page$: Observable<PageInfo> = new Observable();
  public sprintHasTaskChanges: EventEmitter<number> = new EventEmitter();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
    backlogDataFilterService: BacklogDataFilterService
  ) {
    super('backlogs', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }

  getBackLogs(request: BackLogSearchRequest, pageable: Pageable) {
    const cloneRequest: BackLogSearchRequest = {...request};
    if (cloneRequest.fromDate) {
      cloneRequest.fromDate = cloneRequest.fromDate + 'T00:00:00.000Z';
    }
    if (cloneRequest.toDate) {
      cloneRequest.toDate = cloneRequest.toDate + 'T23:59:59.000Z';
    }
    cloneRequest.keyword = UtilService.getTaskKeywordText(cloneRequest.keyword || '');
    const params = queryString.stringify({...cloneRequest, ...pageable});
    return this.getWithQuery(params);
  }

  addTasksToBacklogs(payload: { taskIds: number[] }) {
    this.setLoading(true);
    return this.http.post(`${environment.apiUrl}/backlogs/`, payload)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getSprints(projectId: number, params: string = ''): Observable<Sprint[]> {
    const requestUrl = UrlHelperService.resolveApiUrl(`projects/${projectId}/board-sprints/list-sprint-in-backlog?${params}`);
    return this.http.get<Sprint[]>(requestUrl);
  }

  // getSprint(sprintId: number): Observable<Sprint> {
  //   this.setLoading(true);
  //   const requestUrl = UrlHelperService.resolveApiUrl(`projects/${sprintId}/board-sprints/list-sprint-in-backlog`);
  //   return this.http.get<Sprint>(requestUrl)
  //     .pipe(finalize(() => this.setLoading(false)));
  // }

  deleteSprint(projectId: number, sprintId: number, moveToSprintId: number): Observable<Sprint> {
    this.setLoading(true);
    const data = {
      moveToSprintId: moveToSprintId
    }

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        projectId,
        sprintId,
        moveToSprintId
      },
    };

    const requestUrl = UrlHelperService.resolveApiUrl(`projects/${projectId}/board-sprints/${sprintId}`);
    return this.http.delete<Sprint>(requestUrl, options)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getTasksBySprint(sprintId: number, searchParam: BacklogSearchParam = null): Observable<List2Res<Task>> {
    let query = `keyword=&&page=0&size=10000`;
    if (searchParam) {
      query = searchParam.toQueryString();
    }

    const requestUrl = UrlHelperService.resolveApiUrl(`tasks/?sprintIds=${sprintId}&${query}`);
    return this.http.get<List2Res<Task>>(requestUrl);
  }

  createSprint(sprint: Sprint): Observable<Sprint> {
    this.setLoading(true);
    return this.http.post<Sprint>(UrlHelperService.resolveApiUrl(`projects/${sprint.projectId}/board-sprints/`),
      sprint)
      .pipe(
        finalize(() => this.setLoading(false)),
      );
  }
  updateSprint(sprint: Sprint): Observable<Sprint> {
    this.setLoading(true);
    return this.http.put<Sprint>(UrlHelperService.resolveApiUrl(`projects/${sprint.projectId}/board-sprints/${sprint.id}/`),
      sprint)
      .pipe(
        finalize(() => this.setLoading(false))
      );
  }

  startSprint(sprint: Sprint): Observable<Sprint> {
    this.setLoading(true);
    return this.http.post<Sprint>(UrlHelperService.resolveApiUrl(`projects/${sprint.projectId}/board-sprints/${sprint.id}/start`),
      sprint)
      .pipe(
        finalize(() => this.setLoading(false))
      );
  }

  completeSprint(sprint: Sprint): Observable<Sprint> {
    this.setLoading(true);
    return this.http.post<Sprint>(UrlHelperService.resolveApiUrl(`projects/${sprint.projectId}/board-sprints/${sprint.id}/complete-sprint`),
      sprint)
      .pipe(
        finalize(() => this.setLoading(false))
      );
  }


  getBacklogTasks(projectId: number, searchParam: BacklogSearchParam = null): Observable<List2Res<Task>> {
    let query = `projectIds=${projectId}`;
    if (searchParam) {
      query = searchParam.toQueryString();
    }

    const requestUrl = UrlHelperService.resolveApiUrl(`tasks/?${query}&taskBacklogFlg=true`);
    return this.http.get<List2Res<Task>>(requestUrl)
      .pipe(finalize(() => {
        this.setLoading(false);
      }));
  }

  patchTasks(payload: TasksPatchRequest) {
    return this.http.patch<Task[]>(`${environment.apiUrl}/backlogs/bulk-edit`, payload);
  }

  moveSprintToBacklog(payload: SprintToBacklogRequest) {
    return this.http.put<Task[]>(`${environment.apiUrl}/backlogs/move-to-location`, payload);
  }
}
