import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskState } from './task.model';

export const featureKey = 'tasks';

const selectFeature = createFeatureSelector<TaskState>(featureKey);

export const selectPreviousWorkType = createSelector(
  selectFeature,
  (state: TaskState) => state.preWorkType
);

export const selectTaskTypes = createSelector(
  selectFeature,
  (state: TaskState) => state.taskTypes
);

export const selectTaskCreated = createSelector(
  selectFeature,
  (state: TaskState) => state.taskCreated
);


export const selectTaskUpdated = createSelector(
  selectFeature,
  (state: TaskState) => state.taskUpdated
);

export const selectWorkCalendarTasks = createSelector(
  selectFeature,
  (state: TaskState) => state.workCalendarTasks
);

export const TaskSelectors = {
  selectPreviousWorkType,
  selectTaskTypes,
  selectWorkCalendarTasks
}