import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoading } from 'src/app/core/store/app';
import { FADE_FAST } from '../../_utils/animate';
import { PROCESSING_MESSAGE } from 'src/app/core/constants/message';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [FADE_FAST]
})
export class LoadingComponent implements OnInit {
  visible = false;
  message = PROCESSING_MESSAGE;
  level: 'info' | 'error' | 'normal' = 'normal';
  timeout = null;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.select(selectLoading).subscribe((res) => {
      clearTimeout(this.timeout);

      if (res) {
        setTimeout(() => {
          this.message = res.text || PROCESSING_MESSAGE;
          this.level = res.level || 'normal';
        }, 300);

        if (res.visible) {
          this.visible = res.visible;
        } else {
          this.timeout = setTimeout(() => {
            this.visible = res.visible;
          }, 1000);
        }
      }
    });
  }
}
