<div class="row p-4">
    <div class="col-5 text-center">
        <img
            class="rounded-circle avatar mb-3"
            [src]="user?.avatarLarge || '../../../../../assets/icons/person.svg'"
            alt=""
        />

        <div class="text-20px font-semibold">
            {{ user?.fullName || '#Name' }}
        </div>

        <div class="mb-3 job-title">{{ user?.jobTitle || '#Job title' }}</div>

        <button (click)="onClick()" [routerLink]="'/organization/users/' + user?.id" class="btn btn-primary btn-create">
            View Profile Details
        </button>
    </div>
    <div class="col-7">
        <div class="row form-group mb-4">
            <label class="col-3" for="Phone">Phone</label>
            <input class="form-control col-9" [ngModel]="user?.phone" disabled />
        </div>
        <div class="row form-group mb-4">
            <label class="col-3" for="Skype">Skype</label>
            <input class="form-control col-9" [ngModel]="user?.userProfile?.skype" disabled />
        </div>
        <div class="row form-group mb-4">
            <label class="col-3" for="Email">Email</label>
            <input class="form-control col-9" [ngModel]="user?.email" disabled />
        </div>
        <div class="row form-group mb-4">
            <label class="col-3" for="Birthday">Birthday</label>
            <input class="form-control col-9" [ngModel]="user?.userProfile?.birthday | date: 'mediumDate'" disabled />
        </div>
    </div>
</div>
