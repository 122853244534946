import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-horizontal-wrapper',
  template: `
    <div class="form-wrapper">
      <label [attr.for]="id" class="col-form-label" *ngIf="props.label">
        {{ props.label }}
      </label>

      <mat-icon
        *ngIf="props.icon"
        fontSet="material-icons-outlined"
        class="form-icon"
      >
        {{ props.icon }}
      </mat-icon>

      <div class="form-field" [ngClass]="{ 'form-field-error': showError }">
        <ng-template #fieldComponent></ng-template>

        <div *ngIf="showError" class="form-error invalid-feedback d-block">
          <formly-validation-message
            [field]="field"
          ></formly-validation-message>
        </div>
      </div>
    </div>
  `
})
export class HorizontalWrapper extends FieldWrapper {}
