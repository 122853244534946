<div class="language-wrapper">
  <div
    class="btn-group ml-auto"
    dropdown
    [insideClick]="true"
  >
    <button
      id="button-alignment"
      dropdownToggle
      type="button"
      class="btn-current-lang"
      *ngIf="isWebLayout"
    >
      {{ currentLanguage.code }}
    </button>

    <button
      id="button-alignment"
      dropdownToggle
      type="button"
      class="btn-current-lang mobile"
      *ngIf="!isWebLayout"
    >
      {{ currentLanguage.name }}
      <span class="text-uppercase mr-2">({{ currentLanguage.code }})</span>
      <mat-icon [svgIcon]="'arrow-drop-down'" class="icon-mini"></mat-icon>
    </button>
    <div
      id="dropdown-alignment"
      *dropdownMenu
      [ngClass]="isWebLayout ? '' : 'dropdown-menu-top-left'"
      class="dropdown-menu  mt-0 dropdown-menu-right"
      role="menu"
      aria-labelledby="button-alignment"
    >
      <div class="m-3">
        <app-search-input
          (search)="onSearchText($event)"
        ></app-search-input>
      </div>
      <li role="menuitem" *ngFor="let lang of languaguesDisplay">
        <button
          class="dropdown-item d-flex align-items-center"
          [ngClass]="{
            'current': currentLanguage.code === lang.code && isWebLayout,
            'text-primary': currentLanguage.code === lang.code && !isWebLayout
          }"
          (click)="switchLanguage(lang)"
        >
          <div class="lang-code mr-2" *ngIf="isWebLayout">
            {{ lang.code }}
          </div>

          <div>
            {{ lang.name }}
          </div>

          <div class="text-uppercase ml-1" *ngIf="!isWebLayout">
            ({{ lang.code }})
          </div>
        </button>
      </li>
    </div>
  </div>

  <div class="copy-right d-flex align-items-center h-100" *ngIf="!isWebLayout">
    <span>|</span><span class="px-3">&copy; XCORP {{currentYear}}</span>
  </div>
</div>
