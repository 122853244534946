<div class="app-editor-form-wrapper">
  <app-editor #appEditor
    [control]="control"
    [placeholder]="placeholder | translate"
    [getUserPromise]="getUserPromise"
    [upload$]="upload$"
    (onEditorCreated)="getEditorInstance($event)"
    ></app-editor>

  <div class="text-right mt-2">
    <button type="button" class="btn btn-white" (click)="onCancel()" [disabled]="isSaving">
      <app-i18n [key]="'Cancel'"></app-i18n>
    </button>
    <button type="button" class="btn btn-primary ml-2" (click)="onSave()"
      [disabled]="isSaving || appEditor.isUploading || isDisabled">
      <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" *ngIf="isSaving"></span>
      <app-i18n [key]="'Save'"></app-i18n>
    </button>
  </div>
</div>
