import { createFeature, createReducer, on } from '@ngrx/store';
import { TaskActions } from './task.actions';
import { featureKey } from './task.selectors';
import { TaskState } from './task.model';

const initialState: TaskState = {
  taskTypes: [],
  preWorkType: null,
  taskCreated: null,
  taskUpdated: null,
  workCalendarTasks: []
};

export const taskReducer = createReducer(
  initialState,
  on(TaskActions.selectWorkItem, (state, { projectId, workItemType }) => ({
    ...state,
    preWorkType: { projectId, workItemType }
  })),
  on(TaskActions.setTaskTypes, (state, { taskTypes }) => ({
    ...state,
    taskTypes
  })),
  on(TaskActions.createTaskSuccess, (state, { task }) => ({
    ...state,
    taskCreated: task
  })),
  on(TaskActions.updateTaskSuccess, (state, { task }) => ({
    ...state,
    taskUpdated: task
  })),
  on(TaskActions.setWorkCalendarTasks, (state, { workCalendarTasks }) => ({
    ...state,
    workCalendarTasks
  }))
);

export const taskFeature = createFeature({
  name: featureKey,
  reducer: taskReducer
});
