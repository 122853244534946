import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { appDefaultLang, User } from 'src/app/shared';
import { ToastService } from '../_services';
import * as queryString from 'query-string';
import { SKIP_NAVIGATE } from '../constants/error';
import { HttpCode } from '../models/http-error';
import { environment } from 'src/environments/environment';
import { Logger } from '../models/logger';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  user: User;
  constructor(
    private router: Router,
    private authService: AuthService,
    private toast: ToastService,
    private translateService: TranslateService,
  ) {
    this.user = this.authService.getLoggedUser();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (!request.url.startsWith(environment.apiUrl)) {
            return throwError(JSON.stringify(error.error));
          }

          if (error.status === HttpCode.UNAUTHORIZED) {
            console.error(`401 request: ${request.url}`);
            this.gotoSignIn();
            return throwError(() => SKIP_NAVIGATE);
          }

          if (error.status === HttpCode.PAYMENT_REQUIRED) { // Payment required
            this.toast.notifyTrialExpired();
            return throwError(null);
          }

          if (error.status === HttpCode.NOT_ACCEPTABLE) {
            if (error.message === 'Invalid Company Secret') {
              this.gotoSignIn();
            }
            else {
              return throwError(JSON.stringify({ ...error.error, status: HttpCode.NOT_ACCEPTABLE }));
            }
          }

          return throwError(JSON.stringify(error.error));
        })
      );
  }

  gotoSignIn() {
    this.authService.signOut();
    const lastUrl = window.location.pathname + window.location.search;
    this.authService.setLastUrl(lastUrl);
    const params: Params = this.user?.company && this.user?.email
      ? { 'signout-domain': this.user.company.domain, 'signout-email': this.user.email } : {};

    const language = this.translateService.currentLang || appDefaultLang;

    // FUA: use query string for navigate by url
    const queryParams = queryString.stringify(params);

    this.router.navigateByUrl(`${language}/auth/sign-in?${queryParams}`);
  }
}
