import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import * as moment from 'moment';
import * as queryString from 'query-string';
import { Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { UtilService } from 'src/app/core';
import {
  DATE_FORMAT,
  FieldSuggestionRequest,
  LinkTaskRequest, List2Res, MoveMultiTasksRequest, Pageable, PageInfo, Task, TaskAttachment,
  TaskAttachmentType, TaskCloneRequest, TaskClones, TaskDescription, TaskFieldDetail, TaskLink, TaskLogTimeSearchRequest, TaskPatch,
  TaskPatchType, TaskReminder, TaskReminderRequest, TaskSearchRequest, TaskTrackingReportResponse, TaskTrackingReportSearchRequest, tEndDateTime, tStartDateTime
} from 'src/app/shared';
import { XqlFieldValue } from 'src/app/shared/_components/antlr/xql-editor/_models/xql.model';
import { LogTimeResponse } from 'src/app/shared/_models/time-log.model';
import { environment } from 'src/environments/environment';
import { FileService } from '../../document/_services/file.service';
import { GitCommitProjectResponse, GitCommitResponse } from '../../projects/project-git/_models';

@Injectable({
  providedIn: 'root'
})

export class TaskService extends EntityCollectionServiceBase<Task> {
  public page$: Observable<PageInfo> = new Observable();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
    private fileService: FileService,
  ) {
    super('tasks', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }

  getById(id: number): Observable<Task> {
    this.setLoading(true);
    return this.http.get<Task>(`${environment.apiUrl}/tasks/${id}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getByTaskKey(taskKey: string): Observable<Task> {
    const projectKey = (taskKey || '').split('-')[0] || '';
    const domain = window.location.host;
    this.setLoading(true);
    return this.http.get<Task>(`${environment.apiUrl}/tasks/key/${taskKey}?projectKey=${projectKey}&domain=${domain}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  patch(id: number, payload: TaskPatch, transform = true): Observable<Task> {
    return this.http.patch<Task>(`${environment.apiUrl}/tasks/${id}`, payload).pipe();
  }

  deleteTask(id: number): Observable<Task> {
    return this.http.delete<Task>(`${environment.apiUrl}/tasks/${id}`).pipe();
  }

  getTasks(payload: TaskSearchRequest, pageable: Pageable) {
    payload.keyword = UtilService.getTaskKeywordText(payload.keyword || '');
    const params = queryString.stringify({ ...payload, ...pageable });

    this.setLoading(true);
    return this.http.get<List2Res<Task>>(`${environment.apiUrl}/tasks/?${params}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getReleaseNoteTasks(payload: TaskSearchRequest, pageable: Pageable) {
    payload.keyword = UtilService.getTaskKeywordText(payload.keyword || '');
    const params = queryString.stringify({ ...payload, ...pageable });

    this.setLoading(true);
    return this.http.get<List2Res<Task>>(`${environment.apiUrl}/tasks/task-release-note?${params}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getAttachments(taskId: number) {
    this.setLoading(true);
    return this.http.get<TaskAttachment[]>(`${environment.apiUrl}/tasks/${taskId}/attachments`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getTasksBySprint(sprintId: number): Observable<Task[]> {
    const params = `keyword=&sprintIds=${sprintId}&page=0&size=10000`;
    return this.getWithQuery(params);
  }

  getTasksByEpic(epicId: number, pageable: Pageable, searchRequest?: TaskSearchRequest): Observable<List2Res<Task>> {
    const params =  queryString.stringify({...searchRequest, ...pageable});
    this.setLoading(true);
    return this.http.get<List2Res<Task>>(`${environment.apiUrl}/tasks/?epicIds=${epicId}&${params}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getByTrackingReport(payload: TaskTrackingReportSearchRequest, pageable: Pageable) {
    const params = queryString.stringify({ ...payload, ...pageable });

    this.setLoading(true);
    return this.http.get<List2Res<TaskTrackingReportResponse>>(`${environment.apiUrl}/tasks/tracking-report?${params}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getLogTime(payload: TaskLogTimeSearchRequest, pageable: Pageable) {
    let params = `taskId=${payload.taskId || ''}&userId=${payload.userId || ''}&date=${payload.date || ''}`;
    params += `&page=${pageable.page || ''}&size=${pageable.size || ''}&sort=${pageable.sort || ''}`;
    this.setLoading(true);
    return this.http.get<List2Res<LogTimeResponse>>(`${environment.apiUrl}/tasks/${payload.taskId}/log-time?${params}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  countLogTime(payload: TaskLogTimeSearchRequest) {
    const params = `taskId=${payload.taskId || ''}&userId=${payload.userId || ''}&date=${payload.date || ''}`;
    this.setLoading(true);
    return this.http.get<number>(`${environment.apiUrl}/tasks/${payload.taskId}/count-log-time?${params}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  addAttachments(id: number, files: File[], type: TaskAttachmentType): Observable<HttpEvent<TaskAttachment[]>> {
    const formData = new FormData();
    formData.append('type', type);

    files.forEach((file) => {
      formData.append('attachments', file);
    });

    this.setLoading(true);
    return this.http.post<TaskAttachment[]>(`${environment.apiUrl}/tasks/${id}/add-attachments`,
      formData,
      {
        reportProgress: true,
        observe: 'events'
      }
    )
      .pipe(finalize(() => this.setLoading(false)));
  }

  addEditorAttachment(taskId: number, file, type: TaskAttachmentType): Observable<TaskAttachment> {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('attachments', file);

    this.setLoading(true);
    return this.http.post(`${environment.apiUrl}/tasks/${taskId}/add-attachments`, formData)
      .pipe(switchMap((payload: TaskAttachment[]) => {
        return this.getAttachmentObjectURL(payload[0]);
      }));
  }

  getAttachmentObjectURL(attachment: TaskAttachment) {
    return this.fileService.getAuthenticatedUrl(attachment.url).pipe(map(objectUrl => ({ objectUrl, ...attachment })));
  }

  removeAttachments(id: number, attachments: TaskAttachment[]) {
    this.setLoading(true);
    return this.http.request<TaskAttachment[]>('delete', `${environment.apiUrl}/tasks/${id}/remove-attachments`, { body: { attachments } })
      .pipe(finalize(() => this.setLoading(false)));
  }

  linkTask(taskId: number, payload: LinkTaskRequest) {
    this.setLoading(true);
    return this.http.post<TaskLink>(`${environment.apiUrl}/tasks/${taskId}/link-task`, payload)
      .pipe(finalize(() => this.setLoading(false)));
  }

  unlinkTask(taskId: number, linkTaskId: number) {
    this.setLoading(true);
    return this.http.request<any>('delete', `${environment.apiUrl}/tasks/${taskId}/link-task/${linkTaskId}/remove`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getSubtasks(taskId: number) {
    this.setLoading(true);
    return this.http.get<Task[]>(`${environment.apiUrl}/tasks/${taskId}/list-subtasks`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  createSubtask(taskId: number, payload: { name: string }) {
    this.setLoading(true);
    return this.http.post<Task>(`${environment.apiUrl}/tasks/${taskId}/create-subtask`, payload)
      .pipe(finalize(() => this.setLoading(false)));
  }

  mentionUsers(taskId: number, payload: { userIds: number[], content: string }) {
    this.setLoading(true);
    return this.http.post(`${environment.apiUrl}/tasks/${taskId}/mention`, payload)
      .pipe(finalize(() => this.setLoading(false)));
  }

  downloadAttachment(url: string): Observable<HttpResponse<Blob>> {
    this.setLoading(true);
    return this.http.get<Blob>(`${environment.apiUrl}/tasks/download-attachment?url=${url}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    })
      .pipe(finalize(() => this.setLoading(false)));
  }

  updateThumbnail(taskId: number, fileId: number, thumbnail: File) {
    const formData = new FormData();
    formData.append('fileId', `${fileId}`);
    formData.append('thumbnail', thumbnail);

    this.setLoading(true);
    return this.http.put<TaskAttachment>(`${environment.apiUrl}/tasks/${taskId}/attachments/thumbnail`,
      formData
    ).pipe(finalize(() => this.setLoading(false)));
  }

  getTaskReminder(taskId: number) {
    this.setLoading(true);
    return this.http.get<TaskReminder>(`${environment.apiUrl}/tasks/${taskId}/remind`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  updateTaskReminder(taskId: number, payload: TaskReminderRequest) {
    this.setLoading(true);
    return this.http.put<TaskReminder>(`${environment.apiUrl}/tasks/${taskId}/remind`, payload)
      .pipe(finalize(() => this.setLoading(false)));
  }

  advancedSearch(payload: {query: string}, pageable: Pageable) {
    const params = queryString.stringify({ ...payload, ...pageable });

    return this.http.get<List2Res<Task>>(`${environment.apiUrl}/tasks/advanced-search?${params}`);
  }

  getFieldDetail(field: string = '') {
    return this.http.get<TaskFieldDetail>(`${environment.apiUrl}/tasks/get-field-detail?field=${field}`);
  }

  getFieldValue(payload: FieldSuggestionRequest, pageable: Pageable): Observable<XqlFieldValue[]> {
    const params = queryString.stringify({ ...payload, ...pageable });
    return this.http.get<any>(`${environment.apiUrl}/tasks/get-field-suggestion?${params}`).pipe(map(res => res.options));
  }

  deleteTaskReminder(taskId: number) {
    this.setLoading(true);
    return this.http.delete(`${environment.apiUrl}/tasks/${taskId}/remind`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getWorkCalendar(payload: TaskSearchRequest, pageable: Pageable) {
    const params = queryString.stringify({ ...payload, ...pageable });
    return this.http.get<List2Res<Task>>(`${environment.apiUrl}/tasks/?${params}`);
  }

  cloneTasks(payload: TaskCloneRequest) {
    return this.http.post<TaskClones>(`${environment.apiUrl}/tasks/clones`, payload);
  }

  moveMultiTask(payload: MoveMultiTasksRequest) {
    return this.http.patch<any>(`${environment.apiUrl}/tasks/move-multi-task`, payload);
  }

  getTaskCommits(id: number, pageable: Pageable) {
    const params = queryString.stringify({ ...pageable });
    return this.http.get<List2Res<GitCommitProjectResponse>>(`${environment.apiUrl}/tasks/${id}/git-commits?${params}`);
  }

  getTaskDescription(id: number) {
    return this.http.get<TaskDescription>(`${environment.apiUrl}/tasks/${id}/description`);
  }
}
