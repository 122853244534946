import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TenantIdentityGuard  {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const { tenant, code, id } = route.queryParams;

        const isUndefined = Boolean(tenant && code && id);

        if (!isUndefined) {
            this.router.navigateByUrl('/w/home');
        }

        return isUndefined;
    }
}
