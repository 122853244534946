import { FIRST_PAGE, PageSize, Pageable } from '..';

export interface BoardStatus {
  id: number;
  name: string;
  colorCode: string;
  defaultFlg: boolean;
  icon: string;
  deleted: boolean;
  orderNumber: number;
  numTask?: number;
  count?: number;
  isEdit?: boolean;
  statusCategory: StatusCategory;
  // tasks?: Task[];
}

export interface BoardStatusCreateRequest {
  columnId?: number;
  name: string;
  colorCode?: string;
  icon?: string;
  orderNumber?: number;
}

export interface BoardStatusDeleteRequest {
  statusId: number;
}

export interface SettingStatusRequest {
  statusIds: number[];
  defaultId: number;
  statusCategory: StatusCategory;
}

export enum StatusCategory {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED',
  VERIFYING = 'VERIFYING',
  QC_PASSED = 'QC_PASSED',
  PRODUCTION = 'PRODUCTION',
  CLOSE = 'CLOSE',
}

export class StatusSearchParams implements Pageable {
  page?: number;
  size?: number;
  sort?: string | string[];
  keyword?: string;

  constructor(obj?: Pageable) {
    this.page = obj?.page || FIRST_PAGE;
    this.size = obj?.size || PageSize.loadAll;
    this.sort = obj?.sort;
  }
}
