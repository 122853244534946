import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Attachment } from '../..';
import { EditorComponent, EditorUserMention } from '../editor/editor.component';
import Quill from 'quill';

export interface EditorFormPayload {
  htmlString: string;
  attachments: Attachment[];
  selectedUserMention?: any[];
}

@Component({
  selector: 'app-editor-form',
  templateUrl: './editor-form.component.html',
  styleUrls: ['./editor-form.component.scss']
})
export class EditorFormComponent implements OnInit {
  @Input() htmlString = '';
  @Input() placeholder = 'Insert text here...';
  @Input() isSaving = false;
  @Input() hasDraft = false;
  @Input() getUserPromise: (searchTerm: string) => Promise<EditorUserMention[]>;
  @Input() upload$: (file: File) => Observable<Attachment>;
  @Output() save: EventEmitter<EditorFormPayload> = new EventEmitter();
  @Output() change: EventEmitter<EditorFormPayload> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() onEditorCreated: EventEmitter<Quill> = new EventEmitter<Quill>();

  @ViewChild('appEditor') editorComponent: EditorComponent;
  control: UntypedFormControl = new UntypedFormControl('');
  isDisabled = true;
  constructor() { }

  ngOnInit(): void {
    this.control.setValue(this.htmlString);

    this.control.valueChanges
      .pipe(debounceTime(500))
      .subscribe(value => {
        this.change.emit(this.setupPayload());
        if (this.hasDraft) {
          this.isDisabled = false;
          return;
        }
        if (value && value !== this.htmlString) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      });
  }

  onSave() {
    this.save.emit(this.setupPayload());
  }

  setupPayload(): EditorFormPayload {
    const attachments: Attachment[] = this.editorComponent.getUploadAttachments();
    let htmlString: string = this.control.value;

    attachments.forEach(e => {
      htmlString = htmlString.replace(e.objectUrl, e.url);
    });

    const selectedUserMention = this.editorComponent?.getUserMentions();

    return { attachments, htmlString, selectedUserMention };
  }

  onCancel() {
    this.cancel.emit();
  }

  getEditorInstance(quill) {
    this.onEditorCreated.emit(quill);
  }
}
