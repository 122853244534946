import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { SearchOverlayConfig } from '../../_models/app/search-overlay.model';

@Component({
  selector: 'app-search-overlay',
  templateUrl: './search-overlay.component.html',
  styleUrls: ['./search-overlay.component.scss']
})
export class SearchOverlayComponent implements OnInit, OnDestroy {
  @Input() config: SearchOverlayConfig = new SearchOverlayConfig();
  @Input() canEdit = true;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() open: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.config.searchTypeahead$
      .pipe(debounceTime(500))
      .subscribe((searchText: string) => {
        this.config.search(searchText);
      });
  }

  ngOnDestroy(): void {
    this.config.searchTypeahead$?.unsubscribe();
  }

  onOpenSearch() {
    this.open.emit();
  }

  itemIndex(item: any) {
    return this.config.selecteds.findIndex(e => e[this.config.bindId] === item[this.config.bindId]);
  }

  isSelected(item: any) {
    return this.itemIndex(item) !== -1;
  }

  toggleItem(item: any) {
    const index = this.itemIndex(item);

    if (this.config.multiple) {
      if (index !== -1) {
        this.config.selecteds.splice(index, 1);
      } else {
        this.config.selecteds.push(item);
      }
      this.change.emit(this.config.selecteds);
      return;
    }

    if (index === -1) {
      this.config.selecteds.splice(0, this.config.selecteds.length);
      this.config.selecteds.push(item);
      this.change.emit(item);
    }
  }

}
