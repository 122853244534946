export enum NotificationSettingPayload {
  TURN_ON = 'turnOn',
  SET_FOR_ALL_TASK = 'setForAllTask',
  TASK_SEND_MAIL_CREATED = 'taskSendMailCreated',
  TASK_SEND_MAIL_ASSIGNEE = 'taskSendMailAssignee',
  TASK_SEND_MAIL_MENTION = 'taskSendMailMention',
  TASK_SEND_MAIL_CHANGE_STATUS = 'taskSendMailChangeStatus',
  TASK_REMINDER = 'taskSendMailReminder',
  SET_ALL = 'setAll',
  TASK_NOTIFY_UPDATE = 'taskNotifyUpdate',
  TASK_NOTIFY_MENTION = 'taskNotifyMention',
  TASK_OVER_DUE_AFTER = 'taskOverDueDateAfter',
  RECEIVER_TASK_OVER_DUE_AFTER = 'receiveTaskOverDueAfter',
  TASK_NOTIFY_REMINDER = 'taskNotifyReminder',
  TASK_NOTIFY_CREATE = 'taskNotifyCreated'
}

export enum NotificationSettingType {
  NOTIFY = 'notify',
  EMAIL = 'email'
}

export enum ListenSSEChannel {
  ImportProcess = 'importProcess',
  Error = 'error',
  UnreadNotification = 'unreadNotifications'
}
