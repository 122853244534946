<div class="date-picker-wrapper">
  <input
    [formControl]="control"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [class]="'form-control text-' + align"
    #fdp="bsDatepicker"
    bsDatepicker
    [bsConfig]="bsConfig"
    [placement]="placement"
    [placeholder]="placeholder | translate"
    (bsValueChange)="onChangeValue($event)"
    (onHidden)="onHiddenDatePicker()"
    (onShown)="onShownDatePicker()"
  />

  <button class="btn btn-clear-date" *ngIf="control?.value"><span class="material-icons-outlined h-100 d-block" [title]="'Clear all' | translate"
    (click)="clearControlValue()">
    close
  </span></button>
</div>
