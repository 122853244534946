import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { DEFAULT_PAGE_SIZE, FIRST_PAGE, TABLE_CONFIG } from '../../_utils';
import * as queryString from 'query-string';

export interface List2Res<T = any> {
  content: T[];
  pageable: PageableRes;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class PageInfo {
  numberOfElements?: number;
  totalPages: number;
  totalElements: number;
  pageable: PageableRes;

  static mapFromList2Res(res: List2Res<any>) {
    return {
      pageable: res.pageable,
      totalElements: res.totalElements,
      totalPages: res.totalPages,
    };
  }
}

export interface PageableRes {
  sort: Sort;
  offset: number;
  pageSize: number;
  pageNumber: number;
  unpaged: boolean;
  paged: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export class SearchParams<T = any> {
  param?: T;
  pageable?: Pageable;

  constructor(obj?: any) {
    this.param = obj?.param || this.param;
    this.pageable = obj?.pageable || this.pageable;
  }

  getQuerystring() {
    return queryString.stringify({ ...this.param, ...this.pageable });
  }
}

export class Pageable {
  page?: number = FIRST_PAGE;
  size?: number = DEFAULT_PAGE_SIZE;
  sort?: string | string[] = '';

  constructor(obj?: Pageable) {
    this.page = obj?.page || FIRST_PAGE;
    this.size = obj?.size || DEFAULT_PAGE_SIZE;
    this.sort = obj?.sort || '';
  }
}

export class PageableModel implements Pageable {
  page?: number;
  size?: number;
  sort?: string | string[];
  keyword?: string;

  constructor(obj?: Pageable) {
    this.page = obj?.page || FIRST_PAGE;
    this.size = obj?.size || DEFAULT_PAGE_SIZE;
    this.sort = obj?.sort;
  }
}
