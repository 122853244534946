export const GitTypes = [
  {
    name: 'Github',
    icon: 'assets/icons/git/github.svg',
  },
  {
    name: 'Gitlab',
    icon: 'assets/icons/git/gitlab.svg',
  },
  {
    name: 'Bitbucket',
    icon: 'assets/icons/git/bitbucket.svg',
  }
];

export const priorityIcons = [
  'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/46a604cb207c4245469f43c228847e39/blocker.svg',
  'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/96ac43f6e33cae19dbfa724c62c40d31/critical.svg',
  'https://dev-upload-xcorp.s3.ap-southeast-1.amazonaws.com/resources/9c8c62d4269398f00bedc56d13c3251d/High%202.svg',
  'https://dev-upload-xcorp.s3.ap-southeast-1.amazonaws.com/resources/b2a3faeddac9ab5bff94893a7ac8610e/Very%20High%202.svg',
  'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/d71c9492984f557e6ea786758987ee01/very_high.svg',
  'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/69832450fdbf90b597d108b09bf43149/high.svg',
  'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/8167f8b158e70adf4062bcfb2fd8f9a0/medium.svg',
  'https://dev-upload-xcorp.s3.ap-southeast-1.amazonaws.com/resources/f50992cd64b39544e95122c65a6cdf21/Major.svg',
  'https://dev-upload-xcorp.s3.ap-southeast-1.amazonaws.com/resources/77f1da8e11ec4df5bd1dc8888f729bb0/Medium%202.svg',
  'https://dev-upload-xcorp.s3.ap-southeast-1.amazonaws.com/resources/b4b5ce15f75bd7a0a02ba1dc61791dc9/Minor.svg',
  'https://dev-upload-xcorp.s3.ap-southeast-1.amazonaws.com/resources/ee79f465f399fcb3c012709309686a84/Very%20Low%202.svg',
  'https://dev-upload-xcorp.s3.ap-southeast-1.amazonaws.com/resources/7b3532d0d6d3bc3f78f5e5142d703812/Low%202.svg',
  'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/db9e74db39209a2569b25922cd6e4a77/very_low.svg',
  'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/96ac43f6e33cae19dbfa724c62c40d31/low.svg',
  'https://dev-upload-xcorp.s3.ap-southeast-1.amazonaws.com/resources/9205168d0c3f991d2bfbe2f046f52e18/Priority%20Default.svg'
];
