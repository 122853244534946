import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { AbstractComponent, Attachment, UnsavedCache } from 'src/app/shared';
import { AwsImg } from 'src/app/shared/_components/editor-content-editable/editor-html-view/editor-html-view.component';
import { EditorFormPayload } from 'src/app/shared/_components/editor-form/editor-form.component';
import { EditorUserMention } from 'src/app/shared/_components/editor/editor.component';

@Component({
  selector: '',
  template: ''
})
export class BaseTaskDescriptionComponent extends AbstractComponent implements OnInit {
  @Input() canEdit = true;
  @Input() description: string;
  @Input() getUserPromise: (searchTerm: string) => Promise<EditorUserMention[]>;
  @Input() upload$: (file: File) => Observable<Attachment>;
  @Output() save: EventEmitter< { payload: EditorFormPayload, callback: () => void }> = new EventEmitter();
  @Output() cancel = new EventEmitter<void>();

  get descriptionText() : string {
    return this.translateService.instant('Add a description...');
  }

  showEditor = false;
  placeholder = `<span class="placeholder">${this.descriptionText}</span>`;
  parsedHtml = '';
  awsImgs: AwsImg[] = [];
  unsavedCache!: UnsavedCache;
  currentUserKey!: string;
  currentTaskKey!: string;
  localDescription: string = null;

  constructor(
    public translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {}

  onCancel(emit: boolean = true) {
    this.showEditor = false;
    const payload: EditorFormPayload = {
      attachments: null,
      htmlString: null,
      selectedUserMention: null
    };
    this.parsedHtml = null;
    this.onChange(payload);
    if (emit) {
      this.cancel.emit();
    }
  }

  onSave(payload: EditorFormPayload) {
    let htmlString = payload.htmlString;
    this.awsImgs.forEach(e => {
      htmlString = htmlString.replace(e.objectUrl, e.url);
    });

    this.save.emit({
      payload: {
        htmlString,
        attachments: payload.attachments.map(e => {
          return {
            name: e.name,
            url: e.url,
            type: 'Description',
          };
        }),
        selectedUserMention: payload.selectedUserMention,
      },
      callback: () => {
        this.parsedHtml = '';
        this.awsImgs = [];
        this.showEditor = false;
      }
    });
    this.onCancel(false);
  }

  onClickComment(event: any, html: HTMLElement) {
    if (event?.target?.nodeName === 'IMG' || !this.canEdit) {
      return;
    } else {
      this.showEditor = true;
    }
    this.checkUnsavedDescription();
  }

  onContentParsed(html: string, awsImgs: AwsImg[]) {
    this.parsedHtml = html;
    this.awsImgs = awsImgs;
  }

  onChange(payload: EditorFormPayload) { }

  checkUnsavedDescription() {
    if (!isEmpty(this.unsavedCache)) {
      this.localDescription = this.unsavedCache?.[this.currentUserKey]?.[this.currentTaskKey]?.description;
      if (this.localDescription && this.description !== this.localDescription) {
        this.parsedHtml = this.localDescription;
      }
    }
  }
}
