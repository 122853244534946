import { NgModule } from '@angular/core';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
@NgModule({
  imports: [
    NzNotificationModule,
    NzMessageModule,
    NzStepsModule,
    NzToolTipModule,
    NzSwitchModule,
    NzTableModule
  ],
  exports: [
    NzNotificationModule,
    NzMessageModule,
    NzStepsModule,
    NzToolTipModule,
    NzSwitchModule,
    NzTableModule
  ],
  declarations: [],
  providers: []
})
export class NgAntDesignModule {}
