<app-search-overlay *ngIf="config"
  [config]="config"
  (open)="onOpen()"
  [canEdit]="canEdit"
  (change)="onChange($event); change.emit($event)">
</app-search-overlay>

<ng-template #selectedTemplate>
  <app-avatar *ngFor="let user of config.selecteds"
    [url]="user.avatar" [name]="user.fullName">
  </app-avatar>

  <app-avatar *ngIf="config.selecteds.length == 0"></app-avatar>
</ng-template>

<ng-template #optionTemplate let-item="item">
  <app-avatar [url]="item.avatar" [name]="item.fullName"></app-avatar>
  <span class="option-label">{{item.fullName}}</span>
</ng-template>
