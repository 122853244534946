import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Directive,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppInjector } from 'src/app/app.module';
import { ToastService } from 'src/app/core';
import { PermissionCheckService } from 'src/app/site-management/role-permission/_services/permission-check.service';
import { DATE_TIME_UI, permissionCode, routerObject } from '../../_utils';
import { PermissionChecker } from '.';

export interface AppInit {
  init(): void;
}
export interface AppChanges {
  changes(changes: SimpleChanges): void;
}

export interface AppAfterViewInit {
  afterViewInit(): void;
}

export interface AppDestroy {
  destroy(): void;
}

@Directive()
export class AbstractComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  destroyed$ = new Subject<void>();
  subscriptions: Subscription[] = [];
  isWebLayout = true;
  toast: ToastService = AppInjector.get(ToastService);
  permissionDataService: PermissionCheckService = AppInjector.get(
    PermissionCheckService
  );
  breakpointObserver: BreakpointObserver = AppInjector.get(BreakpointObserver);

  routerObject = routerObject;

  // Permission
  visibility: { [key: string]: boolean } = {};
  permissionCode = permissionCode;
  actions: string[] = [];
  permissionChecker: PermissionChecker;
  // End Permssion

  constructor() {}

  init() {}
  changes(changes: SimpleChanges) {}
  afterViewInit() {}
  destroy() {}
  observeCheckWindowSize() {}

  ngOnInit(): void {
    this.visibility = this.permissionDataService.getVisibility(this.actions);
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changes(changes);
  }

  ngAfterViewInit(): void {
    this.afterViewInit();
  }

  ngOnDestroy(): void {
    this.destroy();

    this.subscriptions.forEach((e) => {
      e?.unsubscribe();
    });

    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  errorFn(error: string) {
    const httpError = JSON.parse(error);
    this.toast.error(httpError?.message);
  }

  getDateUI(date: string) {
    return date ? moment(date).format(DATE_TIME_UI) : '';
  }

  stopEvent(event) {
    event?.preventDefault();
    event?.stopPropagation();
  }

  checkWindowSize() {
    this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result.matches) {
          this.isWebLayout = true;
        } else {
          this.isWebLayout = false;
        }
        this.observeCheckWindowSize();
      });
  }
}
