export interface CompanyStructure {
  id: number;
  name: string;
  orderLevel?: string;
  color?: string;
  childView?: string;
  jobTitle: {
    id: number;
    title: string;
  };
  viceCompanyStructures?: CompanyStructure[];
  childCompanyStructures?: CompanyStructure[];
  sameLevelCompanyStructure?: CompanyStructure[];
}

export interface ViewChild {
  label: string;
  icon: string;
}

export class CompanyStructureRequest {
  currentId?: number;
  name?: string;
  color?: string;
  jobTitleId?: number;
  childView?: string;
  viceJobTitleIds?: number[];

  constructor(data: any) {
    this.currentId = data.currentId;
    this.name = data.name;
    this.color = data.color;
    this.jobTitleId = data.jobTitleId;
    this.childView = data.childView;
    this.viceJobTitleIds = data.viceJobTitleIds;
  }

  toEditPayload() {
    const request = { changes: [] };
    if (this.name) {
      request.changes.push({ type: 'Name', value: this.name });
    }
    if (this.color) {
      request.changes.push({ type: 'Color', value: this.color });
    }
    if (this.jobTitleId) {
      request.changes.push({ type: 'JobTitleId', value: `${this.jobTitleId}` });
    }
    if (this.viceJobTitleIds) {
      request.changes.push({ type: 'ViceJobTitleIds', value: `[${this.viceJobTitleIds.toString()}]` });
    }
    if (this.childView) {
      request.changes.push({ type: 'ChildView', value: this.childView });
    }
    return request;
  }
}
