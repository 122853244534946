import { SelectOption, Task, TaskAttachment, TaskAttachmentType } from '..';
import { DatePeriod, OkrMyFollowUp, OkrRiskLevel, OkrObjectiveType, OkrResultType } from '../_utils';
import { FilterBy, FilterItem } from './app/filter.model';
import { AttachmentType, BaseAttachment } from './attachment.model';
import { Group } from './group.model';
import { Metrics } from './metrics.model';
import { OkrKeyResult, OkrKeyResultItem } from './okr-key-result.model';
import { OkrTimeline } from './okr-timeline.model';
import { Team } from './team.model';
import { User } from './user.model';

export class OkrBase {
  id: number;
  start: number;
  current: number;
  expected: number;
  weight: number;
  objectiveType: OkrObjectiveType;
  resultType: OkrResultType;
  progress: number;
  totalValue: number;
  percentValue: number;
  justify: number;
}

export class Okr extends OkrBase {
  id: number;
  objectiveType: OkrObjectiveType;
  name: string;
  key: string;
  description: string;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  progress: number;
  timeline: OkrTimeline;
  assignee: User;
  stakeHolder: User;
  createBy: User;
  okrTeams: Team[];
  okrGroups: Group[];
  keyResults: OkrKeyResult[];
  tasks: OkrTask[];
  children: Okr[];
  parent: Okr;
  path: Okr[];
  krWeight: number;
  metric: Metrics;
  riskLevel: OkrRiskLevel;
  hasChild: boolean;
  percentValue: number;
  totalValue: number;
  attachments: OkrAttachment[];
}

export enum OkrSearchType {
  kr = 'keyresult',
  okr = 'okr',
  item = 'item', // Releated to KR
  task = 'task', // Releated to OKR
}

export interface OkrSearchResponse {
  id: number;
  taskId: number;
  typeIcon: string;
  type: OkrSearchType;
  objectiveType: OkrObjectiveType;
  name: string;
  key: string;
  keyResult: OkrKeyResult;
  description: string;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  justify: number;
  krWeight: number;
  progress: number;
  path: Okr[];
  timeline: OkrTimeline;
  assignee: User;
  stakeHolder: User;
  createBy: User;
  okrTeams: Team[];
  okrGroups: Group[];
  riskLevel: OkrRiskLevel;
}

export interface OkrAssignee {
  id: number;
  fullName: string;
  avatar: string;
}

export interface OkrTask {
  id: number;
  taskId: number;
  assignee: User;
  current: number;
  description: string;
  key: string;
  name: string;
  weight: number;
  typeIcon: string;
  metricValue: number;
  metric: Metrics;
}

export interface OkrCreateRequest {
  objectiveType: OkrObjectiveType;
  name: string;
  description: string;
  parentId: number;
  assigneeId: number;
  stakeholderId: number;
  teamIds: number[];
  groupIds: number[];
  timelineId: number;
  dueDate: string;
  resultType: string;
  start: number;
  current: number;
  expected: number;
  weight: number;
  krWeight: number;
  keyResults: OkrChildCreateRequest[];
  childrens: OkrChildCreateRequest[];
  tasks: OkrChildCreateRequest[];
  metricId: number;
  justify: number;
}

export interface OkrUpdateRequest {
  objectiveType: string;
  name: string;
  description: string;
  parentId: number;
  assigneeId: number;
  stakeholderId: number;
  teamIds: number[];
  groupIds: number[];
  timelineId: number;
  dueDate: string;
  resultType: string;
  start: number;
  current: number;
  expected: number;
  weight: number;
  krWeight: number;
  metricId: number;
  justify: number;
}

export interface OkrLinkTaskRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
  task?: Task;
}

export interface OkrChildRequest {
  id: number;
  assigneeId: number;
  weight: number;
  current: number;
}

export interface OkrKeyResultRequest {
  id: number;
  assigneeId: number;
  weight: number;
  current: number;
}

export interface OkrChildKrCreateRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
}

export interface OkrChildKrUpdateRequest {
  current: number;
  weight: number;
  assigneeId: number;
}

export interface OkrChildUpdateRequest {
  assigneeId: number;
  current: number;
  weight: number;
}

export interface OkrChildCreateRequest {
  id?: number;
  taskId?: number;
  current?: number;
  weight?: number;
  assigneeId?: number;
}

export interface OkrChildTaskUpdateRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
}

export interface OkrSearchRequest {
  startDate?: string;
  endDate?: string;
  timelineId?: number;
  searchText?: string;
  objectiveType?: OkrObjectiveType;
  userIds?: number[];
  groupIds?: number[];
  teamIds?: number[];
  userStatus?: string;
}

export interface OkrTreeItem {
  type: OkrSearchType;
  row: (Okr | OkrKeyResult | OkrKeyResultItem | Task);
}


// export enum OkrTreeRowAction {
//   addOkr = 'addOkr',
//   addKr = 'addKr',
//   linkTask = 'linkTask',
//   unlinkTask = 'unlinkTask',
//   createTask = 'createTask',
//   edit = 'edit',
//   delete = 'delete',
//   deleteKr = 'deleteKr',
//   unlink = 'unlink',
// }

// export interface OkrTreeRowActionData {
//   type: OkrSearchType;
//   row: (Okr| OkrKeyResult);
//   action: OkrTreeRowAction;
// }

export class OkrFilterBy {
  followUp: FilterItem<SelectOption<OkrMyFollowUp>>;
  objectiveType: FilterItem<SelectOption<OkrObjectiveType>>;
  assignee: FilterItem<User>;
  group: FilterItem<Group>;
  team: FilterItem<Team>;
  dueDate: FilterItem<SelectOption<DatePeriod>>;
  userStatus?: FilterItem<{ name: string, value: any }>;
  // okrStatus: FilterItem<SelectOption<OkrRiskLevel>>;
}

export interface RiskLevelData {
  label: string;
  colorCode: string;
  icon: string;
}

export interface OkrDataChange {
  type: OkrSearchType;
  item: (Okr | OkrKeyResult);
}

export class OkrAttachment extends BaseAttachment { }

export interface OkrUpsertFile {
  file?: File;
  type?: AttachmentType;
  id?: number;
  typeOfObject?: string;
  previewUrl?: string;
}

export interface OkrPatch {
  value: string | Date | number;
}



