import { NgModule } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { EntityDataModule } from '@ngrx/data';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { reducers } from './store/app';
import { EntityStoreModule } from './store/entity/entity-store.module';
import { entityConfig } from './store/entity/entity-metadata';
import { HammerProvider } from './config/hammer.config';
import { TranslateConfig } from './config/translate.config';
import {
  HttpErrorInterceptorProvider,
  TokenInterceptorProvider
} from './config/interceptors.config';
import {
  DefaultDataProvider,
  EntityCollectionReducerProvider,
  ResultHandlerProvider
} from './config/ngrx-data.config';
import { StoreDevConfig } from './config/ngrx-store-dev.config';
import { AuthEffects } from './store/auth';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ServiceWorkerConfig } from './config/service-worker.config';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyConfig } from './config/formly.config';


@NgModule({
  imports: [
    HammerModule,
    EntityStoreModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument(StoreDevConfig),
    EffectsModule.forRoot([AuthEffects]),
    EntityDataModule.forRoot(entityConfig),
    TranslateModule.forRoot(TranslateConfig),
    ServiceWorkerModule.register('ngsw-worker.js', ServiceWorkerConfig),
    FormlyModule.forRoot(FormlyConfig),
  ],
  providers: [
    HammerProvider,
    TokenInterceptorProvider,
    HttpErrorInterceptorProvider,
    DefaultDataProvider,
    ResultHandlerProvider,
    EntityCollectionReducerProvider
  ],
  declarations: [],
  exports: [HammerModule]
})
export class CoreModule {}
