<div class="cell-due-date" [title]="taskDueDateString.dueDateDescription">
  <span class="d-block pointer" stopEvent (click)="canEdit && dp.show()" [ngClass]="{'fw-5': dueDate}" [innerHTML]="taskDueDateString.dueDateString"></span>

  <!-- <button class="btn btn-icon btn-light btn-due-date" [ngClass]="{'d-flex': dp.isOpen}" (click)="dp.show()" *ngIf="!dueDate"  [title]="'Set due date'">
    <span class="material-icons-outlined">
      edit
    </span>
  </button> -->
  <input class="due-date-picker"
    [(ngModel)]="selectedDate"
    #dp="bsDatepicker"
    bsDatepicker
    [bsConfig]="bsConfig"
    placement="bottom right"
    (bsValueChange)="onDueDateChange($event)">
</div>
