import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class XcorpAppGuardService  {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if ([environment.mainDomain, 'localhost:4200'].includes(window.location.host)) {
      return true;
    }
    const redirectUri = `${environment.clientUrl + state.url}`;
    window.open(redirectUri, '_self');
    return false;
  }
}
