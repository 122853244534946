import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UtilService } from 'src/app/core';
import { routerObject, User } from 'src/app/shared';
import { SiteManagementState } from '../../_store/site-management';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NavbarService } from './_services/navbar.service';
import { AuthActions, AuthState } from 'src/app/core/store/auth';
import { Logger } from 'src/app/core/models/logger';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
  @Input() isWebLayout = false;
  @Input() user: User;
  @Output() openSearch = new EventEmitter<any>();

  templateRef: TemplateRef<any>;

  constructor(
    public router: Router,
    private authStore: Store<AuthState>,
    private navbarService: NavbarService,
    private siteManagementStore: Store<SiteManagementState>,
    private modal: BsModalService
  ) {
    this.navbarService.templateRef$.subscribe((data) => {
      this.templateRef = data;
    });
  }

  ngOnInit(): void { }

  onClickLogo() {
    if (window.location.pathname === routerObject.leaveDashboard.fullPath) {
      this.router.navigate(['/w']);
    } else {
      this.router.navigate(['/']);
    }
  }

  signOut() {
    this.authStore.dispatch(AuthActions.signOut());
  }

  getName(fullName: string) {
    return UtilService.getAbbreviationName(fullName);
  }

  onChangePassword() {
    this.modal.show(ChangePasswordComponent)
  }

  onSearch() {
    this.openSearch.emit();
  }
}
