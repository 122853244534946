import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { DATE_UI_FORMAT, DatePickerPlacement, DefaultPickerPlaceholder } from '../../_utils';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() align: 'left'  | 'center' | 'right' = 'left';
  @Input() control: UntypedFormControl;
  @Input() bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: DATE_UI_FORMAT,
    customTodayClass: 'bs-datepicker-today'
  };
  @Input() value: string | number = null;
  @Input() showDp = false;
  @Input() placement = DatePickerPlacement.auto;
  @Input() placeholder = DefaultPickerPlaceholder;
  @Output() dpValueChange = new EventEmitter<string>();
  @Output() onHideDp = new EventEmitter<string>();
  @ViewChild('fdp') fdp: BsDatepickerDirective;

  constructor() {}

  ngOnInit(): void {
    if (!this.control) {
      this.control = new UntypedFormControl(this.value);
    }

    if (this.showDp) {
      setTimeout(() => {
        this.fdp?.show();
      }, 100);
    }
  }

  onShownDatePicker() {}

  onHiddenDatePicker() {
    this.onHideDp.emit();
  }

  onChangeValue(date: string) {
    this.dpValueChange.emit(date);
  }

  clearControlValue() {
    this.control.reset();
  }
}
