import { ChartType } from 'angular-google-charts';

import { Moment } from 'moment';
import { Subject, Subscription } from 'rxjs';


export interface FirstDayAndLastDay {
  firstDay: Moment;
  lastDay: Moment;
}

export interface SelectOption<T = any> {
  name: string;
  value: T;
}

export interface CustomChart {
  type: ChartType;
  columns: any[];
  data: any[];
  options: any;
}

export interface FilterSelectOptions<T, U = string> {
  selecteds?: T[];
  items?: T[];
  typeahead?: Subject<U>;
  subscription?: Subscription;
}

export interface CurrencyUnit {
  unit: string[];
}
