import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { AppActions } from '../store/app';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      this.store.dispatch(AppActions.loading({ text: "You've successfully logged in.", visible: false, level: "info" }));
      return true;
    }

    this.authService.setLastUrl(state.url);

    this.router.navigate(['/w']);
    return false;
  }
}
