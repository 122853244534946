import { Leave } from 'src/app/site-management/dashboard/leave-calendar/leave-calendar.component';
import { Status } from '../_utils';
import { List2Res, Pageable } from './generic';
import { Shift } from './shift.model';
import { TypeOfLeave } from './type-of-leave.model';
import { User } from './user.model';

export interface ShiftLeave {
  [key: string]: TypeOfLeave;
}

export interface LeaveItemResponse {
  userId: number;
  fullName: string;
  typeofLeave?: TypeOfLeave[];
  shiftLeaveResponses: ShiftLeaveResponse[];
  shiftLeaves?: ShiftLeaveResponse[];
}

export interface ShiftLeaveResponse {
  leaveType: TypeOfLeave;
  shift: Shift;
}

export interface UserTimeOff {
  typeOfLeave: TypeOfLeave;
  leaves: {
    fullName: string;
  }[];
}

export interface BalanceTime {
  typeOfLeave: TypeOfLeave;
  leaveDayUsed: number;
  leaveDayAvailable: number;
  leaveDayAllowed: number;
  userAnnualLeaveId?: number;
  effectiveYear?: number;
  isEditing?: boolean; // only for FE
}

export class UserBalanceTimeResponse extends User {
  balanceTimes: BalanceTime[];
}

export interface BalanceTimeSearchRequest {
  year: number;
}

export interface DashboardLeaveSearchRequest {
  date: string; // yyyy-MM-dd
  pageable?: Pageable;
}

export interface CalendarSearchRequest {
  startDate?: string; // yyyy-MM-dd
  endDate?: string; // yyyy-MM-dd
  userIds?: number[];
  teamIds?: number[];
  groupIds?: number[];
  userStatus: Status;
}

export interface CalendarMap {
  [key: string]: {
    user: User;
    leaves: Leave[];
    weekDayOffs: string[];
  };
}
