import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent {
  @Input() control: UntypedFormControl;
  @Input() defaultValue: string;

  clearControlValue() {
    this.control.reset();
  }
}
