import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BoardEpic, Priority, PriorityName, StatusCategory, TaskName, TaskStatus,
  TaskTypeEnum, WorkItemPriority, WorkItemType, pickerColors
} from 'src/app/shared';
import { UpsertComponent } from 'src/app/shared/_components/_base-component/upsert.component';
import { ProjectsService } from '../../_services';
import { takeUntil } from 'rxjs/operators';
import { TaskService } from 'src/app/site-management/tasks/_services/task.service';
import { selectWorkItem } from 'src/app/site-management/_store/task';
import { Store } from '@ngrx/store';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { isEmpty } from 'lodash';

enum FormCtrlName {
  name = 'name',
  colorCode = 'colorCode'
}
@Component({
  selector: 'app-epic-inline-task',
  templateUrl: './epic-inline-task.component.html',
  styleUrls: ['./epic-inline-task.component.scss']
})
export class EpicInlineTaskComponent extends UpsertComponent<BoardEpic> {
  @ViewChild('epicDropdown') epicDropdown!: BsDropdownDirective;
  @Input() projectId: number;

  itemStatuses: TaskStatus[];
  itemTypes: WorkItemType[];
  priorities: WorkItemPriority[];
  pickerColors = pickerColors;
  formCtrlName = FormCtrlName;

  constructor(
    protected fb: UntypedFormBuilder,
    private projectService: ProjectsService,
    private taskService: TaskService,
    private store: Store,
  ) {
    super(fb);
  }

  init() {
    super.init();
    this.loadData();
  }

  createForm() {
    this.form = this.fb?.group({
      [this.formCtrlName.name]: ['', Validators.required],
      [this.formCtrlName.colorCode]: [this.pickerColors[0], Validators.required]
    });
  }

  private loadData() {
    this.getTypes();
    this.getPriorities();
    this.getStatuses();
  }

  getTypes() {
    this.projectService.getWorkItemTypes(this.projectId)
    .pipe(takeUntil(this.destroyed$))
    .subscribe((data) => {
      this.itemTypes = data;
    }, (err: string) => {
      this.itemTypes = [];
    });
  }

  getPriorities() {
    this.projectService.getWorkItemPriorities(this.projectId)
    .pipe(takeUntil(this.destroyed$))
    .subscribe((data) => {
      this.priorities = data;
    }, (err: string) => {
      this.priorities = [];
    });
  }

  getStatuses() {
    this.projectService.getWorkItemStatuses(this.projectId)
    .pipe(takeUntil(this.destroyed$))
    .subscribe((data) => {
      this.itemStatuses = data;
    }, (err: string) => {
      this.itemStatuses = [];
    });
  }

  selectColor(color: string) {
    this.form.get(this.formCtrlName.colorCode).setValue(color, {emitter: false});
    this.closeDropdown();
  }

  getEpicType() {
    return this.itemTypes?.find(type => type.name === TaskTypeEnum.Epic);
  }

  add() {
    if (isEmpty(this.itemTypes) || isEmpty(this.priorities) || isEmpty(this.itemStatuses)) {
      this.toast.error('This project has not completed configuration.');
      return;
    }

    const formValue = this.form.getRawValue();
    const defaultStatus = this.itemStatuses?.find(t => t.statusCategory === StatusCategory.OPEN && t.defaultFlg || t.name === TaskName.ToDo);
    const defaultPriority = this.priorities?.find(p => p.code === Priority.MEDIUM || p.name === PriorityName.Medium);

    const payload: Task = {
      ...formValue,
      projectId: this.projectId,
      sprintId: null,
      epicId: null,
      typeId: this.getEpicType()?.id,
      statusId: defaultStatus?.id,
      priorityId: defaultPriority?.id,
      assigneeId: null,
    };

    this.createEpic(payload);
  }

  private createEpic(payload: Task) {
    this.taskService.add(payload as any).subscribe(
      (resEpic) => {
        this.toast.success('Created successfully!');
        this.store.dispatch(selectWorkItem({projectId: this.projectId , workItemType: this.getEpicType()}));
        this.objectResponse.emit(resEpic as any);
      }, (err: string) => {
        this.errorFn(err);
      });
  }

  closeDropdown() {
    this.epicDropdown.isOpen = false;
  }
}
