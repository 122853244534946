<div class="dropdown-control" dropdown #dropdown="bs-dropdown" container="body" [insideClick]="true"
  placement="bottom right" (click)="onOpenSearch()">

  <div class="pointer" dropdownToggle>
    <ng-container *ngIf="config?.selectedTemplate">
      <ng-container *ngTemplateOutlet="config.selectedTemplate; context: {selecteds : config.selecteds}"></ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="canEdit">
  <div class="dropdown-menu custom-search-dropdown-menu dropdown-menu-right" *dropdownMenu>
    <div class="header" *ngIf="config.title || config.searchable">
      <div class="title" *ngIf="config.title">{{config.title}}</div>

      <app-search-input (keywordChange)="config.searchTypeahead$.next($event)"
        *ngIf="config.searchable"></app-search-input>
    </div>
    <div class="option-list">
      <ng-container *ngFor="let item of config.items">
        <div class="custom-option" [ngClass]="{'selected': isSelected(item)}"
          (click)="dropdown.isOpen = false; toggleItem(item)">
          <ng-container *ngIf="config.optionTemplate">
            <ng-container *ngTemplateOutlet="config.optionTemplate; context: {item : item}"></ng-container>
          </ng-container>
        </div>
      </ng-container>

      <div class="text-center" *ngIf="!config.loading && config.items.length===0"><app-i18n
          [key]="'No options'"></app-i18n></div>

      <div class="footer" *ngIf="config.footerTemplate" (click)="dropdown.isOpen = false">
        <ng-container *ngTemplateOutlet="config.footerTemplate"></ng-container>
      </div>
    </div>
  </div>
  </ng-container>
</div>
