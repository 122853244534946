import { EventEmitter, Injectable } from '@angular/core';
import { Sprint, Task } from 'src/app/shared';

export const CDK_BACKLOG_KEY = 'backlog';

@Injectable({
  providedIn: 'root'
})
export class BacklogDataService {

  taskChange$ = new EventEmitter<{
    previousValue: Task,
    currentValue: Task,
  }>();
  // taskChange$ = this.taskChange.asObservable().pipe(filter(e => e !== null));

  cdkSelectFrom = ''; // key selected from sprint or backlog
  cdkLastSelectedIndex: number;
  cdkSelectedTasks: Task[] = [];
  cdkBacklogKey = CDK_BACKLOG_KEY;
  constructor() { }

  getCdkContainerKey(sprint?: Sprint) {
    return sprint ? `sprint-id-${sprint.id}` : CDK_BACKLOG_KEY;
  }

  emitTaskChange(changes: {previousValue: Task, currentValue: Task}) {
    this.taskChange$.next(changes);
  }

  resetCdk() {
    this.cdkSelectFrom = '';
    this.cdkLastSelectedIndex = null;
    this.cdkSelectedTasks = [];
  }
}
