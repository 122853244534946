import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { LicenseCode } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class LicenseDataService {
  constructor(
    private authService: AuthService,
  ) { }

  canActive(licenseCode: LicenseCode) {
    return this.authService.loggedUser?.company.license[licenseCode || ''];
  }

  isKanbanLicense() {
    return this.authService.loggedUser?.company.license?.allowProjectType === 'Kanban';
  }
}
