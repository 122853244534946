import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChartType, GoogleChartComponent } from 'angular-google-charts';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subject, Subscription } from 'rxjs';
import { AbstractComponent, CustomChart, DATE_FORMAT, DATE_UI_FORMAT, FirstDayAndLastDay, PermissionChecker, reportStatus, UserReport, UserReportSearchRequest } from 'src/app/shared';
import { ToastService, UtilService } from 'src/app/core';
import { UserService } from 'src/app/site-management/user/_services/user.service';
import { UntypedFormControl } from '@angular/forms';

enum FilterType {
  lastYear,
  thisYear,
  last6Months,
  last3Months,
  thisMonth,
  custom,
}

@Component({
  selector: 'app-user-report-history',
  templateUrl: './user-report-history.component.html',
  styleUrls: ['./user-report-history.component.scss']
})
export class UserReportHistoryComponent extends AbstractComponent {
  @ViewChild('googleChart ', { static: false }) googleChart: GoogleChartComponent;

  @Input() userId: number;
  @Input() isMyProfile: boolean;
  chart: CustomChart;
  chartType = {
    lineChart: ChartType.LineChart,
    pieChart: ChartType.PieChart,
  };
  bsConfig: Partial<BsDatepickerConfig> = { dateInputFormat: DATE_UI_FORMAT };
  userReportInfo: UserReport;
  searchRequest: UserReportSearchRequest = {
    userId: null,
    fromDate: null,
    toDate: null,
    preFromDate: null,
    preToDate: null,
  };

  filters = [
    { label: 'This month', value: FilterType.thisMonth },
    { label: 'Last 3 months', value: FilterType.last3Months },
    { label: 'Last 6 months', value: FilterType.last6Months },
    { label: 'This year', value: FilterType.thisYear },
    { label: 'Last year', value: FilterType.lastYear },
    { label: 'Custom', value: FilterType.custom },
  ];

  filterType = FilterType;
  selectedFilter: FilterType = FilterType.thisYear;
  isNumdata = true;
  statusData = [];
  customData = null;
  searchSubscription: Subscription;
  destroyed$ = new Subject<void>();

  constructor(
    protected userService: UserService,
  ) {
    super();
  }

  init(): void {
    this.searchRequest.userId = this.userId;
    this.search();
  }

  destroy() {
    this.destroyed$.next();
    this.destroyed$.complete();

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  search() {
    this.searchSubscription?.unsubscribe();
    this.searchSubscription = this.userService.getReport(this.getSearchModel()).subscribe(
      (data) => {
        this.userReportInfo = data;
        this.buildChart();
      },
      (error: string) => this.errorFn(error)
    );
  }

  getSearchModel() {
    return {
      userId: this.searchRequest.userId,
      fromDate: this.getFirstDayAndLastDay().current.firstDay.format(DATE_FORMAT),
      toDate: this.getFirstDayAndLastDay().current.lastDay.format(DATE_FORMAT),
      preFromDate: this.getFirstDayAndLastDay().prev.firstDay.format(DATE_FORMAT),
      preToDate: this.getFirstDayAndLastDay().prev.lastDay.format(DATE_FORMAT),
    }
  }

  changeFilter() {
    if (this.selectedFilter !== this.filterType.custom) {
      this.search();
    }
  }

  updateSearchDateParams(date: string, isFrom = true) {
    if (!date) {
      return;
    }

    if (isFrom) {
      this.searchRequest.fromDate = date;
    } else {
      this.searchRequest.toDate = date;
    }

    if (this.searchRequest.fromDate && this.searchRequest.toDate) {
      this.search();
    }
  }

  getFirstDayAndLastDay(): { current: FirstDayAndLastDay, prev: FirstDayAndLastDay } {

    if (this.selectedFilter === FilterType.thisMonth) {
      return {
        current: UtilService.getFirstDayAndLastDayOfMonth(new Date(), 0),
        prev: UtilService.getFirstDayAndLastDayOfMonth(new Date(), -1),
      };
    }

    if (this.selectedFilter === FilterType.last3Months) {
      return {
        current: UtilService.getFirstDayAndLastDayOfLastXMonth(new Date(), 3, 0),
        prev: UtilService.getFirstDayAndLastDayOfLastXMonth(new Date(), 3, -1),
      };
    }

    if (this.selectedFilter === FilterType.last6Months) {
      return {
        current: UtilService.getFirstDayAndLastDayOfLastXMonth(new Date(), 6, 0),
        prev: UtilService.getFirstDayAndLastDayOfLastXMonth(new Date(), 6, -1),
      };
    }

    if (this.selectedFilter === FilterType.thisYear) {
      return {
        current: UtilService.getFirstDayAndLastDayOfYear(new Date(), 0),
        prev: UtilService.getFirstDayAndLastDayOfYear(new Date(), -1),
      };
    }

    if (this.selectedFilter === FilterType.lastYear) {
      return {
        current: UtilService.getFirstDayAndLastDayOfYear(new Date(), -1),
        prev: UtilService.getFirstDayAndLastDayOfYear(new Date(), -2),
      };
    }

    if (this.selectedFilter === FilterType.custom) {
      return {
        current: {
          firstDay: moment(this.searchRequest.fromDate),
          lastDay: moment(this.searchRequest.toDate)
        },
        prev: UtilService.getFirstDayAndLastDayBeforeOf(this.searchRequest.fromDate as any, this.searchRequest.toDate as any),
      };
    }
  }

  switchLineChart(isNumData: boolean = false) {
    if (this.isNumdata !== isNumData) {
      this.isNumdata = isNumData;
      this.configLineChart();
    }
  }

  buildChart() {
    this.caculateStatusData();

    if (this.selectedFilter === this.filterType.thisMonth) {
      this.configPieChart();
    } else {
      this.configLineChart();
    }
  }

  caculateStatusData() {
    this.statusData = [
      {
        label: reportStatus.completed.text,
        color: reportStatus.completed.color,
        ...this.getPercentChanges(
          this.userReportInfo.currentReport?.reportOnTime,
          this.userReportInfo.previousReport?.reportOnTime,
          this.userReportInfo.currentReport?.reportTotal,
          this.userReportInfo.previousReport?.reportTotal)
      },
      {
        label: reportStatus.early.text,
        color: reportStatus.early.color,
        ...this.getPercentChanges(
          this.userReportInfo.currentReport?.reportEarly,
          this.userReportInfo.previousReport?.reportEarly,
          this.userReportInfo.currentReport?.reportTotal,
          this.userReportInfo.previousReport?.reportTotal)
      },
      {
        label: reportStatus.lately.text,
        color: reportStatus.lately.color,
        ...this.getPercentChanges(
          this.userReportInfo.currentReport?.reportLately,
          this.userReportInfo.previousReport?.reportLately,
          this.userReportInfo.currentReport?.reportTotal,
          this.userReportInfo.previousReport?.reportTotal)
      },
      {
        label: reportStatus.missed.text,
        color: reportStatus.missed.color,
        ...this.getPercentChanges(
          this.userReportInfo.currentReport?.reportMissed,
          this.userReportInfo.previousReport?.reportMissed,
          this.userReportInfo.currentReport?.reportTotal,
          this.userReportInfo.previousReport?.reportTotal)
      }
    ];
  }

  getPercentChanges(curr: number = 0, prev: number = 0, currTotal: number = 0, prevTotal: number = 0) {
    const percent = Math.round(curr / currTotal * 100) || 0;
    const lastPercent = Math.round(prev / prevTotal * 100) || 0;
    const diff = percent - lastPercent;
    const isIncrease = diff >= 0 ? true : false;
    const percentText = `${percent}%`;
    const tooltip = `${isIncrease ? 'Increase' : 'Decrease'} ${percentText} compared to `
      + `${this.formatDateUI(this.searchRequest.preFromDate)} - ${this.formatDateUI(this.searchRequest.preToDate)}`;
    return {
      count: curr,
      isIncrease,
      percent: percentText,
      diff: `${Math.abs(diff)}%`,
      tooltip,
    };

  }

  getVF(count: number = 0, total: number = 0) {
    const value = (count / total) || 0;
    const formatted = `${Math.round(value * 100)}%`;
    return { v: value, f: formatted };
  }

  configLineChart() {
    let data = [];

    if (this.isNumdata) {
      data = this.userReportInfo.details?.map(e => {
        return [e.yearMonth, e.reportOnTime, e.reportLately, e.reportEarly, e.reportMissed];
      });
    } else {
      data = this.userReportInfo.details?.map(e => {
        return [
          e.yearMonth,
          this.getVF(e.reportOnTime, e.reportTotal),
          this.getVF(e.reportLately, e.reportTotal),
          this.getVF(e.reportEarly, e.reportTotal),
          this.getVF(e.reportMissed, e.reportTotal),
        ];
      });
    }

    this.chart = {
      type: this.chartType.lineChart,
      columns: ['Time', reportStatus.completed.text, reportStatus.lately.text, reportStatus.early.text, reportStatus.missed.text],
      data,
      options: {
        curveType: 'function',
        legend: { position: 'none' },
        colors: [
          reportStatus.completed.color, reportStatus.lately.color, reportStatus.early.color, reportStatus.missed.color
        ],
        width: '100%',
        height: '100%',
        vAxis: {
          baselineColor: '#DDE8F1', // trục x
          gridlines: {
            color: '#DDE8F1'
          },
          minorGridlines: {
            color: '#DDE8F1'
          },
          viewWindow: {
            min: 0,
          },
          format: this.isNumdata ? 'decimal' : 'percent',
        },
        hAxis: {
          // format: this.isNumdata ? 'decimal' : 'percent',
        },
        chartArea: {
          left: 50,
          top: 25,
          width: '100%',
          height: '90%'
        },
        // animation: {
        //   duration: 1000,
        //   easing: 'out',
        //   startup: true,
        // }
      }
    };
  }

  configPieChart() {
    this.chart = {
      type: this.chartType.pieChart,
      columns: ['Status', 'Percent'],
      data: [
        [reportStatus.completed.text, this.userReportInfo.currentReport?.reportOnTime || 0],
        [reportStatus.lately.text, this.userReportInfo.currentReport?.reportLately || 0],
        [reportStatus.early.text, this.userReportInfo.currentReport?.reportEarly || 0],
        [reportStatus.missed.text, this.userReportInfo.currentReport?.reportMissed || 0]
      ],
      options: {
        pieHole: 0.45,
        legend: { position: 'none' },
        colors: [reportStatus.completed.color, reportStatus.lately.color, reportStatus.early.color, reportStatus.missed.color],
        pieSliceTextStyle: {
          color: 'white',
        },
        chartArea: { width: '90%', height: '90%' },
        width: '100%',
      }
    };
    const reportTotal = this.userReportInfo.currentReport?.reportTotal;
    const firstReportDate = this.userReportInfo.currentReport?.firstReportDate;
    this.customData = {
      reportTotal,
      yearMonth: firstReportDate ? moment(firstReportDate, DATE_FORMAT).format('MMMM YYYY') : '',
    };
  }

  formatDateUI(date: string) {
    return UtilService.getDateUI(date);
  }


}
