<div class="wrapper web-sidebar" [ngClass]="currentSize" *ngIf="isWebLayout">
  <div class="menu-wrapper">
    <div class="menu flex-column">
      <ng-container *ngFor="let group of groupMenus; let i = index">
        <div class="group-menu" *ngIf="isVisibleGroup(group)" [ngStyle]="{'order': group?.rowIndex || 1}">
          <ng-container *ngFor="let item of group.childrens">
            <ng-container *ngTemplateOutlet="template; context: { item : item, level : initLevel }"></ng-container>
          </ng-container>
        </div>
      </ng-container>

      <div class="toggle-wrapper">
        <div style="position: relative;">
          <div class="btn-toggle" (click)="changeSize()" [tooltip]="currentSize | getToggleTooltip | translate" [placement]="'right'">
            <span>
              <mat-icon [svgIcon]="'angle-right'"></mat-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="toggle-wrapper">
    <div style="position: relative;">
      <div class="btn-toggle" (click)="changeSize()" [tooltip]="currentSize | getToggleTooltip | translate" [placement]="'right'">
        <span>
          <mat-icon [svgIcon]="'angle-right'"></mat-icon>
        </span>
      </div>

      <!-- TODO: sau này sẽ là tổng các noti number -->
      <div class="notification-number total-noti-number"
        *ngIf="waitingRequests > 0 && currentSize === sidebarSize.small">
        {{waitingRequests}}
      </div>
    </div>
  </div>
</div>

<div class="wrapper mobile-sidebar" *ngIf="!isWebLayout">
  <a role="button" *ngIf="!isBackIcon()" class="mobile-hamburger" [ngClass]="{'open': isShowMobileSidebar}" (click)="toggleMobileSidebar()">
    <span class="bar"></span>
    <span class="bar"></span>
    <span class="bar"></span>


    <!-- TODO: sau này sẽ là tổng các noti number -->
    <div class="notification-number total-noti-number" *ngIf="waitingRequests > 0">
      {{waitingRequests}}
    </div>
  </a>

  <a role="button" class="mobile-hamburger" *ngIf="isBackIcon()" (click)="backToPreviousPage()">
    <span class="material-icons-outlined"> arrow_back_ios </span>
  </a>

  <div role="button" class="menu-backdrop" [ngClass]="{'open': isShowMobileSidebar}" (click)="toggleMobileSidebar()">
  </div>
  <div class="menu-wrapper" [ngClass]="{'open': isShowMobileSidebar}">
    <div class="menu">
      <div class="group-menu">
        <div class="menu-item">
          <div class="user">
            <div class="user-avatar" role="button"
              (click)="onClickMenuItem({routerLink: '/organization/users/my-profile'})">
              <img [src]="user?.avatar" alt="" *ngIf="user?.avatar">
              <span *ngIf="!user?.avatar">{{user | getAbbreviationName }}</span>
            </div>
            <div class="fullname">{{user?.fullName}}</div>

            <button class="btn ml-auto" (click)="onSearch()" [tooltip]="('Press hotkey' | translate) + ': Ctrl + K'">
              <span class="search-icon"><i class="fa fa-search" aria-hidden="true"></i></span>
            </button>
          </div>
        </div>
      </div>

      <ng-container *ngFor="let group of groupMenus; let i = index">
        <div class="group-menu" *ngIf="isVisibleGroup(group)">
          <ng-container *ngFor="let item of group.childrens">
            <ng-container *ngTemplateOutlet="template; context: {item : item , level : initLevel }"></ng-container>
          </ng-container>
        </div>
      </ng-container>

      <div class="group-menu">
        <div class="menu-item">
          <div class="item" (click)="signOut()">
            <span class="icon">
              <mat-icon class="fa" [svgIcon]="'logout'"></mat-icon>
            </span>
            <span class="label"><app-i18n [key]="'Sign Out'"></app-i18n></span>
          </div>
        </div>
        <div class="my-2">
          <app-switch-language></app-switch-language>
        </div>
      </div>
    </div>

    <!-- <div class="version">Version 220608.1</div> -->
  </div>
</div>

<ng-template #template let-item="item" let-level="level" let-absolute="absolute">
  <div class="menu-item" *ngIf="item.hash | isVisibleHash : item">
    <a class="item-link item-container" hover-dropdown [routerLink]="item.routerLink">
      <div class="children-hover" *ngIf="isWebLayout && currentSize | isShowHoverChildren: item: level">
        <div class="children-container d-flex flex-column">
          <ng-container *ngFor="let child of item.childrens">
            <div [ngStyle]="{'order': child?.rowIndex || 1}">
              <ng-container *ngTemplateOutlet="template; context: {item : child, level: (level || initLevel) + 1, absolute: true }"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="item"
        [ngClass]="{
          'active': currentUrl | isActiveItem: item ,
          'open':  !item.isCollapsed,
          'child': level !== initLevel,
          'hover-item': absolute,
          'medium-item': currentSize === sidebarSize.medium
        }"
        [tooltip]="(item.text | sidebarItemTooltip: !absolute) | async"
        [placement]="'left'"
        [isDisabled]="currentSize !== sidebarSize.medium || !isWebLayout"
        stopEvent
        (click)="onClickMenuItem(item)">
        <ng-container *ngIf="item?.icon">
          <span class="icon" *ngIf="!item?.hash">
            <mat-icon class="fa icon-md" [svgIcon]="item.icon" *ngIf="!item.isMatIcon"></mat-icon>
          </span>
          <span class="icon"  *ngIf="item?.hash">
            <span class="fa material-icons-outlined icon-md" *ngIf="item.isMatIcon">
              {{item.icon}}
            </span>
            <mat-icon class="fa icon-md" [svgIcon]="item.icon" *ngIf="!item.isMatIcon"></mat-icon>
            <div class="notification-number request-number"
              *ngIf="waitingRequests > 0 && ((item.routerLink === routerObject.leave.fullPath) || item.routerLink === routerObject.leaveApproval.fullPath)">
              {{waitingRequests}}
            </div>
          </span>
        </ng-container>
        <span class="label"><app-i18n
            [key]="item.text | sidebarItemLabel | async"></app-i18n></span>
        <i class="fa collapse-icon" [ngClass]="item.isCollapsed ? 'fa-angle-down' : 'fa-angle-up'"
          *ngIf="item.childrens && !item.isTabMenu && level === initLevel"></i>
      </div>
    </a>

    <div class="collapse" [ngClass]="{'show': !item.isCollapsed}" *ngIf="item | isShowChildren: level">
      <div class="d-flex flex-column">
        <ng-container *ngFor="let child of item.childrens">
          <div [ngStyle]="{'order': child?.rowIndex || 1}">
            <ng-container *ngTemplateOutlet="template; context: {item : child, level: (level || initLevel) + 1 }"></ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
