<ng-template #breadcrumb>
  <div class="breadcumb">
    <app-breadcumb-item [activePath]="routerObject.taskMgmt.fullPath"></app-breadcumb-item>
    <app-project-switch #projectSwitch
      [currentProject]="currentProject"
      (projectChange)="onProjectChange($event)"></app-project-switch>
    <ng-container *ngIf="task && currentProject">
      <app-breadcumb-item *ngIf="task.type?.name === 'Epic'"
        [activePath]="routerObject.taskMgmtEpic.fullPath" [replaceKeyValue]="{':projectKey': currentProject?.key}"></app-breadcumb-item>

      <ng-container *ngIf="activeBoard && task.type?.name !== 'Epic'">
        <app-breadcumb-item *ngIf="task.sprint?.id === activeBoard.activeSprint?.id"
          [activePath]="isKanBan() ? routerObject.taskMgmtKanban.fullPath : routerObject.taskMgmtActiveSprint.fullPath" [replaceKeyValue]="{':projectKey': currentProject?.key}"></app-breadcumb-item>

        <app-breadcumb-item *ngIf="task.sprint?.id !== activeBoard.activeSprint?.id"
          [activePath]="routerObject.taskMgmtBacklog.fullPath" [isLastInBreadcumb]="true" [replaceKeyValue]="{':projectKey': currentProject?.key}"></app-breadcumb-item>
      </ng-container>
    </ng-container>
    <button class="btn btn-primary btn-create" (click)="onCreateTask()"><app-i18n [key]="'Create Task'"></app-i18n></button>
  </div>
</ng-template>

<div #taskForm [formGroup]="form" *ngIf="task">
  <div class="task-detail-wrapper" [ngClass]="{'custom-modal-content': isModal}">
    <div class="task-drag-drop-bg" [ngClass]="{'dragging': isDragging}"
      appDragAndDrop
      (onFileDropped)="onFileChanged($event); isDragging = false;"
      (onDragleave)="isDragging = false;">
    </div>
    <div class="ui-direction-row" *ngIf="uiDirection === 'row'">
      <div class="row task-detail-content">
        <div class="col-12 col-lg-7 left-content">
          <div class="header-row mb-3" *ngIf="isWebLayout">
            <ng-container *ngTemplateOutlet="taskKey"></ng-container>
            <ng-container *ngTemplateOutlet="taskTopBtn"></ng-container>
          </div>
          <ng-container *ngIf="!isWebLayout">
            <div class="header-row mobile-header" >
              <ng-container *ngTemplateOutlet="taskStatus"></ng-container>
              <div *ngIf="closeable" >
                <ng-container *ngTemplateOutlet="moreAction"></ng-container>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="taskKey"></ng-container>
            <ng-container *ngTemplateOutlet="taskTopBtn"></ng-container>
          </ng-container>


          <div class="left-body">
            <ng-container *ngTemplateOutlet="nameInput"></ng-container>

            <div class="custom-tabset" *ngIf="!isWebLayout">
              <tabset>
                <tab [heading]="'General' | translate">
                  <!-- <ng-container *ngTemplateOutlet="taskTopBtnMobile"></ng-container> -->
                  <ng-container *ngTemplateOutlet="leftBody"></ng-container>
                  <ng-container *ngTemplateOutlet="bottomContent"></ng-container>
                </tab>
                <tab [heading]="'Details' | translate">
                  <div class="mb-3"></div>
                  <ng-container *ngTemplateOutlet="rightBody"></ng-container>
                </tab>
              </tabset>
            </div>

            <div *ngIf="isWebLayout">
              <ng-container *ngTemplateOutlet="leftBody"></ng-container>
              <ng-container *ngTemplateOutlet="bottomContent"></ng-container>
            </div>
          </div>

        </div>
        <div class="col-12 col-lg-5 right-content" *ngIf="isWebLayout">
          <ng-container *ngIf="task.type?.name === TaskTypeEnum.Epic && isShowTaskDetail; else rightbody">
            <ng-container *ngTemplateOutlet="taskOfEpic"></ng-container>
          </ng-container>
          <ng-template #rightbody>
            <div class="header-row mb-3">
              <ng-container *ngTemplateOutlet="taskStatus"></ng-container>
              <ng-container *ngTemplateOutlet="moreAction"></ng-container>
            </div>

            <div class="body-row">
              <ng-container *ngTemplateOutlet="rightBody"></ng-container>
            </div>
          </ng-template>

        </div>
      </div>
    </div>

    <div class="ui-direction-column" *ngIf="uiDirection === 'column'">
      <div class="task-detail-content">
        <div class="position-relative">
          <div *ngIf="!canShowTabLayout(task.type?.name)">
            <div class="header-row">
              <div>
                <ng-container *ngTemplateOutlet="taskKey"></ng-container>
                <ng-container *ngTemplateOutlet="taskTopBtn"></ng-container>
              </div>
              <div class="d-flex align-items-start">
                <ng-container *ngTemplateOutlet="taskStatus"></ng-container>
                <ng-container *ngTemplateOutlet="moreAction"></ng-container>
              </div>
            </div>

            <div class="left-body mt-2">
              <ng-container *ngTemplateOutlet="nameInput"></ng-container>

              <ng-container *ngTemplateOutlet="leftBody"></ng-container>
            </div>

            <div class="task-form form-group">
              <div class="header">
                <span class="label"><app-i18n [key]="'details'"></app-i18n></span>

                <span class="material-icons icon">construction</span>
              </div>
              <div class="body">
                <ng-container *ngTemplateOutlet="rightBody"></ng-container>
              </div>
            </div>

            <ng-container *ngTemplateOutlet="bottomContent"></ng-container>
          </div>
          <div *ngIf="canShowTabLayout(task.type?.name)">
            <div class="header-row flex-column">
              <div class="d-flex justify-content-between">
                <ng-container *ngTemplateOutlet="taskKey"></ng-container>
                <div class="d-flex align-items-start">
                  <ng-container *ngTemplateOutlet="taskStatus"></ng-container>
                  <ng-container *ngTemplateOutlet="moreAction"></ng-container>
                </div>
              </div>
              <div class="mt-1">
                <ng-container *ngTemplateOutlet="taskTopBtn"></ng-container>
              </div>
              <div class="left-body mt-2">
                <ng-container *ngTemplateOutlet="nameInput"></ng-container>
              </div>
            </div>
            <div class="custom-tabset mt-4">
              <tabset #tabset>
                <tab heading="{{'General' | translate}}">
                  <ng-container *ngTemplateOutlet="leftBody"></ng-container>
                  <ng-container *ngTemplateOutlet="attachmentBlock"></ng-container>
                  <ng-container *ngTemplateOutlet="commentAndHistory"></ng-container>
                </tab>
                <tab heading="{{'Details' | translate}}">
                  <div class="task-form form-group">
                    <div class="header">
                      <span class="label"><app-i18n [key]="'details'"></app-i18n></span>
                      <span class="material-icons icon">construction</span>
                    </div>
                    <div class="body">
                      <ng-container *ngTemplateOutlet="rightBody"></ng-container>
                    </div>
                  </div>
                </tab>
                <tab heading="{{'Tasks' | translate}}">
                  <ng-container *ngTemplateOutlet="detailEpicTaskBlock"></ng-container>
                  <ng-container *ngTemplateOutlet="timesheetBlock"></ng-container>
                </tab>
              </tabset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #nameInput>
    <div class="d-flex align-items-center">
      <div class="flex-grow-1 task-name-container">
        <ng-container *ngTemplateOutlet="platformTemplate"></ng-container>
        <span class="content-editable task-name" contenteditable
          formControlName="name"
          (focusout)="nameFocusout()"
          (keydown.enter)="onEnterName($event, nameInput)">
        </span>
        <app-form-error [form]="form" [controlName]="'name'"></app-form-error>
      </div>
    </div>

  </ng-template>

  <ng-template #taskKey>
    <div class="d-flex align-items-center">
      <div class="task-key-detail epic" *ngIf="taskEpic">
        <img class="task-icon" [src]="taskEpic?.type?.icon || '../../../../assets/icons/tasks/epic.svg'">
        <a class="btn-link" (click)="openTask(taskEpic)">{{taskEpic?.key}}</a>
        <span class="mx-2">/</span>
      </div>
      <div class="task-key-detail parent" *ngIf="task?.parentTask">
        <img class="task-icon" [src]="task?.parentTask.type?.icon || '../../../../assets/icons/tasks/story.svg'">
        <a class="btn-link" (click)="openTask(task?.parentTask)">{{task?.parentTask?.key}}</a>
        <span class="mx-2">/</span>
      </div>
      <div class="task-key-detail task" (mouseover)="onTaskKeyMouseOver()" (mouseleave)="onTaskKeyMouseLeave()">
        <img class="task-icon" [src]="task?.type?.icon || '../../../../assets/icons/tasks/story.svg'">
        <span class="task-key label" *ngIf="!linkable">{{task?.key}}</span>
        <a class="btn-link" *ngIf="linkable" (click)="openTask(task)">{{task?.key}}</a>
        <span class="d-flex-center link-copy badge badge-label py-1 ml-1 pointer" *ngIf="showTaskLinkCopy" (click)="onCopyLinkToClipboard()">
          <span class="material-icons icon-copy-link mr-1">link</span>
          <span class="text-copy-link"><app-i18n [key]="'Copy link'"></app-i18n></span>
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template #taskTopBtn>
    <div class="icon-button-list mb-0">
      <button class="btn icon-btn" [tooltip]="'Bookmark' | translate" [placement]="'bottom'">
        <app-task-bookmark [task]="task" [isTaskDetail]="true"></app-task-bookmark>
      </button>
      <button class="btn icon-btn" [tooltip]="'Reminder' | translate" [placement]="'bottom'" (click)="addReminder()">
        <mat-icon [svgIcon]="'action-bar-reminder'"></mat-icon>
      </button>
      <button class="btn icon-btn" [tooltip]="'Add attachment' | translate" [placement]="'bottom'" (click)="addAttachment()">
        <mat-icon [svgIcon]="'action-bar-attachment'"></mat-icon>
      </button>
      <button class="btn icon-btn" [tooltip]="'Create Subtask' | translate" [placement]="'bottom'" (click)="addSubtask()"
        *ngIf="task && !hideCreateSubTask(task.type?.name)">
        <mat-icon [svgIcon]="'action-bar-subtask'"></mat-icon>
      </button>
      <button class="btn icon-btn" [tooltip]="'Link Issue' | translate" [placement]="'bottom'" (click)="addLink()"
        *ngIf="!hideLinkIssue(task.type?.name)">
        <mat-icon [svgIcon]="'action-bar-link'"></mat-icon>
      </button>
      <button class="btn icon-btn" [tooltip]="'Log Work' | translate" [placement]="'bottom'" (click)="logWork()">
        <mat-icon [svgIcon]="'action-bar-logwork'"></mat-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #leftBody>
    <div class="form-group mt-1">
      <label>
        <app-i18n [key]="'Description'"></app-i18n>
        <ng-container *ngIf="localDescription && localDescription !== task.description">
          <span>&nbsp;&middot;&nbsp;<app-i18n [key]="'Unsaved description'"></app-i18n></span>
        </ng-container>
      </label>
      <app-task-detail-description
        [task]="task"
        [description]="task.description"
        [getUserPromise]="getUserPromise"
        [upload$]="uploadFileDescription$"
        (save)="descriptionFocusout($event)"
        (cancel)="onCancelDescription()">
      </app-task-detail-description>
    </div>
  </ng-template>

  <ng-template #detailEpicTaskBlock>
    <div *ngIf="task.type?.name === TaskTypeEnum.Epic">
      <app-task-detail-epic-tasks #epicTasks [task]="task" [project]="currentProject"
        [header]="'Tasks in this epic' | translate"
        (formSubmit)="onNewTaskAddedToEpic($event)" (clickTask)="onClickTask($event)"></app-task-detail-epic-tasks>
    </div>
  </ng-template>

  <ng-template #linkIssueBlock>
    <div #linkIssueElement>
      <app-task-detail-link-issue #linkIssue [task]="task"></app-task-detail-link-issue>
    </div>
  </ng-template>

  <ng-template #subtaskBlock>
    <div #subtasksElement *ngIf="task.type?.name !== TaskTypeEnum.Storyline">
      <app-task-detail-subtask #subtasks
        [task]="task"
        [canOpenTaskKeyInNewTab]="canOpenTaskKeyInNewTab"
        (subtaskAdded)="onSubtaskAdded($event)"
        (taskKeyClicked)="taskKeyClicked.emit($event)">
      </app-task-detail-subtask>
    </div>
  </ng-template>

  <ng-template #timesheetBlock>
    <div #timesheetElement>
      <app-task-detail-timesheet #timesheet [task]="task"></app-task-detail-timesheet>
    </div>
  </ng-template>

  <ng-template #bottomContent>
    <ng-container *ngTemplateOutlet="attachmentBlock"></ng-container>
    <ng-container *ngTemplateOutlet="detailEpicTaskBlock"></ng-container>
    <ng-container *ngTemplateOutlet="linkIssueBlock"></ng-container>
    <ng-container *ngTemplateOutlet="subtaskBlock"></ng-container>
    <ng-container *ngTemplateOutlet="timesheetBlock"></ng-container>
    <div>
      <ng-container *ngTemplateOutlet="commentAndHistory"></ng-container>
    </div>
  </ng-template>

  <ng-template #taskStatus>
    <div class="status-wrap">
      <ng-select
        formControlName="status"
        [items]="status$ | async"
        bindLabel="name"
        [multiple]="false"
        [clearable]="false"
        [searchable]="true"
        [style.--task-status-color]="form.get('status').value?.colorCode">
        <ng-template ng-label-tmp let-item="item">
          <div class="custom-option">
            <img class="option-icon" [src]="item.icon">
            <span class="option-label text-uppercase bold-label">{{item.name}}</span>
          </div>
        </ng-template>
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="custom-option">
            <img class="option-icon" [src]="item.icon">
            <span class="option-label text-uppercase bold-label">{{item.name}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </ng-template>

  <ng-template #moreAction>
    <div class="d-flex">
      <app-button-dropdown *ngIf="task.type?.name !== TaskTypeEnum.Epic">
        <a class="dropdown-item" (click)="onCloneTask()">
          <app-i18n [key]="'Clone Task'"></app-i18n>
        </a>
        <a class="dropdown-item" (click)="onDeleteTask()">
          <app-i18n [key]="'Delete Task'"></app-i18n>
        </a>
      </app-button-dropdown>

      <app-button-dropdown
        *ngIf="task.type?.name === TaskTypeEnum.Epic"
        [icon]="isEpic ? 'more_vert': 'more_horiz'" [transparent]="isEpic ? true: false" class="ml-2">
        <a class="dropdown-item" (click)="onCloneEpics()"><app-i18n [key]="'Clone Epic'"></app-i18n></a>
        <a class="dropdown-item" *ngIf="isEpic" (click)="onDeleteClick()"><app-i18n [key]="'Delete Epic'"></app-i18n></a>
        <a class="dropdown-item" *ngIf="!isEpic" (click)="onDeleteTask()"><app-i18n [key]="'Delete Epic'"></app-i18n></a>
      </app-button-dropdown>

      <div class="btn btn-white btn-w-32 mx-1"
        *ngIf="closeable"
        (click)="hideModal()" [tooltip]="'Close' | translate" [placement]="'bottom'">
        <span class="material-icons">close</span>
      </div>
    </div>
  </ng-template>

  <ng-template #attachmentBlock>
    <div #attachmentElement>
      <app-task-detail-file #detailFile [task]="task"></app-task-detail-file>
    </div>
  </ng-template>

  <ng-template #rightBody>
    <div class="task-detail-form mt-1">
      <app-task-detail-form
        [form]="form"
        [task]="task"
        [fields]="fields"
        (patchValue)="patchValue($event)">
      </app-task-detail-form>
    </div>

    <div class="footer-row mt-3">
      <div class="task-time">
        <div><app-i18n [key]="'Created'"></app-i18n> {{ task.createdAt | dateFormat: DATE_TIME_FORMAT }}</div>
        <div class="mt-1"><app-i18n [key]="'Updated'"></app-i18n> {{ task.modifiedAt | dateFormat: DATE_TIME_FORMAT }}</div>
      </div>
    </div>
  </ng-template>

  <ng-template #commentAndHistory>
    <div class="custom-tabset mt-4">
      <tabset>
        <tab [heading]="'Comments' | translate">
          <app-task-detail-comment
            [task]="task"
            [getUserPromise]="getUserPromise"
            [upload$]="uploadFileComment$"
            (saveComment)="saveComment($event.selectedUserMention, $event.htmlString)"
            (updateAttachment)="uploadTaskAttachment($event)">
          </app-task-detail-comment>
        </tab>
        <!-- <tab heading="History">
        </tab> -->
        <tab heading="{{'History' | translate}}">
          <app-task-detail-history
            [task]="task">
          </app-task-detail-history>
        </tab>

        <tab [heading]="'Commit' | translate">
          <app-task-detail-commit [task]="task"></app-task-detail-commit>
        </tab>
      </tabset>
    </div>
  </ng-template>

  <ng-template #taskOfEpic>
    <app-task-detail [uiDirection]="'column'" *ngIf="selectedTaskEpic"
      [taskId]="selectedTaskEpic?.id"
      [linkable]="true" [closeable]="true"
      [canOpenTaskKeyInNewTab]="true"
      (updated)="onTaskEpicUpdated($event)"
      (hide)="isShowTaskDetail = false; selectedTask = null">
    </app-task-detail>
  </ng-template>

  <ng-template #platformTemplate>
    <span class="badge-platform mr-1 task-platform" *ngIf="task.platform">{{task.platform.name}}</span>
  </ng-template>


  <ng-template #cloneTasksTemplate>
    <div class="d-flex align-items-center mt-2">
      <input type="checkbox" [formControl]="cloneAllTasksControl" class="mr-2" id="alltask">
      <label class="form-check-label" for="alltask">
        <app-i18n key="Do you want to clone all tasks in epics?"></app-i18n>
      </label>
    </div>
  </ng-template>
</div>

