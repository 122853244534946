import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as queryString from 'query-string';
import { List2Res, Pageable, PayLoadPatchCompany, SystemAdminResponse } from 'src/app/shared';
import { BillingResponse } from 'src/app/shared/_models/payment.model'
import { environment } from 'src/environments/environment';
import { Customer, CustomerReport, CustomerSearch } from 'src/app/shared/_models/customer.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService  {
  constructor(
    private http: HttpClient,
  ) {}

  updateUser(payload: string = '') {
    return this.http.post(`${environment.apiUrl}/companies-management/user-update-status`, payload)
  }

  getCompany(payload: CustomerSearch, pageable: Pageable) {
    const params = queryString.stringify({...payload,...pageable});
    return this.http.get<List2Res<Customer>>(`${environment.apiUrl}/companies-management/?${params}`)
  }

  getDetailCompany(companyId: number) {
    return this.http.get<Customer>(`${environment.apiUrl}/companies-management/${companyId}`);
  }

  getBillingCompany(companyId: number, pageable: Pageable) {
    const params = queryString.stringify(pageable);
    return this.http.get<List2Res<BillingResponse>>(
      `${environment.apiUrl}/companies-management/${companyId}/billing-history?${params}`
    );
  }

  getReport(payload: CustomerSearch): Observable<CustomerReport> {
    const params = `preFromDate=${payload.preFromDate}&preToDate=${payload.preToDate}`
      + `&fromDate=${payload.fromDate}&toDate=${payload.toDate}`;
    return this.http.get<CustomerReport>(`${environment.apiUrl}/companies-management/report?${params}`);
  }

  patchCompany(companyId: number, payload: PayLoadPatchCompany) {
    return this.http.patch<Customer>(`${environment.apiUrl}/companies-management/${companyId}`, payload);
  }

  getSystemAdmin() {
    return this.http.get<SystemAdminResponse>(`${environment.apiUrl}/companies-management/check-system-admin`);
  }

}
