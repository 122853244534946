<div class="navbar-wrapper web-navbar container-fluid" *ngIf="isWebLayout">
  <div class="row row-content">
    <div class="col-12 d-flex align-self-center">
      <div class="d-flex align-items-center w-100">
        <img class="company-logo" src="../../../../assets/img/logo.svg" (click)="onClickLogo()" alt="">

        <ng-container *ngIf="templateRef">
          <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{}">
          </ng-template>
        </ng-container>

        <div class="navbar-menu">
          <div class="">
            <button class="btn p-0 ml-3" (click)="onSearch()" [tooltip]="('Press hotkey' | translate) + ': Ctrl + K'">
              <span class="search-icon"><i class="fa fa-search" aria-hidden="true"></i></span>
            </button>
          </div>
          <div class="switch-language-wrapper pl-3">
            <app-switch-language></app-switch-language>
          </div>
          <div class="notification-system ml-3">
            <app-notification-system></app-notification-system>
          </div>
          <div class="user-account-dropdown ml-3" dropdown #dropdown="bs-dropdown" container="body"
            placement="bottom right">

            <div class="pointer" dropdownToggle>
              <app-avatar
                [size]="'large'"
                [url]="user.avatar"
                [name]="user.fullName"
                [hideTooltip]="true"
              ></app-avatar>
            </div>
            <div class="dropdown-menu dropdown-menu-right user-account-dropdown-menu" *dropdownMenu>
              <div class="header main-acc">
                <app-avatar
                  [url]="user.avatarLarge"
                  [name]="user.fullName"
                ></app-avatar>
                <div class="fw-6 mt-2">{{user.fullName}}</div>
                <div>{{user.email}}</div>
                <div class="text-primary">{{user.company.domain}}</div>
                <a class="btn btn-outline-secondary mt-3" [routerLink]="'/organization/users/my-profile'"><app-i18n [key]="'My Profile'"></app-i18n></a>
                <a class="btn btn-outline-secondary mt-3" (click)="onChangePassword()"><app-i18n [key]="'Change Password'"></app-i18n></a>
              </div>

              <div class="acc-list">
                <div class="acc-wrapper add_acc justify-content-center" [routerLink]="['/w']">
                  <app-avatar
                    [size]="'medium'"
                    [url]="'../../../../assets/icons/ic_black_xcorp.svg'"
                    [hideTooltip]="true"
                  ></app-avatar>
                  <div class="right-block">
                    <span class="fw-6">About xCorp®</span>
                  </div>
                </div>
              </div>
              <div class="footer">
                <button class="btn btn-outline-secondary" (click)="signOut()"><app-i18n [key]="'Sign out'"></app-i18n></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="navbar-wrapper mobile-navbar container-fluid" *ngIf="!isWebLayout">
  <div class="row">
    <div class="col-12">
      <div class="navbar-content">
        <ng-container *ngIf="templateRef">
          <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{}">
          </ng-template>
        </ng-container>

        <div class="notification-system ml-3">
          <app-notification-system></app-notification-system>
        </div>
      </div>
    </div>
  </div>
</div>
