import { Pageable } from './generic';

export interface LeaveReport {
  avatar: string;
  fullName: string;
  teams: string[];
  location: string;
  typeResponses: {
    leaveTypeId: number;
    leaveTypeName: string;
    days: number;
  }[];
}

export type ExportType = 'CSV' | 'PDF' | 'EXCEL';

export interface LeaveReportSearch {
  startDate?: string; // yyyy-mm-dd
  endDate?: string; // yyyy-mm-dd
  locationId?: number;
  teamIds?: number[];
  employeeIds?: number[];
  paidFlag?: boolean;
  exportType?: ExportType;
  userStatus: string;
}
