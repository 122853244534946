import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { environment } from 'src/environments/environment';
import { I18nService } from 'src/app/site-management/_services';
import { Logger } from '../models/logger';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private i18nService: I18nService,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(environment.apiUrl)) {
      return next.handle(request);
    }

    const token: string = this.authService.getAccessToken();  
    if (!token) {
      return next.handle(request);
    }
  
    const langCode = this.i18nService.getCurrentLang();
    const headers = {
      'Accept-Language': langCode,
      'Authorization': `Bearer ${token}`,
      'company-secret': this.authService.getLoggedUser()?.company?.companySecret || '',
    };

    if (request.responseType === 'blob') {
      headers['ngsw-bypass'] = 'true';
    }

    request = request.clone({
      setHeaders: headers,
    });
    return next.handle(request);
  }
}
