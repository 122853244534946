import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/core';
import { NzToastType } from 'src/app/core/models/toast';
import { Task, routerObject } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class TaskUtilsService {

  text = 'To open task, please click on the link below';
  title = 'Create successfully!';

  constructor(private toast: ToastService, private translateService: TranslateService) { }

  onShowToast(task: Task) {
    const _text = this.translateService.instant(this.text);
    const taskLink = `<div><a href="${routerObject.taskMgmtTaskList.fullPath}/${task.key}" target="_blank">${task.key}</a></div>`;
    const content = `${_text}${taskLink}`;

    this.toast.create(NzToastType.Success, this.title, content, { nzDuration: 8000 });
  }
}
