import { Component, Input, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-general-confirm',
  templateUrl: './general-confirm.component.html',
  styleUrls: ['./general-confirm.component.scss']
})
export class GeneralConfirmComponent {
  @Input() title: string;
  @Input() titleParams = {};
  @Input() message: string;
  @Input() params = {};
  @Input() templateRef: TemplateRef<any>;
  @Input() saveBtnName = 'Yes';
  @Input() cancelBtnName = 'No';
  public result$: Subject<boolean> = new Subject();

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  onNoClick() {
    this.bsModalRef.hide();
    this.result$.next(false);
  }

  onYesClick() {
    this.bsModalRef.hide();
    this.result$.next(true);
  }
}
