import { SearchSystemData } from "src/app/site-management/_store/search-system/search-system.model";
import { TicketNode } from "./ticket.model";
import { CvNode } from "./cv.model";

export interface NodeParam {
  [key: string]: string
}

export interface MenuNodeLink {
  routerLink: string;
  params: NodeParam
}

export enum IconType {
  LINK = 'LINK',
  LOCAL = 'LOCAL',
  MAT = 'MAT'
}

export class ParentCacheNode {
  text: string;
  icon?: string;
}

export class BaseNode {
  text: string;
  icon: string;
  iconType?: IconType;
  routerLink?: string;
  params?: NodeParam;
  newTab?: boolean;
  isSystem?: boolean;
  data?: SearchSystemData;
  id?: number;

  constructor(node: BaseNode) {
    this.text = node.text;
    this.icon = node.icon;
    this.newTab = node.newTab;
    this.iconType = node.iconType;
    this.routerLink = node.routerLink;
    this.params = node.params;
    this.isSystem = node.isSystem;
    this.data = node.data;
    this.id = node.id;

  }
}

export interface SystemDataTypeModal {
  name: string;
  value: SystemDataType;
}

export enum SystemDataName {
  MENU= 'Menu',
  USER= 'User',
  TICKET= 'Task',
  CV= 'CV',
  TEAM= 'Team',
  GROUP= 'Group',
  PROJECT= 'Project',
  SALARY= 'Salary Profile',
  SCHEDULE= 'Pay Schedule',
  PAYROLL= 'Payroll',
  FILE= 'Document'
  // todo
}

export enum SystemDataType {
  MENU= 'MENU',
  USER= 'USER',
  TICKET= 'TICKET',
  CV= 'CV',
  TEAM= 'TEAM',
  GROUP= 'GROUP',
  PROJECT= 'PROJECT',
  SALARY= 'SALARY',
  SCHEDULE= 'SCHEDULE',
  PAYROLL= 'PAYROLL',
  FILE= 'FILE'
  // todo
}

export enum SystemDataTypeIcon {
  MENU= 'num-sign',
  USER= 'at-sign',
  TICKET= 'arrow',
  CV= 'colon',
  // todo
}

export const DataTypePrefix = {
  [SystemDataType.MENU]: ['#', 'menu:'],
  [SystemDataType.USER]: ['@', 'user:'],
  [SystemDataType.TICKET]: ['>', 'task:'],
  [SystemDataType.CV]: [':C', 'cv:'],
  [SystemDataType.GROUP]: [':G', 'group:'],
  [SystemDataType.TEAM]: [':T', 'team:'],
  [SystemDataType.PROJECT]: [':Pr', 'project:'],
  [SystemDataType.SALARY]: [':S', 'salary:'],
  [SystemDataType.SCHEDULE]: [':PS', 'schedule:'],
  [SystemDataType.PAYROLL]: [':P', 'payroll:'],
  [SystemDataType.FILE]: [':D', 'file:'],
  // todo
}

export interface CheckedKeyword {
  type: SystemDataType
  keyword: string;
}

export interface SelectOptionType<T = any> {
  name: string;
  value: T;
  icon: string;
}

export class SystemData {
  static get options(): SelectOptionType<SystemDataType>[] {
    return Object.keys(SystemDataType).map((key) => {
      return {
        name: SystemDataName[key],
        value: SystemDataType[key],
        icon: SystemDataTypeIcon[key]
      };
    });
  }
}

export interface SearchCache {
  node: BaseNode | TicketNode | CvNode;
  parents?: ParentCacheNode[];
  createAt: string;
  type: SystemDataType;
}

