import { ConfigOption } from '@ngx-formly/core';
import { addonsExtension } from 'src/app/libs/formly/extentions/addons.extentsion';
import { translateExtension } from 'src/app/libs/formly/extentions/translate.extentsion';
import { SwitchType } from 'src/app/libs/formly/formly-switch/formly-switch.component';
import { TagsType } from 'src/app/libs/formly/formly-tags/formly-tags.component';
import { HorizontalWrapper } from 'src/app/libs/formly/horizontal-wrapper/horizontal-wrapper';
import { NgxSelectType } from 'src/app/libs/formly/ngx-select/ngx-select.component';
import { TimePickerType } from 'src/app/libs/formly/time-picker/time-picker.component';

export const FormlyCustomType = [
  TimePickerType,
  NgxSelectType,
  HorizontalWrapper,
  TagsType,
  SwitchType
];

export const FormlyConfig: ConfigOption = {
  types: [
    { name: 'time-picker', component: TimePickerType },
    { name: 'ngx-select', component: NgxSelectType },
    { name: 'tags', component: TagsType },
    { name: 'switch', component:  SwitchType},
  ],
  wrappers: [{ name: 'form-field-horizontal', component: HorizontalWrapper }],
  validationMessages: [{ name: 'required', message: 'This field is required' }],
  extensions: [{ name: 'addons', extension: { onPopulate: addonsExtension } },
  { name: 'translate', extension: { onPopulate: translateExtension } }]
};
