import { DateFormat } from './../site-management/_pipes/date-format.pipe';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Directives
import {
  RequiredIfDirective,
  InvalidIfDirective,
  DisableIfDirective,
  DragAndDropDirective,
  FileDropZoneDirective
} from './_directives';
import { ContenteditableDirective } from './_directives/content-editable.directive';
import { StopEventDirective } from './_directives/stop-event.directive';
import { PasswordVisibilityDirective } from './_directives/password-visibility.directive';
import { RestrictMultiSubmitDirective } from './_directives';
import { DropdownPositionDirective } from './_directives/dropdown-overflow.directive';
import { HasPermissionDirective } from './_directives/has-permission.directive';
import { HoverDropdownDirective } from './_directives/hover-dropdown.directive';
import { ClickOutsideDirective } from './_directives/click-outside.directive';
import { DateTimePickerDirective } from './_directives/bs-date-time-picker.directive';
import { TooltipOverlayDirective } from './_directives/tooltip-overlay.directive';
import { QuillMentionEventDirective } from './_components/editor-content-editable/_directives/quill-mention-event.directive';
import { TimePickerDirective } from './_directives/bs-time-picker.directive';
import { NgForScrollCustomizeDirective } from './_directives/ng-for-scroll-customize.directive';
import { UploadFolderDirective } from './_directives/upload-folder.directive';

// Components
import { FormErrorComponent } from './_components/form-error/form-error.component';
import { ProgressBarComponent } from './_components/progress-bar/progress-bar.component';
import { ColorPickerComponent } from './_components/color-picker/color-picker.component';
import { SwitchDateComponent } from './_components/switch-date/switch-date.component';
import { MultiFilterComponent } from './_components/multi-filter/multi-filter.component';
import { CalendarMonthComponent } from './_components/calendar-month/calendar-month.component';
import { UploadFileComponent } from './_components/upload-file/upload-file.component';
import { SwitchDatesComponent } from './_components/switch-dates/switch-dates.component';
import { EditorComponent } from './_components/editor/editor.component';
import { SearchInputComponent } from './_components/search-input/search-input.component';
import { AvatarComponent } from './_components/avatar/avatar.component';
import { EditorFormComponent } from './_components/editor-form/editor-form.component';
import { ColorPickerFormComponent } from './_components/color-picker-form/color-picker-form.component';
import { FilePreviewComponent } from './_components/file-preview/file-preview.component';
import { BreadcumbItemComponent } from './_components/breadcumb-item/breadcumb-item.component';
import { PricingInfoComponent } from './_components/pricing-info/pricing-info.component';
import { AwsSignatureComponent } from './_components/aws-signature/aws-signature.component';
import { ContextMenuComponent } from './_components/context-menu/context-menu.component';
import { InputComponent } from './_components/input/input.component';
import { ProgressGroupComponent } from './_components/progress-group/progress-group.component';
import { DatepickerComponent } from './_components/datepicker/datepicker.component';
import { ProgressComponent } from './_components/progress/progress.component';
import { BadgeComponent } from './_components/badge/badge.component';
import { AwsSignatureVideoComponent } from './_components/aws-signature-video/aws-signature-video.component';
import { NgxPageSizeComponent } from './_components/ngx-pagesize/ngx-pagesize.component';
import { IconComponent } from './_components/icon/icon.component';
import { SelectColorComponent } from './_components/select-color/select-color.component';
import { SelectColorPipe } from './_components/select-color/select-color.pipe';
import { ModalContainerModule } from './_components/modal-container/modal-container.module';
import { ButtonDropdownComponent } from './_components/button-dropdown/button-dropdown.component';
import { SearchEmployeeComponent } from './_components/controls/search-employee/search-employee.component';
import { SearchTeamComponent } from './_components/controls/search-team/search-team.component';
import { SearchGroupComponent } from './_components/controls/search-group/search-group.component';
import { PeriodFilterComponent } from './_components/period-filter/period-filter.component';
import { SearchPolicyComponent } from './_components/controls/search-policy/search-policy.component';
import { SearchOverlayComponent } from './_components/search-overlay/search-overlay.component';
import { SearchBenefitRuleComponent } from './_components/controls/search-benefit-rule/search-benefit-rule.component';
import { SearchOverlayUserComponent } from './_components/search-overlay/search-overlay-user/search-overlay-user.component';
import { SearchOverlayTeamComponent } from './_components/search-overlay/search-overlay-team/search-overlay-team.component';
import { SearchOverlayGroupComponent } from './_components/search-overlay/search-overlay-group/search-overlay-group.component';
import { CellDueDateComponent } from './_components/controls/cell-due-date/cell-due-date.component';
import { CellTextEditableComponent } from './_components/controls/cell-text-editable/cell-text-editable.component';
import { NameEditableComponent } from './_components/controls/name-editable/name-editable.component';
import { EditorContentEditableComponent } from './_components/editor-content-editable/editor-content-editable.component';
import { EditorHtmlViewComponent } from './_components/editor-content-editable/editor-html-view/editor-html-view.component';
import { IconPickerComponent } from './_components/icon-picker/icon-picker.component';
import { GroupByComponent } from './_components/group-by/group-by.component';
import { DFieldComponent } from './_components/dynamic-field/d-field/d-field.component';
import { DFieldInputComponent } from './_components/dynamic-field/d-field/d-field-input/d-field-input.component';
import { DFieldDateComponent } from './_components/dynamic-field/d-field/d-field-date/d-field-date.component';
import { DFieldSelectComponent } from './_components/dynamic-field/d-field/d-field-select/d-field-select.component';
import { PercentChangeComponent } from './_components/percent-change/percent-change.component';
import { I18nComponent } from './_components/_internationalization/i18n/i18n.component';
import { SwitchLanguageComponent } from './_components/_internationalization/switch-language/switch-language.component';
import { ImportFileComponent } from './_components/import-file/import-file.component';
import { I18nMasterDataComponent } from './_components/_internationalization/i18n-master-data/i18n-master-data.component';
import { MobileListComponent } from './_components/mobile-list/mobile-list.component';
import { CellDateEditableComponent } from './_components/controls/cell-date-editable/cell-date-editable.component';
import { DFieldTableComponent } from './_components/dynamic-field/d-field/d-field-table/d-field-table.component';
import { DFieldTableRowUpsertComponent } from './_components/dynamic-field/d-field/d-field-table/d-field-table-row-upsert/d-field-table-row-upsert.component';
import { DFieldFileComponent } from './_components/dynamic-field/d-field/d-field-file/d-field-file.component';
import { DTabComponent } from './_components/dynamic-field/d-tab/d-tab.component';
import { TaskAssigneeComponent } from './_components/task-assignee/task-assignee.component';
import { TaskDateComponent } from './_components/task-date/task-date.component';
import { NotificationListComponent } from './_components/notification-system/notification-list/notification-list.component';
import { NotificationSystemComponent } from './_components/notification-system/notification-system.component';
import { DSectionComponent } from './_components/dynamic-field/d-section/d-section.component';
import { DPageComponent } from './_components/dynamic-field/d-page/d-page.component';
import { MobileSearchComponent } from './_components/mobile-search/mobile-search.component';
import { UserAvatarListComponent } from './_components/user-avatar-list/user-avatar-list.component';
import { FxEditorComponent } from './_components/antlr/fx-editor/fx-editor.component';
import { AdvanceFilterComponent } from './_components/advance-filter/advance-filter.component';
import { BasicFilterComponent } from './_components/basic-filter/basic-filter.component';
import { UserDropdownComponent } from './_components/select-user/user-dropdown.component';
import { DFieldCellTableComponent } from './_components/dynamic-field/d-field/d-field-table/d-field-cell-table/d-field-cell-table.component';
import { DFieldCellFileTableComponent } from './_components/dynamic-field/d-field/d-field-table/d-field-cell-table/d-field-cell-file-table/d-field-cell-file-table.component';
import { DFieldCellDateTableComponent } from './_components/dynamic-field/d-field/d-field-table/d-field-cell-table/d-field-cell-date-table/d-field-cell-date-table.component';
import { DFieldCellDropdownTableComponent } from './_components/dynamic-field/d-field/d-field-table/d-field-cell-table/d-field-cell-dropdown-table/d-field-cell-dropdown-table.component';
import { DFieldCellTextTableComponent } from './_components/dynamic-field/d-field/d-field-table/d-field-cell-table/d-field-cell-text-table/d-field-cell-text-table.component';
import { TaskDueDateComponent } from './_components/task-due-date/task-due-date.component';
import { UploadFileToastComponent } from './_components/upload-file-toast/upload-file-toast.component';
import { EmojiPickerComponent } from './_components/emoji-picker/emoji-picker.component';
import { EmojiPreviewComponent } from './_components/emoji-preview/emoji-preview.component';
import { EmojiCommentComponent } from './_components/emoji-comment/emoji-comment.component';
import { EmojiUserListsComponent } from './_components/emoji-user-lists/emoji-user-lists.component';
import { CommentFeedbackComponent } from './_components/feedback-comment/comment-feedback.component';
import { WrapperComponent } from './_components/wrapper/wrapper.component';
import { NoPermissionComponent } from './_components/no-permission/no-permission.component';
import { DateTimePickerComponent } from './_components/date-time-picker/date-time-picker.component';
import { TimePickerComponent } from './_components/time-picker/time-picker.component';
import { TaskStatusComponent } from './_components/task-status/task-status.component';
import { EditableTagComponent } from './_components/editable-tag/editable-tag.component';
import { LoadingComponent } from './_components/loading/loading.component';
import { EpicListDropdownComponent } from '../site-management/projects/epics/epic-list-dropdown/epic-list-dropdown.component';
import { EpicInlineTaskComponent } from '../site-management/projects/epics/epic-inline-task/epic-inline-task.component';
import { TagsComponent } from './_components/tags/tags.component';
import { EpicColorsDropdownListComponent } from './_components/epic-colors-dropdown-list/epic-colors-dropdown-list.component';
import { TimePickerControlComponent } from './_components/time-picker-control/time-picker-control.component';
import { TimePickerInputComponent } from './_components/time-picker-input/time-picker-input.component';
import { TimepickerComponent } from './_components/timepicker/timepicker.component';
import { ShareTargetComponent } from './_components/share-target/share-target.component';

// Pipes
import { SafePipe } from '../site-management/_pipes/safe.pipe';
import { SafeHtmlPipe } from '../site-management/_pipes/safeHtml.pipe';
import { ProfileOverlayComponent } from '../site-management/_shared/profile-overlay/profile-overlay.component';
import { ProfileComponent } from '../site-management/_shared/profile-overlay/profile/profile.component';
import { LeaveHistoryComponent } from '../site-management/_shared/profile-overlay/leave-history/leave-history.component';
import { UserReportHistoryComponent } from '../site-management/_shared/user-info/user-report-history/user-report-history.component';
import { UserAvatarComponent } from '../site-management/_shared/user-info/user-avatar/user-avatar.component';
import { RomanizePipe } from '../site-management/_pipes/romanize.pipe';
import { AlphabetizePipe } from '../site-management/_pipes/alphabetize.pipe';
import { NumberSuffixPipe } from '../site-management/_pipes/numberSuffix.pipe';
import { BaseTaskDescriptionComponent } from '../site-management/_shared/task-components/base-task-description/base-task-description.component';
import { AutoInputWidth } from './../site-management/_pipes/autoInputWidth.pipe';
import { DueDateItem } from '../site-management/_pipes/dueDateItem.pipe';
import { ValueCellTablePipe } from '../site-management/_pipes/dynamic-field';
import { DPageBreadcrumbComponent } from '../site-management/dynamic-page/dynamic-page-breadcrumb/dynamic-page-breadcrumb.component';
import { BacklogContextMenuComponent } from '../site-management/projects/backlogs/backlog-context-menu/backlog-context-menu.component';
import { BacklogBulkChangeComponent } from '../site-management/projects/backlogs/backlog-context-menu/backlog-bulk-change-tasks/backlog-bulk-change-tasks.component';
import { NumberFormatPipe } from '../site-management/_pipes/number-format.pipe';
import { NumberComponent } from './_components/number/number.component';
import { TaskProgressComponent } from './_components/task-progress/task-progress.component';
import { ProgressGroupColorPipe } from './pipes/progress-group-color.pipe';
import { ProgressTooltipPipe } from './pipes/progress-group-tooltop.pipe';
import { DateTimeInputComponent } from './_components/date-time-input/date-time-input.component';
import { PeriodActivePipe } from './pipes/period-active.pipe';
import { ColorPipe } from '../site-management/_pipes/color.pipe';
import { GetNamePipe } from '../site-management/_shared/user-info/user-avatar/user-avatar.pipe';
import { OptionWithObservablePipe } from './pipes/option-with-observable.pipe';
import { AnyPipe } from './pipes/any.pipe';
import { GetShortNamePipe } from '../site-management/_pipes/getShortName.pipe';
import { GetSelectedTimePipe } from './_components/time-picker-control/pipes/get-selected-time.pipe';
import { LocalCommentPipe } from '../site-management/_pipes/local-comment.pipe';
import { GetFileSizePipe } from '../site-management/_pipes/getFileSize.pipe';
import { IsTaskHistoryEmptyPipe } from '../site-management/_shared/task-components/pipes/is-task-history-empty.pipe';
import { TaskTimeFormatPipe } from '../site-management/_shared/task-components/pipes/task-time-format.pipe';
import { IsTaskEditorFieldPipe } from '../site-management/_shared/task-components/pipes/is-task-editor-field.pipe';
import { IsTaskTimeFieldPipe } from '../site-management/_shared/task-components/pipes/is-task-time-field.pipe';

// External Library
import { LibsModule } from '../libs/libs.module';
import { FormlyCustomType } from '../core/config/formly.config';

const LIBS = [
  LibsModule
];

const DIRECTIVES = [
  RequiredIfDirective,
  InvalidIfDirective,
  HasPermissionDirective,
  DisableIfDirective,
  DragAndDropDirective,
  FileDropZoneDirective,
  ContenteditableDirective,
  StopEventDirective,
  PasswordVisibilityDirective,
  RestrictMultiSubmitDirective,
  DropdownPositionDirective,
  HoverDropdownDirective,
  ClickOutsideDirective,
  DateTimePickerDirective,
  QuillMentionEventDirective,
  TooltipOverlayDirective,
  TimePickerDirective,
  NgForScrollCustomizeDirective,
  UploadFolderDirective,
];

const COMPONENTS = [
  BaseTaskDescriptionComponent,
  I18nComponent,
  I18nMasterDataComponent,
  FxEditorComponent,
  SwitchLanguageComponent,
  FormErrorComponent,
  ProgressBarComponent,
  ColorPickerComponent,
  ColorPickerFormComponent,
  SwitchDateComponent,
  MultiFilterComponent,
  CalendarMonthComponent,
  UploadFileComponent,
  SwitchDatesComponent,
  EditorComponent,
  SearchInputComponent,
  AvatarComponent,
  EditorFormComponent,
  FilePreviewComponent,
  BreadcumbItemComponent,
  PricingInfoComponent,
  AwsSignatureComponent,
  AwsSignatureVideoComponent,
  ContextMenuComponent,
  InputComponent,
  ProgressGroupComponent,
  DatepickerComponent,
  ProgressComponent,
  BadgeComponent,
  NgxPageSizeComponent,
  IconComponent,
  SelectColorComponent,
  ProfileOverlayComponent,
  ProfileComponent,
  LeaveHistoryComponent,
  UserReportHistoryComponent,
  UserAvatarComponent,
  ButtonDropdownComponent,
  SearchEmployeeComponent,
  SearchTeamComponent,
  SearchGroupComponent,
  PeriodFilterComponent,
  SearchPolicyComponent,
  SearchOverlayComponent,
  SearchBenefitRuleComponent,
  SearchOverlayUserComponent,
  SearchOverlayTeamComponent,
  SearchOverlayGroupComponent,
  CellDueDateComponent,
  CellDateEditableComponent,
  CellTextEditableComponent,
  NameEditableComponent,
  EditorHtmlViewComponent,
  EditorContentEditableComponent,
  IconPickerComponent,
  GroupByComponent,
  IconPickerComponent,
  DFieldComponent,
  DFieldInputComponent,
  DFieldDateComponent,
  DFieldSelectComponent,
  PercentChangeComponent,
  ImportFileComponent,
  MobileListComponent,
  DFieldTableComponent,
  DFieldCellTableComponent,
  DFieldCellFileTableComponent,
  DFieldCellDateTableComponent,
  DFieldCellDropdownTableComponent,
  DFieldCellTextTableComponent,
  DFieldTableRowUpsertComponent,
  DFieldFileComponent,
  DTabComponent,
  DPageComponent,
  DSectionComponent,
  DPageBreadcrumbComponent,
  TaskAssigneeComponent,
  TaskDateComponent,
  NotificationListComponent,
  NotificationSystemComponent,
  MobileSearchComponent,
  UserAvatarListComponent,
  AdvanceFilterComponent,
  BasicFilterComponent,
  UserDropdownComponent,
  TaskDueDateComponent,
  UploadFileToastComponent,
  EmojiPickerComponent,
  EmojiPreviewComponent,
  EmojiCommentComponent,
  EmojiUserListsComponent,
  CommentFeedbackComponent,
  BacklogContextMenuComponent,
  BacklogBulkChangeComponent,
  NumberComponent,
  TaskProgressComponent,
  WrapperComponent,
  NoPermissionComponent,
  DateTimeInputComponent,
  DateTimePickerComponent,
  TimePickerComponent,
  TaskStatusComponent,
  EditableTagComponent,
  ...FormlyCustomType,
  LoadingComponent,
  EpicListDropdownComponent,
  EpicInlineTaskComponent,
  TagsComponent,
  EpicColorsDropdownListComponent,
  TimePickerControlComponent,
  TimePickerInputComponent,
  TimepickerComponent,
  ShareTargetComponent
];

const ANGULAR_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule
];

const PIPES = [
  DateFormat,
  SafePipe,
  ColorPipe,
  SafeHtmlPipe,
  SelectColorPipe,
  RomanizePipe,
  NumberSuffixPipe,
  AlphabetizePipe,
  AutoInputWidth,
  DueDateItem,
  ValueCellTablePipe,
  NumberFormatPipe,
  ProgressGroupColorPipe,
  ProgressTooltipPipe,
  PeriodActivePipe,
  GetNamePipe,
  OptionWithObservablePipe,
  AnyPipe,
  GetShortNamePipe,
  GetSelectedTimePipe,
  LocalCommentPipe,
  GetFileSizePipe,
  IsTaskHistoryEmptyPipe,
  TaskTimeFormatPipe,
  IsTaskEditorFieldPipe,
  IsTaskTimeFieldPipe
];

const PROVIDERS = [
  DecimalPipe,
];

const LIBRARY_MODULES = [
  ModalContainerModule,
];
@NgModule({
  declarations: [
    ...DIRECTIVES,
    ...COMPONENTS,
    ...PIPES,
  ],
  imports: [
    ...ANGULAR_MODULES,
    ...LIBRARY_MODULES,
    ...LIBS,
  ],
  providers: [
    ...PIPES,
    ...PROVIDERS,
  ],
  exports: [
    ...DIRECTIVES,
    ...COMPONENTS,
    ...ANGULAR_MODULES,
    ...LIBRARY_MODULES,
    ...PIPES,
    ...LIBS
  ]
})
export class SharedModule {}
export { ImportFileComponent };
