export enum TaskDetailFormType {
  TaskTemplate = 'taskTemplate',
  Assignee = 'assignee',
  Reporter = 'reporter',
  CC = 'cc',
  Type = 'type',
  Priority = 'priority',
  Epic = 'epic',
  ColorCode = 'colorCode',
  Platform = 'platform',
  Storyline = 'storyline',
  Label = 'label',
  Releases = 'releases',
  Sprint = 'sprint',
  Status = 'status',
  DueDate = 'dueDate',
  EndDate = 'endDate',
  StartDate = 'startDate',
  FollowUp = 'followUp',
  Reminder = 'reminder',
}

export enum LinkType {
  FINISH_TO_FINISH = 'FINISH_TO_FINISH',
  START_TO_START = 'START_TO_START',
  JIRA_SUBTASK_OUTWARD = 'JIRA_SUBTASK_OUTWARD',
  JIRA_SUBTASK_INWARD = 'JIRA_SUBTASK_INWARD',
  IS_EPIC_OF = 'IS_EPIC_OF',
  HAS_EPIC = 'HAS_EPIC',
  BLOCKS = 'BLOCKS',
  IS_BLOCKED_BY = 'IS_BLOCKED_BY',
  CLONES = 'CLONES',
  IS_CLONED_BY = 'IS_CLONED_BY',
  DUPLICATES = 'DUPLICATES',
  IS_DUPLICATED_BY = 'IS_DUPLICATED_BY',
  TESTS = 'TESTS',
  IS_TESTED_BY = 'IS_TESTED_BY',
  RELATES_TO = 'RELATES_TO',
  FINISH_TO_START = 'FINISH_TO_START',
  START_TO_FINISH = 'START_TO_FINISH',
  CREATED = 'CREATED',
  CREATED_BY = 'CREATED_BY',
  CAUSES = 'CAUSES',
  IS_CAUSED_BY = 'IS_CAUSED_BY',
  SPLIT_TO = 'SPLIT_TO',
  SPLIT_FROM = 'SPLIT_FROM'
}
