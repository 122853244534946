<div class="notification-wrapper" dropdown container="body" placement="bottom right" [insideClick]="true" (onShown) = "onShowNotifications()">
  <div class="notification" dropdownToggle>
    <img class="img-ring" src="../../../../assets/icons/notification.svg" alt="">
    <div class="quantity-notification" *ngIf="numberNotification" [ngClass]="{'more-notification':showMoreThanLimitNotifications()}">{{numberNotification > 99 ? limitNotificationDisplay : numberNotification}} <span
        *ngIf="showMoreThanLimitNotifications()">+</span></div>
  </div>

  <div *dropdownMenu class="dropdown-menu dropdown-menu-right user-account-dropdown-menu notification-content">
    <div class="d-flex justify-content-between">
      <div class="flex flex-column flex-sm-row justify-content-between w-100">
        <div class="title"><app-i18n [key]="'notifications'"></app-i18n></div>
        <div class="flex">
          <div class="text-mark-all" (click)="onMarkAllNotification()" [ngClass]="{'disable-mark-all': numberNotification < 1}">
            <app-i18n [key]="'markAllAsRead'"></app-i18n>
          </div>
          <div dropdownToggle
            class="flex ml-3"
            role="button"
            [tooltip]="'Setting' | translate"
            [placement]="'bottom'"
            [routerLink]="this.routerObject.orgMyProfile.fullPath" [queryParams]="{tab: 'Notification'}"
            (click)="isShowNotification = false">
            <img src="../../../../assets/icons/notification/notification_setting.svg" alt="">
          </div>
        </div>
      </div>

      <div class="close-dropdown" dropdownToggle type="button">
        <span class="material-icons-outlined icon-md"> close </span>
      </div>
    </div>

    <div class="custom-tabset mt-4">
      <tabset>
        <tab heading="{{'All' | translate}}" (selectTab)="getNotificationUnRead(notificationTab.all)">
          <ng-container *ngTemplateOutlet="notificationList"></ng-container>
        </tab>
        <tab heading="{{'Unread' | translate}}" (selectTab)="getNotificationUnRead(notificationTab.unread)" active="true">
          <ng-container *ngTemplateOutlet="notificationList"></ng-container>
        </tab>
      </tabset>
    </div>
  </div>
  <ng-template #notificationList>
    <div class="notification-list" infiniteScroll [infiniteScrollDistance]="infiniteScrollDistanceDefault"
      [infiniteScrollThrottle]="infiniteScrollThrottle" (scrolled)="onScrollDown()" [scrollWindow]="false">
      <div *ngFor="let notification of rows">
        <app-notification-list [notification]="notification" (remove)="onRemoveNotification($event)"
          (markNotification)="onMarkNotification($event)" (redirectTask)="onMarkNotification($event)"></app-notification-list>
      </div>
      <div *ngIf="!rows?.length" class="no-notification">
        <div><mat-icon [svgIcon]="'no-notification'" class="icon-xlg"></mat-icon></div>
        <div><app-i18n [key]="'notification.noNotification'"></app-i18n></div>
      </div>
    </div>
  </ng-template>
</div>
