export const productChilds = [
  {
    text: 'Task Management',
    routerLink: '/w/product/task-management',
    icon: '/assets/icons/m-task.svg'
  },
  {
    text: 'Timesheet',
    routerLink: '/w/product/timesheet',
    icon: '/assets/icons/m-daily.svg'
  },
  {
    text: 'Work Tracking',
    routerLink: '/w/product/work-tracking',
    icon: '/assets/icons/m-timesheet.svg'
  },
  {
    text: 'Leave Management',
    routerLink: '/w/product/leave-management',
    icon: '/assets/icons/m-leave.svg'
  },
  {
    text: 'Document Management',
    routerLink: '/w/product/document-management',
    icon: '/assets/icons/m-doc.svg'
  },
  {
    text: 'OKR',
    routerLink: '/w/product/okr',
    icon: '/assets/icons/m-okr.svg'
  },
  {
    text: 'Slack Integration',
    routerLink: '/w/product/slack',
    icon: '/assets/icons/slack.png'
  }
];