import { HolidayType } from '../_utils/enums';

export interface Holiday {
  id?: number;
  holidayDate: string;
  name: string;
  description: string;
  type: HolidayType;
  overtimeRate: number;
}

export interface HolidaySearchRequest {
  fromDate: string;
  toDate: string;
}
