import * as moment from "moment";

export const numMaxDayInMonth = 31;
export const monthsOfYear = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const DefaultPickerPlaceholder = 'Date';

export const DATE_NO_TIME_ZONE = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const getDateNoTimezone = (time: string) => {
  return new Date(moment.utc(time).format(DATE_NO_TIME_ZONE))
}