<span class="date-cell">
  <span
    class="d-block"
    [ngClass]="{ 'read-only': isReadOnly }"
    (click)="!isReadOnly && sp.show()"
    *ngIf="date && !isDueDate"
    >{{ date | dateFormat : DATE_FORMAT }}</span
  >
  <span
    class="d-block"
    [ngClass]="{ 'read-only': isReadOnly }"
    [title]="(date | dueDateItem)?.dueDateDescription"
    (click)="!isReadOnly && sp.show()"
    *ngIf="date && isDueDate"
    [innerHTML]="(date | dueDateItem)?.dueDateString"
  ></span>

  <span class="d-block no-data text-ellipsis"
    (click)="!isReadOnly && sp.show()" *ngIf="!date"
    >{{'None' | translate}}</span>

  <button
    class="btn btn-icon btn-date"
    [ngClass]="{ 'd-flex': sp.isOpen }"
    (click)="!isReadOnly && sp.show()"
    *ngIf="!date"
  >
    <span class="material-icons-outlined"> edit </span>
  </button>
  <input
    class="date-picker"
    #sp="bsDatepicker"
    bsDatepicker
    [formControl]="control"
    placement="bottom right"
  />
</span>
