<div class="badge-wrapper" *ngIf="labels?.length > 0" [title]="getLabelsText()" [tooltip]="getLabelsText()" [isDisabled]="!displayTooltip">
  <ng-container *ngIf="!editable">
    <span class="badge badge-main badge-pill {{cssClass}}" [ngStyle]="badgeStyle">
      {{labels[0]}}
    </span>

    <span class="badge badge-pill badge-light badge-suffix" *ngIf="suffix && labels?.length > 1">+{{labels.length - 1}}</span>
  </ng-container>

  <ng-container *ngIf="editable">
    <input 
      [type]="type" class="badge badge-main badge-input badge-pill {{cssClass}}"
      [ngStyle]="badgeStyle"
      [formControl]="formControl"
      (focusin)="onFocus()"
      (focusout)="onInputChange()"
      (keyup.enter)="onInputChange()">
  </ng-container>
</div>