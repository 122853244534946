export const QUERY_PARAMS = {
  task: {
    id: 'id',
    selectedTask: 'selectedTask',
    view: 'view',
  },
}

export enum QUERY_PARAMS_VALUE {
  detail = 'detail',
  modal = 'modal',
};