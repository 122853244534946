import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/core';
import { NotificationShort } from 'src/app/shared/_models/notification.model';
import { AbstractComponent } from '../../_base-component';
import { NotificationActionType } from '../notification-system';
import { LogoCompany } from 'src/app/shared/_utils';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent extends AbstractComponent {
  @Input() notification: NotificationShort;
  @Output() remove = new EventEmitter<NotificationShort>();
  @Output() markNotification = new EventEmitter<NotificationShort>();
  @Output() redirectTask = new EventEmitter<NotificationShort>();

  isShowBtnDropdown = false;
  defaultNotificationIcon = LogoCompany;
  notificationActionType = NotificationActionType;

  constructor(
    private router: Router,
  ) {
    super();
  }

  redirectToTask(notification: NotificationShort) {
    if (notification.action === NotificationActionType.ShareTaskFilter) {
      this.redirectToTasks(notification);
    }

    if (!notification.data.targetKey) {
      return;
    }
    const redirectUrl = this.canRedirectToBacklog(notification.action)
      ? this.routerObject.taskMgmtBacklog.fullPath.replace(':projectKey', notification.data.targetKey)
      : `${this.routerObject.taskMgmtTaskList.fullPath}/${notification.data.targetKey}`;

    const url = this.router.serializeUrl(
      this.router.createUrlTree([redirectUrl])
    );

    window.open(url, '_blank');
    this.redirectTask.emit(notification);
  }

  getTimeUI(date: string) {
    return UtilService.getDiffTimeFromNowUI(date);
  }

  removeNotification(notification: NotificationShort) {
    this.isShowBtnDropdown = false;
    this.remove.emit(notification);
  }

  markNotificationAsRead(notification: NotificationShort) {
    this.isShowBtnDropdown = false;
    this.markNotification.emit(notification);
  }

  onClickDropdown(event: MouseEvent) {
    this.isShowBtnDropdown = true;
    this.stopEvent(event);
  }

  isRemindDueTask(type: string) {
    return type === NotificationActionType.RemindOverDueTask;
  }

  isRemindType(type: string): boolean {
    return [NotificationActionType.RemindOverDueTask, NotificationActionType.TaskReminder].some(e => e === type);
  }

  showMessageReminder(notification: NotificationShort) {
    return notification?.data?.message && notification.action === NotificationActionType.TaskReminder;
  }

  canRedirectToBacklog(actionType: string) {
    return NotificationActionType.CloneData === actionType;
  }

  redirectToTasks(notification: NotificationShort) {
    const redirectUrl = `${this.routerObject.taskMgmtTaskList.fullPath}?filterId=${notification.data.targetId}`;
    this.markNotificationAsRead(notification);
    window.open(redirectUrl, '_blank');
  }
}
