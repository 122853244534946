export interface Metrics {
  id: number;
  name: number;
  unit: number;
}

export interface MetricsCreateRequest {
  name: number;
  unit: number;
}

export interface MetricsUpdateRequest {
  name: number;
  unit: number;
}

