
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ToastService } from 'src/app/core';
import {
  ProjectEpicModel, Metrics, pickerColors, Priority, PriorityName, Project, ProjectMemberUser, ProjectPlatform, ProjectSearchParam, Release, Sprint,
  StatusCategory,
  Task, TaskAttachmentType, TaskName, TaskPatch, TaskStatus, TaskTypeEnum, TaskUpsert, TaskUpsertFile, User,
  WorkItemLabel, WorkItemPriority, WorkItemType, ProjectShort,
} from 'src/app/shared';
import { BoardStoryline } from 'src/app/shared/_models/board-storyline.model';
import { MetricService } from '../../metric/_services/metric.service';
import { ProjectBoardEpicService, ProjectsMasterDataService } from '../../projects/_services';
import { ProjectBoardStorylineService } from '../../projects/_services/project-board-storyline.service';
import { StorageService } from '../../_services/storage.service';
import { TaskActions, selectPreviousWorkType, selectWorkItem } from '../../_store/task';
import { TaskService } from '../_services/task.service';
import { AuthService } from './../../../authentication/_services/auth.service';
import { DATE_FORMAT, DATE_UI_FORMAT } from './../../../shared/_utils/consts';
import { ProjectsService } from './../../projects/_services/projects.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-task-upsert',
  templateUrl: './task-upsert.component.html',
  styleUrls: ['./task-upsert.component.scss']
})

export class TaskUpsertComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  @Output() formSubmit = new EventEmitter<Task>();

  @Input() selectedProjectId: number;
  @Input() selectedSprintId: number;
  @Input() forceReleaseId: number;
  @Input() itemStatuses: TaskStatus[];
  @Input() isForceStatus = false;
  @Input() isForceEpic = false;
  @Input() isForceStoryline = false;
  @Input() showStoryline = false;
  @Input() hrBoardProject: Project;

  isFormSubmitClicked = false;
  isHideFields = true;
  selectedProject: Project;
  form: UntypedFormGroup;
  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: DATE_UI_FORMAT,
    customTodayClass: 'bs-datepicker-today'
  };

  selectedFiles: TaskUpsertFile[] = [];
  uploadedCount = 0;
  uploadInProgress = false;
  itemTypes: WorkItemType[];
  priorities: WorkItemPriority[];
  releases: Release[];
  labels: WorkItemLabel[];
  platforms: ProjectPlatform[] = [];
  availablePlatforms: ProjectPlatform[] = [];
  metrics: Metrics[] = [];
  TaskTypeEnum = TaskTypeEnum;

  members: ProjectMemberUser[] = [];
  users$: Observable<ProjectMemberUser[]>;
  usersInput$ = new Subject<string | null>();

  projects: ProjectShort[];
  sprints: Sprint[];
  epics: ProjectEpicModel[];
  epicInput$ = new Subject<string | null>();
  labelInput$ = new Subject<string | null>();
  versionInput$ = new Subject<string | null>();

  today = moment().format(DATE_UI_FORMAT);
  loggedUser: User;
  destroyed$ = new Subject<void>();
  previousWorkItem: WorkItemType;
  loading = false;

  storylines: BoardStoryline[] = [];
  projectTypeahead$ = new Subject<string>();
  storylineTypeahead$ = new Subject<string>();
  memberTypeahead$ = new Subject<string>();
  storylineSubscription: Subscription;
  storylineTaskSubscription: Subscription;
  metricSubscription: Subscription;
  projectSearchParams: ProjectSearchParam = {
    keyword: '',
    page: 0,
    size: 10,
    sort: '',
    types: [],
    privateFlg: null
  };
  projectSearchSubscription: Subscription;

  patchDescription: string = '';

  public get workType(): WorkItemType {
    return this.form.get('type')?.value;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private projectService: ProjectsService,
    private projectsMasterDataService: ProjectsMasterDataService,
    private storageService: StorageService,
    private authService: AuthService,
    private taskService: TaskService,
    private toast: ToastService,
    private projectBoardStorylineService: ProjectBoardStorylineService,
    private metricService: MetricService,
    private projectBoardEpicService: ProjectBoardEpicService,
    private store: Store
  ) {
  }

  async ngOnInit() {
    this.loggedUser = this.authService.getLoggedUser();
    this.createForm();
    this.initPrerequisiteData();
    this.applyPreviousWorkType();
    this.observeEvent();
  }

  ngOnDestroy() {
    this.storylineSubscription?.unsubscribe();
    this.metricSubscription?.unsubscribe();
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  applyPreviousWorkType() {
    this.store.select(selectPreviousWorkType).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (!this.task && res?.projectId === this.selectedProjectId) {
        this.previousWorkItem = res.workItemType;
      }
    });
  }

  async initPrerequisiteData() {
    if (this.hrBoardProject) {
      this.selectedProject = this.hrBoardProject;
      if (this.selectedProject) {
        this.getFormCtrl('projectId').setValue(this.selectedProject);
      }
      this.loadDropDownData();
      this.getStorylines();
    }
    else{
      await this.projectService.getFullProjects(this.projectSearchParams).toPromise().then(response => {
        this.projects = response.content;
        const cacheProject = this.projectService.getCurrentProject();
        this.selectedProject = cacheProject?.id === this.selectedProjectId ? cacheProject : null;
        if (this.selectedProject) {
          this.getFormCtrl('projectId').setValue(this.selectedProject);
        }
        this.loadDropDownData();
        this.getStorylines();
      });
    }
  }


  async loadDropDownData() {
    if (!this.selectedProjectId || this.selectedProjectId === 0) {
      return;
    }
    // TODO: NEED UPGRADE RxJs version to use the latest methods likes firstValueFrom...
    // due to toPromise has been deprecated

    // Loads data for inputting dropdownlist depends on selected type and project
    this.projectService.getProjectMembers(this.selectedProjectId)
      .subscribe(members => {
        this.members = members;
      });

    await forkJoin([
      this.projectService.getWorkItemTypes(this.selectedProjectId),
      this.projectService.getSprints(this.selectedProjectId),
      this.projectService.getWorkItemStatuses(this.selectedProjectId)
    ]).pipe(
      map(([types, sprints, statuses]) => {
        return { types, sprints, statuses };
      }))
      .toPromise().then(
        (data) => {
          this.itemTypes = (data.types || []).filter(e => e.name !== TaskTypeEnum.SubTask);
          if (!this.showStoryline) {
            this.itemTypes = this.itemTypes.filter(e => e.name !== TaskTypeEnum.Storyline);
          }
          this.sprints = data.sprints;
          if (!this.isForceStatus) {
            this.itemStatuses = data.statuses;
          }
        });


    await forkJoin([
      this.projectBoardEpicService.getListMaster({projectId: this.selectedProjectId}, {page: 0, size: 20}),
      this.projectService.getWorkItemPriorities(this.selectedProjectId),
      this.projectService.getReleases(this.selectedProjectId, '', { sort: 'createdAt,desc' }),
      this.projectService.getWorkItemLabels(this.selectedProjectId),
      this.projectsMasterDataService.getPlatFormsByProject(this.selectedProjectId),
    ]).pipe(
      map(([epics, priorities, releases, labels, platforms]) => {
        return { epics, priorities, releases, labels, platforms };
      }))
      .toPromise().then(
        (data) => {
          this.epics = data.epics as any;
          this.priorities = data.priorities;
          this.releases = data.releases;
          this.labels = data.labels;
          this.platforms = data.platforms;
          this.availablePlatforms = this.platforms;
          this.resetFormValues();
        }
      );
  }

  observeEvent() {
    this.storylineTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.getStorylines(value || '');
        });

    this.projectTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.searchProject(value || '');
        });

    this.form.get('platform').valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.getStorylines();
        }
      );

    this.form.get('storyline').valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.checkIgnoredsPlatformIds();
        }
      );

    this.memberTypeahead$
      .pipe(
        distinctUntilChanged(),
        debounceTime(500))
      .subscribe(
        (value) => {
          this.searchMember(value || '');
        });
    this.memberTypeahead$.next('');
  }

  searchMember(keyword: string = '') {
    this.projectService.getProjectMembers(this.selectedProjectId, keyword)
      .subscribe(members => {
        this.members = members;
      });
  }

  searchProject(keyword: string = '') {
    this.projectSearchParams.keyword = keyword;
    this.projectSearchSubscription?.unsubscribe();
    this.projectSearchSubscription = this.projectService.getFullProjects(this.projectSearchParams)
      .subscribe(response => {
        this.projects = response.content;
      });
  }

  createForm() {
    this.form = this.fb.group({
      type: [null, Validators.required],
      statusId: [null, this.isForceStatus ? [Validators.required] : []],
      name: [null, [Validators.required, Validators.maxLength(512)]],
      description: [null],
      dueDate: [null],
      priorityId: [null],
      assigneeId: [null],
      reporter: [null],
      ccIds: [[]],
      projectId: [null, Validators.required],
      sprintId: [null],
      timeOriginalEstimate: [null],
      storyPoint: [null],
      epicId: [null],
      labelIds: [[]],
      versions: [[]],
      privateFlg: [false],
      attachments: [[]],
      colorCode: [],
      platform: [],
      storyline: [],
      metric: [],
      metricValue: [null],
    }, {
      validator: Validators.compose([

      ])
    });

    this.initSelectedProjectIdChanged();
  }

  get taskType(): WorkItemType {
    return this.form.controls['type']?.value;
  }

  onDescriptionFileChanged(file: File, previewUrl: string) {
    this.selectedFiles.push({
      file,
      previewUrl,
      type: 'Description',
    });
    this.selectedFiles = [...this.selectedFiles];
  }

  getStorylines(keyword: string = '') {
    this.storylineSubscription?.unsubscribe();
    const currentPlatform: ProjectPlatform = this.form.get('platform').value;
    if (!currentPlatform) { return; }

    const availablePlatforms = currentPlatform ? [currentPlatform.id] : [];
    this.storylineSubscription = this.projectBoardStorylineService.searchByAvailablePlatforms(
      this.selectedProjectId, { keyword, availablePlatforms }, { page: 0, size: 50 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.storylines = res.content.filter(e => e);
        },
        (error) => {
          this.storylines = [];
        }
      );
  }

  onOpenMetricDropdown() {
    if (this.metrics.length === 0) {
      this.getMetrics();
    }
  }

  getMetrics() {
    this.metricSubscription?.unsubscribe();
    this.metricSubscription = this.metricService.search({ page: 0, size: 1000 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.metrics = res.content;
        },
        (error) => {
          this.metrics = [];
        }
      );
  }

  checkIgnoredsPlatformIds() {
    const storylineId: number = this.form.get('storyline').value?.id || this.task.storylineId;
    if (storylineId) {
      this.storylineTaskSubscription?.unsubscribe();
      this.storylineTaskSubscription = this.projectBoardStorylineService.getTasks(
        this.selectedProjectId, storylineId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            const ignoredsPlatformIds = res.map(e => e.platform?.id);
            this.availablePlatforms = this.platforms.filter(platform => ignoredsPlatformIds.findIndex(e => e === platform.id) === -1);
          },
          (error) => {
            this.availablePlatforms = this.platforms;
          }
        );
    }
  }

  onSubmit() {
    if (this.loading) {
      return;
    }

    this.isFormSubmitClicked = true;
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    if (!this.isUploadCompleted()) {
      return;
    }
    const formValue = this.form.getRawValue();
    const platform = formValue.type?.name === TaskTypeEnum.Storyline ? null : formValue.platform;
    const storyline = formValue.type?.name === TaskTypeEnum.Story ? formValue.storyline : null;
    const taskUpsertRequestModel: TaskUpsert = {
      ...formValue,
      projectId: formValue.projectId.id,
      sprintId: formValue.sprintId?.id,
      typeId: formValue.type.id,
      statusId: formValue.statusId?.id,
      priorityId: formValue.priorityId?.id,
      dueDate: formValue.dueDate ? moment(formValue.dueDate).format(DATE_FORMAT) : null,
      epicId: formValue.epicId?.id,
      reporterId: formValue.reporter?.id || null,
      colorCode: formValue.colorCode,
      versionIds: formValue.versions?.map(e => e.id) || [],
      attachments: [],
      platformId: platform?.id || null,
      storylineId: storyline?.id || null,
      metricId: formValue.metric?.id || null,
    };

    if (this.selectedFiles.findIndex(e => e.type === 'Description') !== -1) {
      taskUpsertRequestModel.description = '';
    }

    this.loading = true;
    this.taskService.add(taskUpsertRequestModel as any).subscribe(
      (task: Task) => {
        this.task = task;
        if (this.selectedFiles.length > 0) {
          this.patchDescription = this.form.get('description').value || '';
          this.handleUploadFile();
        } else {
          this.formSubmit.next(cloneDeep(task));
          this.loading = false;
        }
        this.store.dispatch(TaskActions.createTaskSuccess({ task }));
        this.store.dispatch(selectWorkItem({projectId: this.selectedProjectId , workItemType: this.workType}))
      },
      (error: string) => {
        this.loading = false;
        const httpError: HttpErrorResponse = JSON.parse(error);
        this.toast.error(httpError?.message);
      }
    );
  }

  handleUploadFile() {
    this.uploadedCount = 0;
    this.selectedFiles.forEach((file, index) => {
      this.uploadFile(file.file, file.type, file.previewUrl);
    });
  }

  uploadFile(file: File, type: TaskAttachmentType, previewUrl: string) {
    this.taskService.addAttachments(this.task.id, [file], type)
      .pipe(takeUntil(this.destroyed$)).subscribe(
        event => {
          if (event instanceof HttpResponse) {
            const attachments = event.body;
            const obj = attachments[0];
            this.uploadedCount++;

            if (type === 'Description') {
              let description: string = this.patchDescription;
              if (file.type.includes('image')) {
                description = description.replace(previewUrl, obj.url);
              } else {
                const replaceValue = `assets/img/editor-coment-file.svg?file-url=${obj.url}`;
                description = description.replace(previewUrl, replaceValue);
              }
              this.patchDescription = description;
            }
            this.handleSaveDescription();
          }
        },
        (error: string) => {
          this.uploadedCount++;
          this.handleSaveDescription();
        });
  }

  handleSaveDescription() {
    if (this.uploadedCount === this.selectedFiles.length) {
      if (this.selectedFiles.findIndex(e => e.type === 'Description') !== -1) {
        const payload: TaskPatch = {
          type: 'description',
          value: this.patchDescription,
        };
        this.patchValue(payload);
      } else {
        this.formSubmit.next(this.task);
        this.loading = false;
      }
    }
  }

  patchValue(payload: TaskPatch) {
    this.taskService.patch(this.task.id, payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (task) => {
          this.task = task;
          this.formSubmit.next(this.task);
          this.loading = false;
        },
        (error: string) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        }
      );
  }

  onCancel() {
    this.formSubmit.next(null);
  }

  onFilesDropped(fileList: FileList) {
    if (!fileList || fileList.length === 0) {
      return;
    }
    const files = Array.from(fileList);
    files.forEach(file => {
      this.selectedFiles.push({
        file,
        type: 'Other',
      });
    });
    this.selectedFiles = [...this.selectedFiles];
  }

  initSelectedProjectIdChanged() {
    const projectIdCtrl = this.getFormCtrl('projectId');
    projectIdCtrl.valueChanges.subscribe(
      (project: Project) => {
        if (project) {
          this.selectedProjectId = project.id;
          this.selectedProject = project;
          this.getFormCtrl('sprintId').setValue(null);
          this.loadDropDownData();
        }

      }
    );
    if (this.selectedProject) {
      projectIdCtrl.setValue(this.selectedProject);
    }
  }

  private setFormDefaultValues() {
    const epicType = this.itemTypes.find(t => t.name === TaskTypeEnum.Epic);
    const storylineType = this.itemTypes.find(t => t.name === TaskTypeEnum.Storyline);
    const defaultType = this.itemTypes.find(t => t.name === TaskTypeEnum.Task);

    if (this.isForceEpic && epicType) {
      this.getFormCtrl('type').setValue(epicType);
      this.getFormCtrl('type').disable();
      this.getFormCtrl('colorCode').setValue(pickerColors[0]);
    } else if (this.isForceStoryline && storylineType) {
      this.getFormCtrl('type').setValue(storylineType);
      this.getFormCtrl('type').disable();
      this.getFormCtrl('storyPoint').disable();
      this.getFormCtrl('colorCode').setValue('#172b4d');
    } else if (defaultType) {
      this.getFormCtrl('type').setValue(this.previousWorkItem || defaultType);
    }

    const defaultSprint = this.sprints.find(s => s.id === this.selectedSprintId);
    if (defaultSprint) {
      this.getFormCtrl('sprintId').setValue(defaultSprint);
    }

    const forceRelease = this.releases.find(e => e.id === this.forceReleaseId);
    if (forceRelease) {
      this.getFormCtrl('versions').setValue([forceRelease]);
      this.getFormCtrl('versions').disable();
    }

    const defaultStatus = this.itemStatuses.find(t => t.statusCategory === StatusCategory.OPEN && t.defaultFlg || t.name === TaskName.ToDo);
    if (defaultStatus) {
      this.getFormCtrl('statusId').setValue(defaultStatus);
    }

    const defaultPriority = this.priorities.find(p => p.code === Priority.MEDIUM || p.name === PriorityName.Medium);
    if (defaultPriority) {
      this.getFormCtrl('priorityId').setValue(defaultPriority);
    }

    if (this.loggedUser) {
      this.getFormCtrl('reporter').setValue(this.loggedUser);
    }

    this.getFormCtrl('assigneeId').setValue(null);
    this.getFormCtrl('ccIds').setValue(null);
  }

  private resetFormValues() {
    this.getFormCtrl('type').setValue(null);
    this.getFormCtrl('sprintId').setValue(null);
    this.getFormCtrl('priorityId').setValue(null);

    this.getFormCtrl('assigneeId').setValue(null);
    this.getFormCtrl('reporter').setValue(null);
    this.getFormCtrl('ccIds').setValue(null);

    this.getFormCtrl('epicId').setValue(null);
    this.getFormCtrl('labelIds').setValue(null);
    this.getFormCtrl('versions').setValue(null);
    this.getFormCtrl('platform').setValue(null);

    this.setFormDefaultValues();
  }

  private getFormCtrl(controlName: string): UntypedFormControl {
    return this.form.controls[controlName] as UntypedFormControl;
  }

  isUploadCompleted(): boolean {
    return !this.uploadInProgress;
  }

  isRequiredPlatform() {
    return this.taskType?.name === TaskTypeEnum.Story && this.form.get('storyline').value;
  }

  canSetPlatform() {
    return !(this.form.get('type').value?.name === TaskTypeEnum.Storyline || this.form.get('type').value?.name === TaskTypeEnum.Epic);
  }

  clearablePlatform() {
    return !this.form.get('storyline').value;
  }


}
