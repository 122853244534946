import { UserGenderValue, UserMaritalValue, UserStatusKey } from '../_enums';

export const UserMaritalStatus = [
  {
    label: 'Married',
    value: UserMaritalValue.MARRIED
  },
  {
    label: 'Single',
    value: UserMaritalValue.SINGLE
  }
];

export const UserGenders = [
  {
    label: 'Male',
    value: UserGenderValue.MALE
  },
  {
    label: 'Female',
    value: UserGenderValue.FEMALE
  }
];

export const UserStatus = [
  {
    name: 'Active',
    value: UserStatusKey.ACTIVE,
  },
  {
    name: 'Inactive',
    value: UserStatusKey.INACTIVE,
  },
  {
    name: 'All',
    value: null,
  }
];

export const UnassignedId = 0;
