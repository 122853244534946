import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { MatMenuModule } from '@angular/material/menu';

const NG_MATERIAL = [
  DragDropModule,
  MatIconModule,
  MatMenuModule,
  OverlayModule,
  CdkScrollableModule
];

@NgModule({
  imports: [...NG_MATERIAL],
  exports: [...NG_MATERIAL],
})
export class NgMaterialModule {}
