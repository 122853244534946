import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { Group, GroupRequest, List2Res, PageInfo, Pageable, Team, User } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class GroupService extends EntityCollectionServiceBase<Group> {
  public page$: Observable<PageInfo> = new Observable();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient
    ) {
    super('groups', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }

  getGroups(query: GroupRequest , pageable: Pageable) {
    const params = queryString.stringify({ ...query, ...pageable});
    return this.http.get<List2Res<Group>>(`${environment.apiUrl}/groups/?${params}`);
  }
}
