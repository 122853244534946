import { Pipe, PipeTransform } from '@angular/core';
import { ProgressGroupItem } from '../_components/progress-group/progress-group.component';

@Pipe({
  name: 'progressGroupColor'
})

export class ProgressGroupColorPipe implements PipeTransform {
  transform(item: ProgressGroupItem, index: number, total: number, items: ProgressGroupItem[]): any {
    return {
      'width': `calc(${item.count / total * 100}% + 7px)`,
      'background-color': item.color || '',
      'z-index': items.length - index
    };
  }
}