import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-cell-text-editable',
  templateUrl: './cell-text-editable.component.html',
  styleUrls: ['./cell-text-editable.component.scss']
})
export class CellTextEditableComponent implements OnInit, OnChanges {
  @ViewChild('nameInput') nameInput: ElementRef;

  @Input() text = '';
  @Input() canEdit = true;

  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
  isEditMode = false;

  textValue = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      this.textValue = this.text;
    }
  }

  showEditMode() {
    this.isEditMode = true;
    setTimeout(() => {
      this.nameInput?.nativeElement?.focus();
    }, 100);
  }

  cancel() {
    this.isEditMode = false;
    this.textValue = this.text;
  }

  save() {
    if (!this.textValue) {
      return;
    }
    this.text = this.textValue;
    this.isEditMode = false;
    this.textChange.emit(this.text);
  }

}
