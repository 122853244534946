import { OkrWorkspace } from './okr-workspace.model';

export interface OkrTimeline {
  id: number;
  title: string;
  name: string;
  startDate: string;
  endDate: string;
  deleted: boolean;
  workspace: OkrWorkspace;
}

export interface OkrTimelineCreateRequest {
  title: string;
  startDate: string;
  endDate: string;
  workspaceId: number;
}

export interface OkrTimelineUpdateRequest {
  title: string;
  startDate: string;
  endDate: string;
}

export interface OkrTimelineSearchRequest {
  workspaceId?: number;
  year?: number;
}

export interface OkrTimelinePeriod {
  key: string;
  start: string;
  end: string;
  year: number;
}

