import { BoardReleaseDetail, PermissionCache, Permisson, Project, RolePermission, User } from 'src/app/shared';

export interface SiteManagementState {
  loggedUser: User;
  currentProject: Project;
  currentRelease: BoardReleaseDetail;
  searchText: string;
  waitingRequest: number;
  permissions: Permisson[];
  rolePermissions: RolePermission[];
}
