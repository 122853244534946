import { Injectable } from '@angular/core';
import { jiraMarkupToHTML } from './jiramark';
import { closingTags } from './jira-mark.const';
@Injectable({
  providedIn: 'root'
})
export class JiraMarkService {
  closingTags = closingTags;

  constructor() { } 

  isContainHtml(html: string): boolean {
    const regexString = this.closingTags.map(tag => tag.replace(/<\/(\w+)>/, '\\<$1\\>')).join('|');
    const regex = new RegExp(regexString);

    return regex.test(html);
  }

  parse(value: string, option?: any) {
    if (value && !this.isContainHtml(value)) {
      return jiraMarkupToHTML(value, option);
    }
    return value;
  }
}
