import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-switch',
  templateUrl: './formly-switch.component.html',
  styleUrls: ['./formly-switch.component.scss']
})
export class SwitchType extends FieldType<FieldTypeConfig> {
  constructor() {
    super();
  }

  onActive(event: Event) {
    event.stopPropagation();
    this.formControl.setValue(!this.formControl.value);
  }
}
