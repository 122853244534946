export interface AccountLink {
  id: string;
  productName: string;
  domain: string;
  avatar: string;
}

export interface GitAccount {
  id: number;
  uuid: string;
  name: string;
  username: string;
  avatar: string;
  url: string;
  email: string;
  platform: string;
}

export interface GitAccountCompany {
  id: number;
  gitAccount: GitAccount;
  connected: boolean;
}

export interface GitAccountSearchRequest {
  keyword?: string;
}
