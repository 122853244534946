<div class="cell-text-editable d-flex align-items-center text-ellipsis">
  <div class="text-name text-ellipsis" [title]="text" *ngIf="!isEditMode">{{text}}</div>

  <input *ngIf="isEditMode" #nameInput type="text" stopEvent class="text-ellipsis edit-name-input"
    [ngClass]="textValue ? '' : 'ng-invalid ng-touched'" [(ngModel)]="textValue" (keyup.enter)="save()"
    (keyup.escape)="cancel()">

  <ng-container *ngIf="isEditMode">
    <button class="btn btn-icon btn-light btn-cancel ml-2" stopEvent (click)="cancel()" [title]="'Cancel' | translate">
      <span class="material-icons-outlined">
        close
      </span>
    </button>

    <button class="btn btn-icon btn-light btn-save ml-2" stopEvent (click)="save()" [title]="'Save' | translate">
      <span class="material-icons-outlined">
        done
      </span>
    </button>
  </ng-container>

  <button class="btn btn-icon btn-light btn-edit-name" stopEvent (click)="showEditMode()" [title]="'Edit' | translate"
    *ngIf="!isEditMode && canEdit">
    <span class="material-icons-outlined">
      edit
    </span>
  </button>
</div>
