// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mainDomain: 'dev.xcorp.app',
  clientUrl: 'https://dev.xcorp.app',
  apiUrl: 'https://api-dev.xperc.com',
  s3Url: 'https://dev-upload-xcorp.s3.ap-southeast-1.amazonaws.com',
  googleClientId: '749290038722-drtkpkomut76r44js9ap4ctika47m6la.apps.googleusercontent.com',
  stripeKey: 'pk_test_51KtUFRDH7WUIgCA05I9IlhZD8brbzae5bsbA9TRdJ4kUdVgpOan7h8Lhw0nDHr5IjiPPwgO3chI68a0UwK17urXc00uSNlyH5h',
  env: 'dev',
  version: '1.2.7'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
