import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxLoadingModule } from 'ngx-loading';
import { GoogleChartsModule } from 'angular-google-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgToggleModule } from 'ng-toggle-button';
import { QuillModule } from 'ngx-quill';
import { AngularSplitModule } from 'angular-split';
import { ClipboardModule } from 'ngx-clipboard';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AngularResizeEventModule } from 'angular-resize-event';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxMaskModule } from './ngx-mask/ngx-mask.module';
import { NgxBootstrapModule } from './ngx-boostrap/ngx-boostrap.module';
import { NgMaterialModule } from './ng-material/ng-material.module';
import { NgAntDesignModule } from './ng-ant-design/ng-ant-design.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FormlyModule } from '@ngx-formly/core';
import { JiraMarkModule } from 'libs/jira-mark/src/public-api';

const LIBS = [
  NgSelectModule,
  NgxDatatableModule,
  ColorPickerModule,
  ImageCropperModule,
  NgxLoadingModule,
  GoogleChartsModule,
  InfiniteScrollModule,
  NgToggleModule,
  QuillModule,
  AngularSplitModule,
  ClipboardModule,
  PdfViewerModule,
  AngularResizeEventModule,
  NgxStripeModule,
  NgxSkeletonLoaderModule,
  TranslateModule,
  NgCircleProgressModule,
  EmojiModule,
  PickerModule,
  NgxMaskModule,
  NgxBootstrapModule,
  NgMaterialModule,
  NgAntDesignModule,
  FullCalendarModule,
  FormlyModule,
  JiraMarkModule
];

@NgModule({
  imports: [...LIBS],
  exports: [...LIBS],
  providers: []
})
export class LibsModule {}
