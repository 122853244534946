import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as colorFn from 'src/app/shared/_utils/color';

@Pipe({ name: 'color' })

export class ColorPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(hex: string) {
    return colorFn.getColor(hex);
  }
}
