import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { delay, delayWhen, forkJoin, MonoTypeOperatorFunction, Observable, of, pipe, retry, retryWhen, take, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { PermissionCache, PermissionCacheRequest, PermissionCodeCache, Permisson, ROLE_PERMISSION_CACHE_KEY,
  RolePermission, USER_PERMISSIONS_KEY, USER_ROLE_PERMISSIONS_KEY, updateFormatByLanguage } from 'src/app/shared';
import { CustomerService } from 'src/app/site-management/customer/_services/customer.service';
import { DynamicFieldDataService } from 'src/app/site-management/dynamic-field/_services/dynamic-field-data.service';
import { DynamicFieldService } from 'src/app/site-management/dynamic-field/_services/dynamic-field-service';
import { PermissionService } from 'src/app/site-management/role-permission/_services/permisson.service';
import { UserService } from 'src/app/site-management/user/_services/user.service';
import { I18nService } from 'src/app/site-management/_services';
import { SiteManagementState, updatePermissions, updateRolePermissions } from 'src/app/site-management/_store/site-management';
import { Store } from '@ngrx/store';
import { Logger } from '../models/logger';
import { throwError } from 'rxjs/internal/observable/throwError';
import { loading } from '../store/app';

@Injectable({
  providedIn: 'root'
})
export class SiteManagementGuardService  {
  isLoaded = false;
  maxRetryCount = 5;
  retryInterval = 1000;

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private authService: AuthService,
    private userService: UserService,
    private customService: CustomerService,
    private dynamicFieldService: DynamicFieldService,
    private dynamicFieldDataService: DynamicFieldDataService,
    private i18nService: I18nService,
    private store: Store<SiteManagementState>,
  ) { }

  catchApiError<T>(apiName: string): MonoTypeOperatorFunction<T> {
    return pipe(
      catchError((err) => {
        return throwError(() => Logger.debug('Site Management Guard', apiName, { err }));
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const languageCode = this.i18nService.getCurrentLang();
    updateFormatByLanguage(languageCode);

    if (this.isLoaded) {
      return true;
    }

    return forkJoin([
      this.getRolePermissions().pipe(this.catchApiError('getRolePermissions')),
      this.userService.getProfile().pipe(this.catchApiError('userService.getProfile()')),
      this.dynamicFieldService.getAll().pipe(this.catchApiError('dynamicFieldService.getAll()')),
      this.customService.getSystemAdmin().pipe(this.catchApiError('customService.getSystemAdmin()')),
    ]).pipe(
      retryWhen((errors) =>
      errors.pipe(
        switchMap((error, index) => {
          this.store.dispatch(loading({ visible: true }));
          if (index < this.maxRetryCount - 1) {
            return of(error).pipe(delay(this.retryInterval));
          }
          return throwError(() => error); // Throw an error to stop retrying
        }),
        take(this.maxRetryCount)
      )
    ),
      map(([permissionObject, user, fields, systemAdmin]) => {
        this.isLoaded = true;
        this.store.dispatch(updateRolePermissions({ data: permissionObject.rolePermissions }));
        this.store.dispatch(updatePermissions({ data: permissionObject.permissions }));
        this.permissionService.permissions = permissionObject.permissions;
        this.authService.setLoggedUser(user);
        this.authService.setSystemAdminFlg(systemAdmin);
        this.dynamicFieldDataService.storeFields(fields);
        this.store.dispatch(loading({ visible: false }));
        return true;
      }),
      catchError((err) => {
        this.store.dispatch(loading({ visible: false }));
        this.router.navigate(['/error'], {queryParams: {relativeUrl: location.href}})
        return of(false);
      })
    );
  }

  getRolePermissions(): Observable<{rolePermissions: RolePermission[], permissions: Permisson[]}> {
    const data: PermissionCacheRequest = {
      codes: [PermissionCodeCache.PERMISSION, PermissionCodeCache.ROLE_PERMISSION]
    };

    return this.permissionService.getCache(data).pipe(
      switchMap((caches) => {
        const clientCaches = JSON.parse(localStorage.getItem(ROLE_PERMISSION_CACHE_KEY)) ?? [];
        const clientPermissions = JSON.parse(localStorage.getItem(USER_PERMISSIONS_KEY)) || [];
        const clientRolePermissions = JSON.parse(localStorage.getItem(USER_ROLE_PERMISSIONS_KEY)) ?? [];

        localStorage.setItem(ROLE_PERMISSION_CACHE_KEY, JSON.stringify(caches));

        const clientPermissionCache = this.getCacheByCode(clientCaches, PermissionCodeCache.PERMISSION);
        const clientRolePermissionCache = this.getCacheByCode(clientCaches, PermissionCodeCache.ROLE_PERMISSION);

        const serverPermissionCache = this.getCacheByCode(caches, PermissionCodeCache.PERMISSION);
        const serverRolePermissionCache = this.getCacheByCode(caches, PermissionCodeCache.ROLE_PERMISSION);

        const isPermissionChanged = clientPermissionCache?.version !== serverPermissionCache?.version;
        const isRolePermissionChanged = clientRolePermissionCache?.version !== serverRolePermissionCache?.version;

        return forkJoin([
          isRolePermissionChanged ? this.permissionService.getRolePermission() : of(clientRolePermissions),
          isPermissionChanged ? this.permissionService.getAll() : of(clientPermissions)
        ]).pipe(map(([rolePermissions, permissions]) => ({ rolePermissions, permissions })));
      })
    );
  }

  getCacheByCode(caches: PermissionCache[], code: PermissionCodeCache): PermissionCache {
    return caches.find(cache => cache?.code === code);
  }
}
