import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() type: 'text' | 'number' = 'text';
  @Input() placeholder = '';
  @Input() className = 'form-control';
  @Input() align: 'left'  | 'center' | 'right' = 'left';
  @Input() value: string | number = null;
  @Input() disabled = false;
  @Input() focus = false;
  @Input() isBlurEnter = true;
  @Input() debounce = false;
  @Input() debounceTime = 500;
  @Input() allowNegativeNumbers = true;
  @Output() onSubmit: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() onFocus: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() keywordChange = new EventEmitter<string>();
  @Output() keywordDebounceChange = new EventEmitter<string>();
  @Output() onKeydownEvent = new EventEmitter<KeyboardEvent>();
  @ViewChild('input') input: ElementRef;
  private inputSub!: Subscription;

  constructor() { }

  ngOnInit(): void {
    if (!this.control) {
      this.control = new UntypedFormControl(this.value);
    }

    if (this.focus) {
      setTimeout(() => {
        if (this.input && this.input.nativeElement) {
          this.input.nativeElement.focus();
        }
      }, 100);

    }

    if (this.debounce) {
      this.setupDebounce();
    }

    if (this.disabled) {
      this.control.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.control.value !== changes.value?.currentValue) {
      this.control.setValue(changes.value.currentValue);
    }

    if (changes.disabled) {
      if (this.disabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  onFocusout(){
    this.submit();
    this.onBlur.emit();
  }

  submit(input?: any) {
    if(this.isBlurEnter) {
      setTimeout(() => {
        input?.blur?.();
      }, 0);
    }

    if (this.control.value !== this.value && this.control.valid) {
      this.value = this.control.value;
      this.onSubmit.emit(this.value);
    }
  }

  onFocusInput() {
    this.onFocus.emit(this.input);
  }

  onInput() {
    this.keywordChange.emit(this.control.value);
  }

  setupDebounce() {
    this.inputSub = this.keywordChange
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe(() => {
        this.keywordDebounceChange.emit(this.control.value);
      });
  }

  ngOnDestroy() {
    this.inputSub?.unsubscribe();
  }

  onkeydown(event: KeyboardEvent) {
    this.onKeydownEvent.emit(event);
  }
}
