<ng-select
  class="form-control"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [ngClass]="{ 'is-invalid': showError }"
  [items]="props?.options | optionWithObservable | async"
  [bindLabel]="props.bindLabel"
  [bindValue]="props.bindValue"
  [clearable]="props?.clearable"
  [searchable]="props?.searchable"
  [placeholder]="field.props?.placeholder"
  [multiple]="props?.multiple"
>
<!-- TODO: Custom multi template for options -->
</ng-select>
