import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_MONTH_NAME_DAY } from 'src/app/shared';

@Pipe({ name: 'dueDateItem' })
export class DueDateItem implements PipeTransform {
  transform(date: string | Date, format: string = DATE_FORMAT): { dueDateString: string, dueDateDescription: string } {
    if (!date) {
      return { dueDateString: '', dueDateDescription: ''};
    }
    const dueDate = moment(date, format);
    const today = moment(moment().format(format));
    const diffDate = today.diff(dueDate, 'days');
    if (diffDate === 0) {
      return {
        dueDateString: '<span class="text-warning due-date-label">Today</span>',
        dueDateDescription: 'Due Today'
      };
    } else if (diffDate > 0) {
      return {
        dueDateString: `<span class="text-danger due-date-label">${diffDate}d</span>`,
        dueDateDescription: `Late ${diffDate + (diffDate > 1 ? ' days' : 'day')}`
      };
    } else {
      const text = moment(date).format(DATE_FORMAT_MONTH_NAME_DAY);
      return {
        dueDateString: `<span class="text-dodgerblue due-date-label">${text}</span>`,
        dueDateDescription: `Due ${text}`
      };
    }
  }
}
