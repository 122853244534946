import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss']
})
export class ButtonDropdownComponent implements OnInit {
  @Input() icon = 'more_horiz';
  @Input() transparent = true;
  @Input() isSetWidth  = true;

  constructor() { }

  ngOnInit(): void {
  }
}
