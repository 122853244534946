import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap, tap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { CURRENT_PROJECT_KEY } from 'src/app/shared/_utils';
import { LeaveRequestService } from '../../leave-management/_services/leave-request.service';
import { SiteActions } from './site-management.actions';
import { ProjectState } from '../project';
import { Store } from '@ngrx/store';
import { resetProjectState } from '../project/project.actions';
import { PermissionService } from '../../role-permission/_services/permisson.service';
import { SiteManagementSelectors } from './site-management.selectors';


@Injectable()
export class SiteManagementEffects {

  constructor(
    private actions$: Actions,
    private leaveRequestService: LeaveRequestService,
    private authService: AuthService,
    private projectStore: Store<ProjectState>,
    private permissionService: PermissionService,
    private store: Store
  ) { }

  updateLoggedUser$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(SiteActions.updateLoggedUser),
        tap((action) => {
          const user = action.data;
          this.authService.setLoggedUser(JSON.parse(JSON.stringify(user)));
        }),
      ),
    { dispatch: false }
  );

  updateCurrentProject$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(SiteActions.updateCurrentProject),
        tap((action) => {
          const userId = this.authService.getLoggedUser()?.id || '';
          const project = action.data;

          this.projectStore.dispatch(resetProjectState());
          localStorage.setItem(CURRENT_PROJECT_KEY + userId, JSON.stringify(project));
        }),
      ),
    { dispatch: false }
  );

  search$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(SiteActions.search),
        switchMap(() => {
          return of(SiteActions.searchSuccess({searchText: null}));
        })
      )
  );

  loadWaitingApproveRequestNumber$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(SiteActions.loadWaitingRequestNumber),
        switchMap((action) =>
          this.leaveRequestService.countWaitingApprovalRequest(action.data).pipe(
            map(data => SiteActions.loadWaitingRequestNumberSuccess({ data })),
            catchError(error => {
              return of(SiteActions.loadWaitingRequestNumberSuccess({ data: 0 }));
            })
          )
        )
      )
  );
}
