import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { permissionCode, Project, Role, User, userRoles, USER_ROLE_PERMISSIONS_KEY } from 'src/app/shared';
import { PermissionSettingItem, Permisson, RolePermission, RolePermissionItem } from 'src/app/shared/_models/permission.model';
import { ProjectsService } from '../../projects/_services';
import { selectCurrentProject, SiteManagementState } from '../../_store/site-management';
import { PermissionService } from './permisson.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionCheckService {
  rolePermissions: RolePermission[];
  permissions: Permisson[] = [];
  mappedRPs: {
    role: Role;
    permissons: PermissionSettingItem[];
  }[] = [];
  modulePermissions: PermissionSettingItem[] = [];
  currentProject: Project;
  loggedUser: User;
  isProjectLeader: boolean;
  isProjectSA: boolean;
  isSuperAdmin: boolean;
  projectRole: Role;
  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
    private projectsService: ProjectsService,
    private store: Store<SiteManagementState>,
  ) {
    this.currentProject = this.projectsService.getCurrentProject();
    this.permissions = this.permissionService.permissions;

    this.buildModulePermissions();
    this.buildUserPermission();

    this.store.select(selectCurrentProject)
      .subscribe(
        (project) => {
          if (project) {
            this.currentProject = project;
            this.isProjectLeader = this.loggedUser?.id === this.currentProject?.leader?.member?.id;
          }
        }
      );
  }

  buildModulePermissions() {
    this.modulePermissions = this.permissions.filter(e => !e.parentId).map(e => {
      const it: PermissionSettingItem = {
        ...e,
        isChecked: false
      };
      this.getChilds(e.id, it);
      return it;
    });
  }

  getChilds(parentId: number, item: PermissionSettingItem) {
    const permissions: PermissionSettingItem[] = this.permissions
      .filter(e => e.parentId === parentId)
      .map(e => {
        const it: PermissionSettingItem = {
          ...e,
          isChecked: false
        };
        if (e.id) {
          this.getChilds(e.id, it);
        }
        return it;
      });

    if (permissions.length > 0) {
      item.permissions = permissions;
      item.isCollapsed = true;
    }
  }

  buildUserPermission() {
    this.rolePermissions = JSON.parse(localStorage.getItem(USER_ROLE_PERMISSIONS_KEY));
    this.loggedUser = this.authService.getLoggedUser();

    if (this.loggedUser?.userRoles?.findIndex(e => e.roleCode === userRoles.superAdmin) !== -1) {
      this.isSuperAdmin = true;
    } else {
      this.isSuperAdmin = false;
    }

    this.mappedRPs = this.rolePermissions.map(rp => {
      const settings: PermissionSettingItem[] = JSON.parse(JSON.stringify(this.modulePermissions));
      this.updateCheckedFlag(rp, settings);
      const flattenedPermissons = this.flattern(settings);

      return {
        role: rp.role,
        permissons: flattenedPermissons,
      };
    });

    this.isProjectSA = this.mappedRPs.filter(rp => this.loggedUser.userRoles.some(r => r.id === rp.role.id))
      .map(rp => rp.permissons?.find(e => e.code === permissionCode.TASK_MANAGEMENT_PROJECTS_EDIT_ALL_PROJECTS))
      .filter(e => e)
      .some(e => e.isChecked);
  }

  updateCheckedFlag(rp: RolePermission, array: PermissionSettingItem[] = []) {
    array.forEach((item: PermissionSettingItem) => {
      if (item.permissions) {
        this.updateCheckedFlag(rp, item.permissions);
        item.isChecked = item.permissions.some(i => i.isChecked === true);
      } else {
        item.isChecked = this.isChecked(rp, item.code);
      }
    });
  }

  flattern(array: PermissionSettingItem[] = []): PermissionSettingItem[] {
    const childs = [];
    for (const item of array) {
      childs.push(item);
      if (item.permissions) {
        childs.push(...this.flattern(item.permissions));
      }
    }
    return childs;
  }

  isChecked(rp: RolePermission, code: string = ''): boolean { // check from nested array
    // if (!this.loggedUserPermission && !this.isSuperAdmin) { // If not SuperAdmin and if current use role not found in rolePermissions.
    //   return false;
    // }

    // if (this.isSuperAdmin) {
    //   return true;
    // }
    const permission: RolePermissionItem = rp.permissions.find(e => e.permission === code);
    return permission ? permission.activeFlg : true; // Set true if permission not found.
  }

  hasPermission(code: string = ''): boolean { // check from flatterned array
    // if (!this.loggedUserPermission && !this.isSuperAdmin) { // If not SuperAdmin and if current use role not found in rolePermissions.
    //   return false;
    // }

    if (this.isSuperAdmin) {
      return true;
    }

    const permissions = this.mappedRPs
      .filter(mappedRP => this.loggedUser?.userRoles.some(role => role.id === mappedRP.role.id))
      .map(mappedRP => {
        return mappedRP.permissons.find(permission => permission.code === code);
      })
      .filter(e => e);
    return permissions.length > 0 ? permissions.some(e => e.isChecked) : true; // Set true if permission not found.
  }

  hasProjectPermission(code: string = '') {
    if (this.isSuperAdmin || this.isProjectLeader || this.isProjectSA) {
      return true;
    }

    if (!this.projectRole) {
      return this.hasPermission(code);
    }

    const permission = this.mappedRPs.find(e => e.role.id === this.projectRole?.id)?.permissons?.find(e => e.code === code);
    return permission ? permission.isChecked : true; // Set true if permission not found.
  }

  getVisibility(codes: string[] = []) {
    const visibility: { [key: string]: boolean } = {};
    for (const code of codes) {
      // visibility[code] = isProjectPermission ? this.hasProjectPermission(code) : this.hasPermission(code);
      visibility[code] = this.hasPermission(code);
    }

    return visibility;
  }

  hasPermissionSystemAdmin() {
    return this.authService.getSystemAdminFlg();
  }
}
