import { createAction, props } from '@ngrx/store';
import {
  BoardReleaseDetail,
  LeaveRequestSearch,
  PermissionCache,
  PermissionCacheRequest,
  Permisson,
  Project,
  RolePermission,
  User
} from 'src/app/shared';

export const updateLoggedUser = createAction(
  '[SiteManagement] Update Logged User',
  props<{ data: User }>()
);

export const updateCurrentProject = createAction(
  '[SiteManagement] Update Current Project',
  props<{ data: Project }>()
);

export const search = createAction(
  '[SiteManagement] Search',
  props<{ searchText: string }>()
);

export const searchSuccess = createAction(
  '[SiteManagement] Search Success',
  props<{ searchText: string }>()
);

export const loadWaitingRequestNumber = createAction(
  '[Dashboard] Load Waiting Request Number',
  props<{ data: LeaveRequestSearch }>()
);

export const loadWaitingRequestNumberSuccess = createAction(
  '[SiteManagement] Load Waiting Request Number Success',
  props<{ data: number }>()
);

export const updateCurrentRelease = createAction(
  '[SiteManagement] Update Current Release',
  props<{ data: BoardReleaseDetail }>()
);

export const updatePermissions = createAction(
  '[SiteManagement] Update Permissions',
  props<{ data: Permisson[] }>()
);

export const updateRolePermissions = createAction(
  '[SiteManagement] Update Role Permissions',
  props<{ data: RolePermission[] }>()
);

export const SiteActions = {
  updateLoggedUser,
  updateCurrentProject,
  search,
  searchSuccess,
  loadWaitingRequestNumber,
  loadWaitingRequestNumberSuccess,
  updateCurrentRelease,
  updatePermissions,
  updateRolePermissions,
};
