import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UtilService } from 'src/app/core';
import { ScreenView } from '../../_utils';
import { DATE_UI_FORMAT } from '../../_utils/consts';
import { SwitchDatePeriod } from '../switch-date/switch-date.component';
import * as moment from 'moment';

export interface DatesFilter {
  fromDate: Date;
  toDate: Date;
}

@Component({
  selector: 'app-switch-dates',
  templateUrl: './switch-dates.component.html',
  styleUrls: ['./switch-dates.component.scss']
})
export class SwitchDatesComponent implements OnInit, OnChanges {
  @Input() dates: DatesFilter = {
    fromDate: new Date(),
    toDate: new Date()
  };
  @Input() period: SwitchDatePeriod = SwitchDatePeriod.month;
  @Input() showThisPeriodBtn = true;

  @Output() datesChange = new EventEmitter<DatesFilter>();
  datesUI = [this.dates.fromDate, this.dates.toDate];
  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: DATE_UI_FORMAT,
    rangeInputFormat: DATE_UI_FORMAT,
  };
  screenView: ScreenView = ScreenView.large;
  screenSize = ScreenView;
  firstAndlast = UtilService.getFirstDayAndLastDayOfMonth(new Date());
  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.datesChange
      .subscribe(data => {
        this.dates = data;
      });
    this.checkWindowSize();
  }

  checkWindowSize() {
    this.breakpointObserver.observe(['(min-width: 768px)']).subscribe(result => {
      let temp = result.matches ? ScreenView.large : ScreenView.small;
      if (this.screenView !== temp) {
        this.screenView = temp;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.datesUI = [this.dates.fromDate, this.dates.toDate];
  }

  changeToThisPeriod(trigger = false) {
  
    if (this.period === SwitchDatePeriod.month) {
      const firstAndLastOfMonth = UtilService.getFirstDayAndLastDayOfMonth(new Date());
      this.firstAndlast.firstDay = firstAndLastOfMonth.firstDay;
      this.firstAndlast.lastDay = firstAndLastOfMonth.lastDay;
      this.datesUI = [this.firstAndlast.firstDay.toDate(), this.firstAndlast.lastDay.toDate()];
    }

    if (this.period === SwitchDatePeriod.day) {
      this.firstAndlast.firstDay = moment(new Date());
      this.firstAndlast.lastDay = moment(new Date());
      this.datesUI = [new Date(), new Date()];
    }

    if (this.period === SwitchDatePeriod.week) {
      const firstAndLastOfWeek = UtilService.getFirstDayAndLastDayOfWeek(new Date());
      this.firstAndlast.firstDay = firstAndLastOfWeek.firstDay;
      this.firstAndlast.lastDay = firstAndLastOfWeek.lastDay;
      this.datesUI = [firstAndLastOfWeek.firstDay.add(1, 'd').toDate(), firstAndLastOfWeek.lastDay.add(1, 'd').toDate()];
    }

    if (this.period === SwitchDatePeriod.year) {
      const firstAndLastOfYear = UtilService.getFirstDayAndLastDayOfYear(new Date());
      this.firstAndlast.firstDay = firstAndLastOfYear.firstDay;
      this.firstAndlast.lastDay = firstAndLastOfYear.lastDay;
      this.datesUI = [firstAndLastOfYear.firstDay.toDate(), firstAndLastOfYear.lastDay.toDate()];
    }

    if(trigger) {
      this.changeDates([this.datesUI[0], this.datesUI[1]])
    }
  }

  changeDates(data: [Date, Date]) {
    if (!data) { return; }

    if (data[0].getTime() !== this.dates.fromDate.getTime()
      || data[1].getTime() !== this.dates.toDate.getTime()) {
      this.datesChange.next({
        fromDate: data[0],
        toDate: data[1]
      });
    }
  }

  changeDateStep(step: -1 | 1) {
    let duration = this.dates.toDate.getTime() - this.dates.fromDate.getTime();
    if (duration === 0) { // toDate == fromDate
      duration = 24 * 60 * 60 * 1000; // 1 day
    }
    let fromDate = null;
    let toDate = null;
    if (step < 0) {
      fromDate = new Date(this.dates.fromDate.getTime() - duration);
      toDate = new Date(this.dates.toDate.getTime() - duration);
    } else if (step > 0) {
      fromDate = new Date(this.dates.fromDate.getTime() + duration);
      toDate = new Date(this.dates.toDate.getTime() + duration);
    }

    this.datesUI = [fromDate, toDate];
  }

  getThisPriodLabel() {
    switch (this.period) {
      case SwitchDatePeriod.day:
        return 'Today';
      case SwitchDatePeriod.month:
        return 'This Month';
      case SwitchDatePeriod.week:
        return 'This Week';
      case SwitchDatePeriod.year:
          return 'This Year';
      default:
        return 'NA';
    }
  }

  switchDatePlacement() {
    return this.screenView === this.screenSize.large ? 'bottom left' : 'bottom center';
  }
}
