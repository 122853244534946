import { Component, Input, OnInit } from '@angular/core';

export interface ProgressGroupItem {
  label: string;
  count: number;
  color: string;
}

@Component({
  selector: 'app-progress-group',
  templateUrl: './progress-group.component.html',
  styleUrls: ['./progress-group.component.scss']
})
export class ProgressGroupComponent implements OnInit {
  @Input() items: ProgressGroupItem[] = [];
  @Input() total = 100;

  constructor() { }

  ngOnInit(): void {
  }
}
