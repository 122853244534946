import { NotificationSettingUI } from "../../_models/notification.model";
import { NotificationSettingPayload, NotificationSettingType } from "../_enums";

export const NotificationSettingStructure: NotificationSettingUI[] = [
    {
    icon: 'assets/icons/task-management.svg',
    label: 'Task Management',
    code: NotificationSettingPayload.TURN_ON,
    isCollapsed: false,
    description: 'subtitleTaskManagement',
    child: [
      {
        label: 'All email notifications',
        id: NotificationSettingType.EMAIL,
        code: NotificationSettingPayload.SET_FOR_ALL_TASK,
        child: [
          {
            label: 'Create a new task',
            code: NotificationSettingPayload.TASK_SEND_MAIL_CREATED
          },
          {
            label: 'Assignee task',
            code: NotificationSettingPayload.TASK_SEND_MAIL_ASSIGNEE,
          },
          {
            label: 'Mention on comment',
            code: NotificationSettingPayload.TASK_SEND_MAIL_MENTION
          },
          {
            label: 'Update task',
            code: NotificationSettingPayload.TASK_SEND_MAIL_CHANGE_STATUS
          },
          {
            label: 'Task Reminder',
            code: NotificationSettingPayload.TASK_REMINDER
          }
        ]
      },
      {
        label: 'All notifications',
        id: NotificationSettingType.NOTIFY,
        code: NotificationSettingPayload.SET_ALL,
        child: [
          {
            label: 'Create a new task',
            code: NotificationSettingPayload.TASK_NOTIFY_CREATE,
          },
          {
            label: 'Show notification when task is updated',
            code: NotificationSettingPayload.TASK_NOTIFY_UPDATE,
          },
          {
            label: 'Show notification when has mention in a task',
            code: NotificationSettingPayload.TASK_NOTIFY_MENTION,
          },
          {
            label: 'Show notification when task is upcoming to due date in next',
            code: NotificationSettingPayload.TASK_OVER_DUE_AFTER,
            additionalLabel: 'days',
            additionalCode: NotificationSettingPayload.RECEIVER_TASK_OVER_DUE_AFTER
          },
          {
            label: 'Task Reminder',
            code: NotificationSettingPayload.TASK_NOTIFY_REMINDER,
          }
        ]
      }
    ]
  }
];
