import { NavigatedFilter } from 'src/app/site-management/tasks/task-list/_models/task-filter.models';
import { FieldCompareData, NavigatedFilterName, Project, ShareFilterPermission, TaskFilterMode, TaskListFilterBy } from '../..';

export const FieldCompareValue: FieldCompareData = {
  reporter: [],
  project: [],
  assignee: [],
  team: [],
  group: [],
  period: [],
  priority: []
};

export const exceptFieldSuggestions = ['dueDate', 'createdAt', 'modifiedAt', 'advanceFilter', 'customQuery'];
export const exceptFilterByKeys = ['advanceFilter', 'customQuery'];
export const datePickerFilters = ['dueDate', 'createdAt', 'startDate', 'endDate', 'resolvedDate', 'comments.commentFeedback.dateFeedback'];
export const MultiOperatorFields = ['in', 'not_in'];

export const FieldAllValue = 'All';
export const FieldNullValue = 'NULL';
export const DefaultAdvanceFilterName = 'Dashboard Filter';
export const FILTER_DATE_FORMAT = 'MMMDDYYYY';
export const AdvanceFilterName = date => `Filter_${date}`;
export const CLOSE_STATUS_CATEGORY = 'CLOSE';
export const BooleanValue = ['true', 'false', 'undefined', 'null'];
export const DefaultFilterName = 'Filters';
export const QuotationMark = '"';
export const EmptyString = '';

export const FilterSharePermissions = [ShareFilterPermission.VIEW, ShareFilterPermission.EDIT];

export const BacklogClosedTasksFilter = (currentProject: Project): NavigatedFilter<TaskListFilterBy> => {
  return {
    name: NavigatedFilterName.BACKLOG_CLOSED_TASKS,
    mode: TaskFilterMode.Basic,
    filterObject: {
      project: { label: 'Project', selecteds: [currentProject] },
      statusCategory: { label: 'Status Category', selecteds: [{ name: 'CLOSE', value: 'CLOSE' }] }
    },
    query: `project == "${currentProject.name}" and backlogs.status.statusCategory == "CLOSE"`
  };
};

export const FilterData = ({project}) => ([
  BacklogClosedTasksFilter(project)
]);
