import { createSelector } from '@ngrx/store';
import { AppState } from './app.reducers';
import { RootState } from '.';

export const selectApp = (state: RootState) => state.app;

export const selectLoading = createSelector(
  selectApp,
  (state: AppState) => state.loading
);
