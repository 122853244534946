import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { TreeItem } from 'src/app/site-management/document/_services/document-data.service';
import { AttachmentStatusEnum } from './../_utils/enums';
import { Group } from './group.model';
import { TaskType } from './task.model';
import { Team } from './team.model';
import { User } from './user.model';
export interface FileLink {
  url: string;
  fileName: string;
  id?: number;
  deleted?: boolean;

}

export class FileAttachment {
  url = '';
  fileName = '';
  fileUpload: File = null;
  status: AttachmentStatusEnum = AttachmentStatusEnum.PENDING;
  uploadProgress = 0;
  responseMessage = '';
  response: HttpResponse<any> | HttpErrorResponse = null;
  uploadSubscription: Subscription = null;
  uploadedDate: Date = new Date();

  constructor(file: any) {
    this.fileUpload = file;
  }
}

export enum FileType {
  dir = 'Dir',
  file = 'File'
}

export enum FileAction {
  View = 'View',
  Create = 'Create',
  Download = 'Download',
  Share = 'Share',
  Delete = 'Delete',
  Restore = 'Restore',
  PermanentlyDelete = 'PermanentlyDelete',
  Copy = 'Copy',
  Move = 'Move'
}

export interface FileResponse {
  id: number;
  name: string;
  path: string;
  type: FileType;
  defaultFlg: boolean;
  project: FileProject;
  task: FileTask;
  icon: string;
  colorCode: string;
  hasChildFolder: boolean;
  fileSize?: number;
  editable: boolean;
  previewUrl: string;
  webViewLink: string;
  parent?: FileItem;
  lastAction: string;
  action: FileAction;
  shortcut?: boolean;
  shortcutLink: string;
  shared?: boolean;
  modifiedAt?: string;
  owner?: FileOwner;
  parentFile?: FileParent;

  // FE
  downloading?: boolean;
}

export interface FileHistoryResponse {
  file: FileResponse;
}

export interface FileItem extends FileResponse {
  id: any;
  fileId?: number | string;
  providerId?: number | string;
  owner?: FileOwner;
  isOwner?: boolean;
  modifiedAt?: string;
  parentUI?: TreeItem;
  isSelected?: boolean;
  shortcut?: boolean;
  shared?: boolean;
}

export interface FileBaseModel {
  id?: any;
  name?: string;
  path?: string;
  customPath?: string;
}

export interface FileProject {
  id: number;
  name: string;
  key: string;
  avatar: string;
  jiraUrl: null;
}

export interface FileTask {
  id: number;
  name: string;
  key: string;
  type: TaskType;
}

export interface FileParent {
  id: number;
  name: string;
  parentFile: FileParent;
  defaultFlg: boolean;
}

export interface FileOwner {
  id: number;
  avatar: string;
  fullName: string;
}

export interface FileSearchRequest {
  keyword?: string;
  displayInTreeFolder?: boolean;
}

export interface FolderUpsert {
  name: string;
}

export interface RenameUpsert {
  name: string;
}

export interface ShortCutUpsert {
  name: string;
  url: string;
  icon: string;
}
export interface ShareFileRequest {
  fileIds: number[];
  users?: ShareFileItemRequest;
  groups?: ShareFileItemRequest;
  teams?: ShareFileItemRequest;
  anonymousUser?: boolean;
  permission: ShareFilePermission;
}

export interface SendMailSharedUserRequest {
  users: number[];
  teams: number[];
  groups: number[];
  message: string;
  fileCodes: string[];
}

export interface UpdateShareFileRequest {
  shareIds: number[];
  permission: ShareFilePermission;
}

export type ShareFilePermission = 'View' | 'Edit' | 'Different';
export enum FilePermission {
  view = 'View',
  edit = 'Edit',
  different = 'Different',
}

export interface ShareFileResponse {
  id: number;
  user: User;
  team: Team;
  group: Group;
  disable?: boolean;
  mergePermission: ShareFilePermission;
}

export interface ShareFileItemRequest {
  ids: number[];
}

export class ProgressInfoBase {
  percent: number;
  fileName: string;
  status: 'inprogress' | 'success' | 'error' | 'cancel';
  errorMsg?: string;
  subscription?: Subscription;
  type?: 'upload' | 'download';
}

export class UploadProgressInfo extends ProgressInfoBase {
  parentId: any;
}

export class DownloadProgressInfo extends ProgressInfoBase {}

export interface MoveFileRequest {
  sourceFileId: number[];
  desFileId: number;
}

export interface GetLinkResponse {
  code: string;
}

export interface FileAuthorizationResponse {
  sourceUrl: string;
  fullUrl: string;
}

export interface FileAuthorizationHeaderResponse {
  authorization: string;
  host: string;
  url: string;
  xamzContentSha256: string;
  xamzDate: string;
}

export interface SharingItemResponse {
  objectType: 'USER' | 'TEAM' | 'GROUP';
  objectId: number;
  objectEmail: string;
  objectAvatar: string;
  objectName: string;
}

export enum SharingType {
  user = 'USER',
  team = 'TEAM',
  group = 'GROUP',
}

export interface FolderSetting {
  icon: string;
  colorCode: string;
}

export interface CopyFileRequest {
  sourceFileId: number[];
  desFileId: number;
}

export interface MoveFileRequest {
  sourceFileId: number[];
  desFileId: number;
}



export enum UploadFileStatus {
  INPROGRESS= 'inprogress',
  SUCCESS= 'success',
  ERROR= 'error',
  CANCEL= 'cancel'
}
export interface UploadFileProgressInfo {
  percent: number;
  fileName: string;
  status: UploadFileStatus;
  errorMsg?: string;
  subscription?: Subscription;
}
