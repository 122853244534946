import { Component, forwardRef, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { generateHours, generateMeridiem, generateMinutes, getSelectedTime, parseTimeString } from './time-picker-control.utils';
import { TimeFormat, TimeObject } from '../time-picker/models/time-picker.model';
import { AbstractComponent } from '../..';
import { TimePickerOverlayService } from 'src/app/site-management/_services/time-picker-overlay.service';

@Component({
  selector: 'app-time-picker-control',
  templateUrl: './time-picker-control.component.html',
  styleUrls: ['./time-picker-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerControlComponent),
      multi: true
    }
  ]
})
export class TimePickerControlComponent extends AbstractComponent implements ControlValueAccessor {
  @ViewChild('hourContainer') hourContainer: ElementRef<any>;
  @ViewChild('minutesContainer') minutesContainer: ElementRef<any>;
  @ViewChild('meridiemContainer') meridiemContainer: ElementRef<any>;

  hours = generateHours();
  minutes = generateMinutes();
  meridiem = generateMeridiem();
  currentTime: string;
  selectedTime: TimeFormat;
  selectedClassName = '.selected';

  constructor(
    private timePickerOverlayService: TimePickerOverlayService
  ) {
    super();
  }

  onChange: (data: any) => void;
  onTouched: () => void;

  writeValue(timeStr: string) {
    if (!timeStr) {
      return;
    }

    this.selectedTime = parseTimeString(timeStr);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  afterViewInit() {
    this.scrollList();
  }

  onSelectedTime(item: TimeObject, element?: HTMLDivElement) {
    this.selectedTime = getSelectedTime(this.selectedTime, item);
    this.scrollToTop(element);
  }

  scrollToTop(element: HTMLDivElement) {
    if (!element) {
      return;
    }

    element.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToSelectedElement(containerElement: ElementRef, selector: string, index: number) {
    const selectedElements = containerElement.nativeElement.querySelectorAll(selector);
    if (selectedElements.length > index) {
      selectedElements[index].scrollIntoView();
    }
  }

  scrollList() {
    this.scrollToSelectedElement(this.hourContainer, this.selectedClassName, 1);
    this.scrollToSelectedElement(this.minutesContainer, this.selectedClassName, 1);
    this.scrollToSelectedElement(this.meridiemContainer, this.selectedClassName, 0);
  }

  onSubmit() {
    const { hour, minute, period } = this.selectedTime;
    const time = `${hour}:${minute} ${period}`;
    if (time === this.currentTime) {
      this.timePickerOverlayService.hide();
      return;
    }

    this.writeValue(time);
    this.onChange(time);
    this.currentTime = time;
    this.timePickerOverlayService.hide();
  }

  onCancel() {
    this.timePickerOverlayService.hide();
  }
}
