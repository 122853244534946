

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PermissionCheckService } from 'src/app/site-management/role-permission/_services/permission-check.service';
import { permissionCode } from '../../_utils';
import { PermissionChecker } from './permission-checker.component';

@Component({
  selector: 'app-base',
  template: `<div>base component!</div>`,
})

export class BaseComponent implements OnInit, OnDestroy {
  visibility: { [key: string]: boolean } = {};
  permissionCode = permissionCode;
  actions: string[] = [];
  destroyed$ = new Subject<void>();
  permissionChecker: PermissionChecker;

  constructor(
    public permissionCheckService: PermissionCheckService
  ) {

  }

  ngOnInit() {
    this.visibility = this.permissionCheckService.getVisibility(this.actions);
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
