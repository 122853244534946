<div class="app-avatar-wrapper {{size}}"
  #avatar
  tooltip-overlay
  [templateRef]="tooltipTemplate"
  [viewContainerRef]="appAvatar"
  [tooltipDisabled]="hideTooltip"
  [hideOnMouseLeave]="true"
  [customConfig]="tooltipOverlayConfig"
  panelClass="user-container"
  [ngStyle]="{'background-color': !url && name ? '#ff9a1f' : 'transparent'}"
>
  <img [src]="url" alt="" *ngIf="url; else shortName">

  <ng-template #tooltipTemplate>
    <div class="m-2 d-flex align-items-center">
      <div class="avatar-wrapper" [ngClass]="{ 'unassigned' : !url && name }">
        <img class="icon-md rounded-circle" [src]="url" *ngIf="url; else shortName">
      </div>
      <div class="ml-1">{{ name || unassignedTitle }}</div>
    </div>
  </ng-template>

  <ng-template #shortName>
    <span class="name" *ngIf="name">{{ name | getShortName }}</span>
    <span class="unassigned-avatar material-icons text-gray" *ngIf="!name">
      account_circle
    </span>
  </ng-template>
</div>
