import { createReducer, on } from '@ngrx/store';
import { AppActions } from './app.actions';
import { LoadingState } from '../../models/loading';

export const appFeatureKey = 'auth';

export interface AppState {
  loading?: LoadingState;
}

export const initAppState: AppState = {};

export const appReducer = createReducer(
  initAppState,
  on(AppActions.loading, (state: AppState, loading: LoadingState): AppState => {
    return { ...state, loading };
  })
);
