import { createAction, props } from '@ngrx/store';
import { LoadingState } from '../../models/loading';

export const loading = createAction(
  '[App] Loading',
  props<LoadingState>()
);

export const AppActions = {
  loading
};
