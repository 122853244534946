import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Project } from 'src/app/shared';
import { ProjectsService } from 'src/app/site-management/projects/_services';

@Injectable({
  providedIn: 'root'
})
export class ProjectGuardService  {
  initProject: Project;
  constructor(
    private projectsService: ProjectsService,
  ) {
    this.initProject = projectsService.getCurrentProject();
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.initProject) {
      return true;
    }
    return this.projectsService.getById(this.initProject.id).pipe(
      map((project) => {
        return true;
      }),
      catchError((err) => {
        return of(true);
      })
    );
  }
}
